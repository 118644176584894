import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { ServicesService } from '../../../services/services/services.service';
import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';

import { ModalComponent } from '../../../components/modal/modal.component';
import { PlainTextComponent } from '../../../parts/plain-text/plain-text.component';

@Component({
  selector: 'app-lw-will-update',
  templateUrl: './lw-will-update.component.html',
  styleUrls: ['./lw-will-update.component.scss']
})
export class LwWillUpdateComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;

  loading: boolean;
  checkingStatus: boolean;

  constructor(
    private router: Router,
    private globalCommsService: GlobalCommsService,
    private servicesService: ServicesService,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
  ) {
    this.loading = false;
  }

  ngOnInit() { }

  showModal() {

    let text = 'There is a problem with the will service at the moment. Please refresh the screen and try again. If you keep seeing this error, please contact us.';

    this.modalRef = this.modalService.show( ModalComponent , {
      data: {
        heading: 'Error',
        component: PlainTextComponent,
        extraData: {
          text: text,
        },
      }
    });

  }

  agreeToTerms(): void {

    this.loading = true;

    this.servicesService.acceptWillTerms( this.object['id'] ).subscribe(
      resp => {
        this.object = resp;
        this.loading = false;
        this.servicesService.patchService( resp );
      },
      error => {
        this.loading = false;
      },
    );

  }

  hasWillUrl(): boolean {
    return ( typeof( this.object['lw_ww_url'] ) === 'string' ? true : false );
  }

  sanitizeUrl( url: string ) {
    return url.replace( '?&amp;' , '?' ).replace( /&amp;/g , '&' );
  }

  openWillWriter( caseObject: Object ): void {
    this.globalCommsService.updateGlobalRef( 'close-all-modals' );
    this.router.navigate([ '/will-writer/' + caseObject['id'] ]);
  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <h1>The Test Bed</h1>

    <mdb-card style="float: left;">
      <mdb-card-body style="padding: 0;">

        <canvas id="myCanvas" #myCanvas width="500" height="500"></canvas>

      </mdb-card-body>

      <button (click)="draw()">Clear</button>

    </mdb-card>

    <mdb-card style="float: left;">
      <mdb-card-body style="padding: 0;">

        <mdb-wysiwyg></mdb-wysiwyg>

      </mdb-card-body>
    </mdb-card>

  </div>
</div>

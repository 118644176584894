import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../environments/environment';
import { Subscription } from 'rxjs';
import { delay , distinctUntilChanged , filter } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { LoginService } from './services/login/login.service';
import { GlobalCommsService } from './services/global-comms/global-comms.service';
import { AccountManagementService } from './services/account-management/account-management.service';
import { ServicesService } from './services/services/services.service';

import { WpConfigService } from './angular-wordpress-rest-api/services/wp-config/wp-config.service';
import { WpLoginService } from './angular-wordpress-rest-api/services/wp-login/wp-login.service';
import { WpMessagesService } from './angular-wordpress-rest-api/services/wp-messages/wp-messages.service';
import { WpUserService } from './angular-wordpress-rest-api/services/wp-user/wp-user.service';

import { AppConfig } from './angular-wordpress-rest-api/classes/app-config/app-config';
import { WpUser } from './angular-wordpress-rest-api/classes/wp-user/wp-user';

import { ModalComponent } from './components/modal/modal.component';
import { PlainTextComponent } from './parts/plain-text/plain-text.component';

import { User } from './classes/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state( 'open' , style({
        opacity: 1,
        zIndex: 9999,
      })),
      state( 'closed' , style({
        opacity: 0,
        zIndex: -1,
      })),
      transition( 'open => closed' , [
        animate( '0.5s' )
      ]),
      transition( 'closed => open' , [
        animate( '0.5s' )
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {

  @ViewChild( 'profileModal' ) profileModal;

  private subscriptions: Subscription[];
  public globalLoading: boolean;

  constructor(
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private loginService: LoginService,
    private globalCommsService: GlobalCommsService,
    private accountManagementService: AccountManagementService,
    public servicesService: ServicesService,
    private wpConfigService: WpConfigService,
    private wpLoginService: WpLoginService,
    private wpMessagesService: WpMessagesService,
    private wpUserService: WpUserService,
  ) {
    this.globalLoading = true;
  }

  ngOnInit() {

    this.wpLoginService.currentLoginStatus.pipe(
      filter( resp => resp != 'unknown' )
    ).subscribe(
      resp => {

        if( resp !== 'logged-in' ) {
          return;
        }

        this.globalCommsService.updateLoginStatus({ status: 'logged-in' });

        this.servicesService.getServices().subscribe(
          resp => {
            this.servicesService.getServices({
              context: 'embed',
            }).subscribe();
          }
        );

      }
    );

    this.wpLoginService.latestLoginResponse.subscribe(
      resp => {

        if( typeof( resp['data'] ) === 'undefined' ) {
          return;
        }

        if( typeof( resp['data']['apiUser'] ) === 'undefined' ) {
          return;
        }

        this.globalCommsService.updateUserData( resp['data']['apiUser'] );
        return this.wpUserService.updateCurrentUser( resp['data']['apiUser'] );

      }
    );

    this.globalCommsService.currentRef.pipe(
      distinctUntilChanged()
    ).subscribe(
      resp => {

        if( resp === 'stop-global-loading' ) {
          this.globalLoading = false;
        }

        if( resp === 'start-global-loading' ) {
          this.globalLoading = true;
        }

      }
    );

    this.wpMessagesService.currentMessage.subscribe(
      resp => {

        if( resp['type'] !== 'error' ) {
          return;
        }

        this.showModal( resp['message'] );

        this.wpMessagesService.clearMessage();

      }
    );

    this.wpMessagesService.currentMessage.pipe(
      distinctUntilChanged(),
      filter( message => message['type'] === 'open-account-settings' )
    ).subscribe(
      resp => {
        this.profileModal.show();
        this.wpMessagesService.clearMessage();
      }
    );

    let config: AppConfig = {
      siteUrl: environment.apiUrl,
      appVersion: '0.0.9',
    }

    this.wpConfigService.updateAppConfig( config );
    this.wpLoginService.checkLoginStatus();

  }

  showGlobalLoding(): boolean {
    return this.globalLoading;
  }

  showModal( text ) {

    this.modalRef = this.modalService.show( ModalComponent , {
      data: {
        heading: 'Error',
        component: PlainTextComponent,
        extraData: {
          text: text,
        },
      }
    });

  }

}

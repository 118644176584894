<div class="row" *ngIf="! object.id">
  <div class="col-lg-4 border-right">

    <strong>Need inspiration? Why not browse our mega bucketlist?</strong>
    <hr />

    <mdb-select [options]="categoryOptions" (ngModelChange)="getSelectedCategory($event)" [(ngModel)]="selectedCategory" placeholder="Choose category"></mdb-select>

    <div *ngIf="activities">
      <hr />

      <mdb-select [options]="activityOptions" (ngModelChange)="getSelectedOption($event)" [(ngModel)]="selectedOption" placeholder="Choose category"></mdb-select>

    </div>

  </div>
  <div class="col-lg-8">

    <ng-container *ngTemplateOutlet="bucketListItemForm"></ng-container>

  </div>
</div>

<div class="row" *ngIf="object.id">
  <div class="col-lg-12">
    <ng-container *ngTemplateOutlet="bucketListItemForm"></ng-container>
  </div>
</div>

<ng-template #bucketListItemForm>

  <p>Add a bucket list item here, give it a name and add some more info if you want.</p>
  <p>You can also record when it's done and automatically add it to your life timeline!</p>

  <hr />

  <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >

</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { WpConfigService } from '../wp-config/wp-config.service';
import { WpMessagesService } from '../wp-messages/wp-messages.service';

import { AppConfig } from '../../classes/app-config/app-config';
import { WpObject } from '../../classes/wp-object/wp-object';

@Injectable({
  providedIn: 'root'
})
export class WpServerService {

  httpOptions: Object;
  apiUrl: string;
  authType: string;
  cookieNonce: string;
  config: AppConfig;
  skipAuthItems: string[];

  constructor(
    public http: HttpClient,
    private wpConfigService: WpConfigService,
    private wpMessagesService: WpMessagesService,
  ) {

    this.wpConfigService.currentAppConfig.subscribe(
      resp => {

        if( resp === {} ) {
          return;
        }

        this.config = resp;

        this.apiUrl = resp['siteUrl'];

        this.cookieNonce = ( typeof( resp['cookieNonce'] ) === 'undefined' ? false : resp['cookieNonce'] );
        this.authType = ( typeof( resp['authType'] ) === 'undefined' || ! this.cookieNonce ? 'jwt' : resp['authType'] );

        this.skipAuthItems = ( typeof( resp['skipAuth'] ) === 'undefined' ? [] : resp['skipAuth'] );

      }
    );

  }

  getHttpHeaders( observe = false , auth = true , extraHeaders = {} ) {

    let headers = extraHeaders;
    let token = localStorage.getItem( 'userToken' );

    if( auth && this.authType === 'jwt' ) {

      let token = localStorage.getItem( 'userToken' );

      if( typeof( token ) === 'string' ) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem( 'userToken' );
      }

    }

    if( auth && this.authType === 'cookie' ) {
      headers['X-WP-Nonce'] = this.cookieNonce;
    }

    this.httpOptions = {
      headers: new HttpHeaders( headers ),
    }

    if( observe ) {
      this.httpOptions['observe'] = 'response';
    }

    return this.httpOptions;

  }

  skipAuth( check: string ): boolean {

    for( let skip of this.skipAuthItems ) {
      let regex = new RegExp( skip , 'g' );

      if( check.search( regex ) === -1 ) {
        continue;
      }

      return true;

    }

    return false;

  }

  //get a token
  getToken( data: Object ) {
    return this.http.post( this.apiUrl + '/jwt-auth/v1/token' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getToken') )
    );
  }

  //validate token
  validateToken() {
    return this.http.post( this.apiUrl + '/jwt-auth/v1/token/validate/ ' , {} , this.getHttpHeaders() );
  }

  //register a user
  userRegister( data ) {
    return this.http.post( this.apiUrl + '/wp/v2/users/register/' , data , this.getHttpHeaders( false ) ).pipe(
      catchError( this.handleError<any>('userRegister') )
    );
  }

  executeWpQuery( url: string , nameSpace?: string ) {

    let ns = ( typeof( nameSpace ) === 'undefined' ? '/wp/v2/' : nameSpace );
    let auth = ( ! this.skipAuth( url ) ? true : false );

    return this.http.get<HttpResponse<any>>( this.apiUrl + ns + url , this.getHttpHeaders( true , auth )).pipe(
      catchError( this.handleError<any>('executeWpQuery') )
    );

  }

  //creare a new services
  createObject( route: string , data: WpObject , nameSpace?: string ) {

    let ns = ( typeof( nameSpace ) === 'undefined' ? '/wp/v2/' : nameSpace );

    return this.http.post<WpObject>( this.apiUrl + ns + route , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('createObject') )
    );

  }

  //update a service
  updateObject( route: string , id: number , data: WpObject , nameSpace?: string ) {

    let ns = ( typeof( nameSpace ) === 'undefined' ? '/wp/v2/' : nameSpace );

    return this.http.put<WpObject>( this.apiUrl + ns + route + '/' + id , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('updateObject') )
    );

  }

  //upload a file
  uploadFile( data: Object , filename?: string, nameSpace?: string ) {

    return this.http.post<WpObject>( this.apiUrl + '/wp/v2/media' , data , this.getHttpHeaders( false , true )).pipe(
      catchError( this.handleError<any>('uploadFile') )
    );

  }

  //delete an object
  deleteObject( route: string , nameSpace?: string ) {

    let ns = ( typeof( nameSpace ) === 'undefined' ? '/wp/v2/' : nameSpace );

    return this.http.delete<WpObject>( this.apiUrl + ns + route , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('deleteObject') )
    );

  }

  //handle errors
  private handleError<T> ( operation = 'operation' , result?: T ) {
    return( error: any ): Observable<T> => {

      let code = error['error']['code'];

      if( typeof( this.config ) !== 'undefined' && typeof( this.config.supressErrors ) !== 'undefined' && this.config.supressErrors.indexOf( code ) !== -1 ) {
        throw result;
        return;
      }

      let message = `${operation} failed: ${error.message}`;

      if( typeof( error['error'] ) !== 'undefined' && typeof( error['error']['message'] ) !== 'undefined' ) {
        message = `${error['error']['message']}`
      }

      this.wpMessagesService.broadcastMessage({
        title: {
          rendered: 'Error',
        },
        type: 'error',
        message: message,
      });

      //throw the error instead of passing a happy observable
      throw result;

    };
  }

}

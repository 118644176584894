import { Injectable } from '@angular/core';

import { PipelineTab } from '../../classes/pipeline-tab/pipeline-tab';
import * as pipelineActions from '../../../json/pipeline-actions.json';

@Injectable({
  providedIn: 'root'
})
export class PipelineActionsService {

  private pipelineActions: Object[];

  constructor() {
    this.pipelineActions = pipelineActions['default'];
  }

  addActionsToTabs( tabs: PipelineTab[] ): PipelineTab[] {

    for( let tab of tabs ) {
      tab['actions'] = this.getTabActions( tab['slug'] );
    }

    return tabs;

  }

  getTabActions( status: string ): Object[] {

    let returnActions = []

    for( let pipelineAction of this.pipelineActions ) {

      if( pipelineAction['allowedStatus'].indexOf( status ) === -1 ) {
        continue;
      }

      returnActions.push( pipelineAction );

    }

    return returnActions;

  }

}

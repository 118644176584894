<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-md-6">
        <h1 class="mb-4">Your Order</h1>
      </div>
      <div class="col-md-6">
        <div class="float-right">
        </div>
      </div>
    </div>

    <div class="row">

      <!--Rotating card-->
      <div class="col-lg-4 col-xl-3">

        <div class="d-lg-none">
          <mdb-accordion [multiple]="false" aria-multiselectable="true">
            <mdb-accordion-item  [collapsed]="true">
              <mdb-accordion-item-head>View Profile</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <app-user-profile></app-user-profile>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>

        <div class="d-none d-lg-block">
          <app-user-profile></app-user-profile>
        </div>

      </div>
      <!--/.Rotating card-->

      <div class="col-lg-8 col-xl-9">

        <div class="row">
          <div class="col-md-12">
            <!--Card-->
            <div class="card card-cascade narrower">
              <!--Card content-->
              <div class="card-body">
                <h3 class="text-left mb-4"><strong>Order Details</strong></h3>

                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Order Number</th>
                      <td>1234</td>
                    </tr>
                    <tr>
                      <th>Product</th>
                      <td>Simple Will - For you and your partner <strong> x1</strong></td>
                    </tr>
                    <tr>
                      <th>Total</th>
                      <td>£19.99</td>
                    </tr>
                    <tr>
                      <th>Date</th>
                      <td>23rd February 2019</td>
                    </tr>
                    <tr>
                      <th>Total</th>
                      <td>£19.99</td>
                    </tr>
                  </tbody>
                </table>

                <button routerLink="/user-home" style="width: 100%; display: block;" type="button" class="btn btn-default mt-4" block="true">Go To The Vault</button>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>

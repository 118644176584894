import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lpa-form',
  templateUrl: './lpa-form.component.html',
  styleUrls: ['./lpa-form.component.scss']
})
export class LpaFormComponent implements OnInit {

  @Input() object: Object;
  public key: string;
  public extraData: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'lpa';

    this.extraData = {
      heading: 'Lasting Power of Attorney',
      helpModalOptions: {
        show: true,
      },
    }

  }

}

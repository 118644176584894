import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TabsetComponent } from 'ng-uikit-pro-standard';

import { ApiFieldsService } from '../../../services/api-fields/api-fields.service';

@Component({
  selector: 'app-eulogy',
  templateUrl: './eulogy.component.html',
  styleUrls: ['./eulogy.component.scss']
})
export class EulogyComponent implements OnInit {

  @ViewChild( 'staticTabs', { static: true }) staticTabs: TabsetComponent;

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  public description: string;
  public subDescription: string;
  public hasContent: boolean;

  constructor(
    public httpClient: HttpClient,
    public apiFieldsService: ApiFieldsService,
  ) {
    this.hasContent = false
  }

  ngOnInit(): void {

    this.key = 'eulogy';
    this.hasContentCheck();

    this.httpClient.get( 'assets/text/eulogy-example.txt' , { responseType: 'text' }).subscribe(
      resp => {

        if( typeof( this.object['eulogy_content'] ) !== 'undefined' ) {
          return;
        }

        this.object['eulogy_content'] = resp;

      }
    );

  }

  changeActiveTab( tab: number ): void {
    this.staticTabs.setActiveTab( tab );
  }

  hasContentCheck(): void {
    this.hasContent = ( typeof( this.object['eulogy_content'] ) !== 'undefined' || this.object['eulogy_content'] === '' ? true : false );
  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MDBModalRef, MDBModalService, SBItemComponent } from 'ng-uikit-pro-standard';

import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { ServicesService } from '../../../services/services/services.service';

import { LpaFormComponent } from './lpa-form/lpa-form.component';

@Component({
  selector: 'app-lpa',
  templateUrl: './lpa.component.html',
  styleUrls: ['./lpa.component.scss']
})
export class LpaComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  public loading: boolean;
  private subscriptions: Subscription[];

  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private globalCommsService: GlobalCommsService,
    private servicesService: ServicesService,
  ) {
    this.subscriptions = [];
    this.loading = false;
  }

  ngOnInit(): void {

    this.key = 'lpa';

    this.extraData = {
      heading: 'Lasting Power of Attorney',
    }

    this.subscriptions.push( this.globalCommsService.currentRef.subscribe(
      resp => {
        if( resp === 'close' ) {
          this.modalRef.hide();
          this.getService();
        }
      }
    ));

  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  getService() {
    this.subscriptions.push( this.servicesService.getService( this.object['id'] ).subscribe(
      resp => {
        this.object = resp;
      },
      error => {
        //console.log( error );
      }
    ));
  }

  openLpaForm() {

    let modalOptions = Object.assign(
      {
        ignoreBackdropClick: true,
        data: {
          heading: 'Lasting Power of Attorney',
          component: LpaFormComponent,
          object: this.object,
        }
      },
      {
        class: 'modal-lg'
      }
    );

    this.openModal( modalOptions );

  }

  openModal( modalOptions: Object ): void {
    this.modalRef = this.modalService.show( ModalComponent , modalOptions );
  }

  canStartForm(): boolean {
    return ( [ 'not-started' , 'started' , 'skipped' ].indexOf( this.object['service_type_status'] ) !== -1 && ! this.loading );
  }

  canEditForm(): boolean {
    return ( [ 'not-started' , 'started' , 'ready' , 'skipped' ].indexOf( this.object['service_type_status'] ) !== -1 && ! this.loading );
  }

  isComplete(): boolean {
    return ( [ 'complete' ].indexOf( this.object['service_type_status'] ) !== -1 );
  }

  canSubmitForm(): boolean {
    return ( [ 'ready' ].indexOf( this.object['service_type_status'] ) !== -1 && ! this.loading );
  }

  submitLpaForm(): void {

    this.loading = true;

    this.servicesService.submitLpa( this.object['id'] ).subscribe(
      resp => {
        this.object = resp;
        this.loading = false;
        this.servicesService.getServices().subscribe();
      },
      error => {
        this.loading = false;
      },
    );

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

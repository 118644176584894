import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { WpQueryService } from '../wp-query/wp-query.service';
import { WpSaveService } from '../wp-save/wp-save.service';

import { WpUser } from '../../classes/wp-user/wp-user';

@Injectable({
  providedIn: 'root'
})
export class WpUserService {

  private user = new BehaviorSubject({});
  currentUser = this.user.asObservable();

  constructor(
    private wpQueryService: WpQueryService,
    private wpSaveService: WpSaveService,
  ) { }

  updateCurrentUser( user: WpUser ): void {
    this.user.next( user );
  }

  updateUser( userData: Object , sendArgs: string[] = [] ) {
    return this.wpSaveService.saveObject( 'users' , userData , sendArgs );
  }

  getCurrentUser() {

    this.wpQueryService.getPosts({
      postType: 'users/me',
      args: {
        context: 'edit',
      },
    }).subscribe(
      resp => {

        if( typeof( resp ) === 'undefined' ) {
          return;
        }

        this.updateCurrentUser( resp['body'] );

      },
      error => {
        this.updateCurrentUser({});
      }
    );

  }

}

import { Injectable } from '@angular/core';

import { ApiFieldsService } from '../services/api-fields/api-fields.service';

import { MasterFormComponent } from '../parts/master-form/master-form.component';
import { ServiceChildListComponent } from '../parts/service-child-list/service-child-list.component';

import { WillsDefaultListComponent } from '../parts/vault/wills/parts/wills-default-list/wills-default-list.component';

@Injectable({
  providedIn: 'root'
})

export class SimpleWill {

  show: Object;
  sections: Array<Object>;
  object: Object;

  complete: any;
  progress: any;

  constructor(
    public apiFieldsService: ApiFieldsService,
  ) {
    this.show = {}
  }

  setObject( object ) {
    this.object = object;
    this.checkProgress();
  }

  checkProgress() {

    this.sections = this.getSections( this.object['service_type'] );

    for( var a = 0; a < this.sections.length; a++ ) {
      let thisSection = this.sections[ a ]['key'];
      let thisSectionType = this.sections[a]['type'];

      if( thisSectionType === 'children' ) {
        let min = this.sections[a]['min'];

        if( typeof( this.object[ thisSection ] ) === 'undefined' || this.object[ thisSection ] < min ) {
          this.show[ thisSection ] = false;
          continue;
        }

        this.show[ thisSection ] = true;
        continue;

      }

      this.apiFieldsService.fieldsRef.subscribe(

        resp => {

          //if a value we are looking for is not passed in the resp we will ask the service to set it
          //slight warning of a infinite loop risk here I guess
          if( typeof( resp[ thisSection ] ) === 'undefined' ) {
            this.apiFieldsService.setFieldReference( thisSection );
            return;
          }

          let questionPages = resp[ thisSection ];

          for( var i = 0; i < questionPages.length; i++ ) {
            let thisQuestionSet = questionPages[ i ];

            for( var x = 0; x < thisQuestionSet['questions'].length; x++ ) {
              let thisQuestion = thisQuestionSet['questions'][ x ];

              if( ! thisQuestion['required'] ) {
                continue;
              }

              if( typeof( this.object[ thisQuestion['name'] ] ) === 'undefined' || this.object[ thisQuestion['name'] ] === '' ) {
                this.show[ thisSection ] = false;
                this.isComplete();
                this.getProgress();
                return;
              }

            }
          }

          this.show[ thisSection ] = true;
          this.isComplete();
          this.getProgress();

        }

      );

    }

  }

  isComplete() {

    for( var key in this.show ) {

      if( ! this.show[ key ] ) {
        this.complete = false;
        return;
      }

    }

    this.complete = true;
    return;

  }

  getProgress() {

    let sectionCount = 0;
    let completeCount = 0;

    for( var key in this.show ) {

      sectionCount++;

      if( this.show[ key ] ) {
        completeCount++
      }

    }

    if( completeCount === 0 ) {
      this.progress = '0%';
    }

    this.progress = Math.round( (( completeCount / sectionCount ) * 100 ) ) + '%';
    return;

  }

  getSections( serviceType ) {

    let sections = {
      'simple-will': [
        /*{
          key: 'simple-wills-initial-form',
          title: 'Basic Questions',
          component: MasterFormComponent,
          type: 'direct',
        },*/
        {
          key: 'simple-wills-details',
          title: 'Your Details',
          component: MasterFormComponent,
          type: 'direct',
        },
        {
          key: 'simple-wills-outcome',
          title: 'Your Choices',
          component: MasterFormComponent,
          type: 'direct',
        },
        {
          key: 'simple-wills-executors',
          title: 'Executors',
          component: WillsDefaultListComponent,
          type: 'children',
          childType: 'executor',
          plural: 'executors',
          singular: 'Executor',
          min: 1,
          max: 4,
        },
        {
          key: 'simple-wills-guardians',
          title: 'Guardians',
          component: WillsDefaultListComponent,
          type: 'children',
          childType: 'guardian',
          plural: 'guardians',
          singular: 'Guardian',
          min: 1,
          max: 2,
        },
        {
          key: 'simple-wills-beneficiaries',
          title: 'Beneficiaries',
          component: WillsDefaultListComponent,
          type: 'children',
          childType: 'beneficiary',
          plural: 'beneficiaries',
          singular: 'Beneficiary',
          min: 1,
          max: 0,
        },
        {
          key: 'simple-wills-witness',
          title: 'Witness',
          component: MasterFormComponent,
          type: 'direct',
        },
        {
          key: 'simple-wills-signature',
          title: 'Your Signature',
          component: MasterFormComponent,
          type: 'direct',
        },
      ]
    }

    if( typeof( sections[ serviceType ] ) === 'undefined' ) {
      return [];
    }

    return sections[ serviceType ];

  }

}

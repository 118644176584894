import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TabsetComponent } from 'ng-uikit-pro-standard';

import { GlobalCommsService } from '../services/global-comms/global-comms.service';
import { AccountManagementService } from '../services/account-management/account-management.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild( 'staticTabs' ) staticTabs: TabsetComponent;

  public captcha: string;
  public captchaKey: string;
  public email: string = '';
  public first_name: string = '';
  public last_name: string = '';
  public loading: Boolean;
  public password: string = '';
  public terms: boolean = false;

  constructor(
    public accountManagementService: AccountManagementService,
    private globalCommsService: GlobalCommsService,
    public router: Router
  ) { }

  ngOnInit() {
    this.captchaKey = environment.recaptchaKey;
    this.globalCommsService.updateGlobalRef( 'stop-global-loading' );
  }

  changeActiveTab( tab ) {
    this.staticTabs.setActiveTab( tab );
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  login({ value , valid }) {

    if( ! valid ) {
      //console.log( value , valid );
      return;
    }

    this.loading = true;

    this.accountManagementService.login({
      email: this.email,
      password: this.password,
    }).subscribe(

      resp => {

        if( typeof( resp['token'] ) !== 'undefined' || typeof( resp['data']['token'] ) !== 'undefined' ) {

          this.globalCommsService.updateLoginStatus({
            status: 'logged-in',
          });

          this.router.navigate(['/user-home']);

        } else {
          this.loading = false;
        }

      },

      error => {
        this.loading = false;
      }

    );

  }

  register({ value , valid }) {

    console.log( this.captcha );

    if( ! valid ) {
      //console.log( value , valid );
      return;
    }

    if( ! this.terms ) {
      alert( 'You must accept our terms to register.' );
      return;
    }

    this.loading = true;

    return this.accountManagementService.register({
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      password: this.password,
      recaptcha: this.captcha,
      terms: this.terms,
    }).subscribe(

      resp => {
        this.router.navigate(['/user-home']);
        this.loading = false;
        return;
      },

      error => {
        this.loading = false;
      }

    );

  }

}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { MDBModalRef, MDBModalService, SBItemComponent } from 'ng-uikit-pro-standard';
import { SidenavComponent } from 'ng-uikit-pro-standard'

import { ModalComponent } from '../components/modal/modal.component';

import { WpMessagesService } from '../angular-wordpress-rest-api/services/wp-messages/wp-messages.service';
import { WpUserService } from '../angular-wordpress-rest-api/services/wp-user/wp-user.service';
import { WpUser } from '../angular-wordpress-rest-api/classes/wp-user/wp-user';

import { ServicesService } from '../services/services/services.service';
import { ApiFieldsService } from '../services/api-fields/api-fields.service';
import { GlobalCommsService } from '../services/global-comms/global-comms.service';
import { ServicesDefinitionService } from '../services/services-definition/services-definition.service';

import { PleaseWaitComponent } from '../parts/vault/please-wait/please-wait.component';

import { UserHomeItem } from '../classes/user-home-item/user-home-item';
import { UserHomeTile } from '../classes/user-home-tile/user-home-tile';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  public navigationItems: UserHomeItem[];
  public currentTiles: UserHomeItem[];
  public currentSection: UserHomeItem;
  public homeItem: UserHomeItem;
  private autoOpen: UserHomeItem;
  public part: string;

  private currentUser: WpUser;
  private querySubscription: Subscription;
  private subscriptions: Subscription[];

  @ViewChild( 'sidenav' , { static: true }) sideNav: SidenavComponent

  constructor(
    public activatedRoute: ActivatedRoute,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private wpUserService: WpUserService,
    public servicesService: ServicesService,
    private apiFieldsService: ApiFieldsService,
    private globalCommsService: GlobalCommsService,
    private servicesDefinitionService: ServicesDefinitionService,
    private wpMessagesService: WpMessagesService,
    private router: Router,
  ) {

    this.subscriptions = []

    this.homeItem = {
      label: '<b>Dashboard</b>',
      slug: 'home',
    }

    this.navigationItems = this.servicesDefinitionService.getServicesDefinition();
    this.resetTiles();

  }

  ngOnInit() {

    this.globalCommsService.updateGlobalRef( 'start-global-loading' );
    this.currentSection = this.homeItem;

    this.subscriptions.push( this.globalCommsService.currentServices.subscribe(
      resp => {

        if( typeof( resp[0] ) !== 'undefined' && typeof( resp[0]['action'] ) !== 'undefined' && resp[0]['action'] === 'wait' ) {
          return;
        }

        this.assignObjects( resp );

      }
    ));

    this.subscriptions.push( this.globalCommsService.currentRef.subscribe(
      resp => {
        if( resp === 'close-all-modals' ) {
          this.modalRef.hide();
        }
      }
    ));

    if( typeof( this.querySubscription ) === 'undefined' ) {
      this.querySubscription = this.activatedRoute.queryParams.pipe(
        first()
      ).subscribe(
        resp => {

          if( typeof( resp['target'] ) === 'undefined' ) {
            return;
          };

          let item = this.pluckUserHomeItem( this.navigationItems , resp['target'] );

          if( item.slug !== resp['target'] ) {
            return;
          }

          this.autoOpen = item;

        }
      );
    }

    this.activatedRoute.paramMap.pipe().subscribe(
      resp => {

        if( typeof( resp['params'] ) === 'undefined' ) {
          this.currentSection = this.homeItem;
          return;
        }

        if( typeof( resp['params']['section'] ) === 'undefined' ) {
          this.currentSection = this.homeItem;
          return;
        }

        let currentSection = this.pluckUserHomeItem( this.navigationItems , resp['params']['section'] );

        if( currentSection['slug'] !== resp['params']['section'] ) {
          return;
        }

        this.currentSection = currentSection;

        if( typeof( resp['params']['part'] ) !== 'undefined' ) {
          this.part = resp['params']['part'];
          return this.clearTiles();
        }

        if( typeof( resp['params']['part'] ) === 'undefined' ) {
          delete this.part;
        }

        if( typeof( this.currentSection.children ) !== 'undefined' ) {
          this.setChildrenAsTiles( this.currentSection );
        }

      }
    );

    this.subscriptions.push( this.router.events.subscribe(
      resp => {
        //console.log( resp );
      }
    ));

    this.subscriptions.push( this.wpUserService.currentUser.subscribe(
      resp => {

        this.currentUser = resp;

        if( typeof( resp['first_name'] ) === 'undefined' || resp['first_name'] === '' ) {
          return;
        }

        this.homeItem.label = resp['first_name'] + '\'s ' + this.homeItem.label;

      }
    ));

  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  assignObjects( userObjects: Object[] ): void {

    for( let navigationItem of this.navigationItems ) {
      for( let child of navigationItem.children ) {
        delete child.userObject;
      }
    }

    for( let userObject of userObjects ) {
      let userHomeItem = this.pluckUserHomeItem( this.navigationItems , userObject['service_type'] );
      userHomeItem.userObject = userObject;
    }

    if( typeof( this.autoOpen ) !== 'undefined' ) {
      this.showModal( this.autoOpen );
      delete this.autoOpen;
    }

    this.globalCommsService.updateGlobalRef( 'stop-global-loading' );

  }

  pluckUserHomeItem( items: UserHomeItem[] , type: string ): any {

    for( let userHomeItem of items ) {

      if( userHomeItem.slug === type ) {
        return userHomeItem;
      }

      if( typeof( userHomeItem.children ) !== 'undefined' ) {

        let pluckChild = this.pluckUserHomeItem( userHomeItem.children , type );

        if( pluckChild.slug !== 'unknown-item' ) {
          return pluckChild;
        }

      }

    }

    return {
      label: 'Unknown item',
      slug: 'unknown-item',
    }

  }

  setTiles( items: UserHomeItem[] ): void {
    this.currentTiles = items;
  }

  clearTiles() {
    this.currentTiles = [];
  }

  resetTiles(): void {
    this.setTiles( this.navigationItems );
  }

  setChildrenAsTiles( navItem: UserHomeItem ): void {
    this.setTiles( navItem.children );
  }

  tabIsCollapsed( navItem: UserHomeItem ) {

    if( typeof( this.currentSection ) === 'undefined' ) {
      return true;
    }

    if( this.currentSection.slug !== navItem.slug ) {
      return true;
    }

    return false;

  }

  executeMethod( methodName: string , object: any ): void {

    let method = this[ methodName ];

    if( typeof( method ) !== 'function' ) {
      return;
    }

    this[ methodName ]( object );

  }

  addService( service: UserHomeItem , markSkipped: boolean = false ): void {

    let modalOptions = Object.assign(
      {
        data: {
          heading: 'Please Wait',
          component: PleaseWaitComponent,
          object: {},
        }
      },
      {
        class: 'modal-lg',
        ignoreBackdropClick: true,
      }
    );

    if( ! markSkipped ) {
      this.openModal( modalOptions );
    }

    this.subscriptions.push( this.servicesService.createService( service.slug , service ).pipe(
      first()
    ).subscribe(
      resp => {

        this.servicesService.patchService( resp );

        if( ! markSkipped ) {
          this.modalRef.hide();
          this.showModal( service );
        }

        if( markSkipped ) {
          this.markSkipped( resp );
        }

      }
    ));

  }

  cancelAService( tile: UserHomeItem ): void {

    this.subscriptions.push( this.servicesService.cancelAService( tile.userObject['id'] ).subscribe(
      resp => {
        this.servicesService.removeService( resp );
      },
      error => {

      },
    ));

  }

  markSkipped( object: Object ): void {
    this.servicesService.updateService( object['service_type'] , object['id'] , { service_type_status: 'skipped' } , ['service_type_status'] ).subscribe(
      resp => {
        this.servicesService.patchService( resp );
      },
      error => {
        //console.log( error );
      },
    );
  }

  markStarted( object: Object ): void {
    this.servicesService.updateService( object['service_type'] , object['id'] , { service_type_status: 'started' } , ['service_type_status'] ).subscribe(
      resp => {
        this.servicesService.patchService( resp );
      },
      error => {
        //console.log( error );
      },
    );
  }

  getParentStatus( tile: UserHomeItem ): string {

    let status = 'started';
    let completeCount = 0;
    let notStartedCount = 0;

    for( let child of tile.children ) {

      if( child.disabled ) {
        continue;
      }

      let childStatus = this.getServiceStatus( child );

      if( childStatus === 'started' || childStatus === 'submitted' ) {
        return 'started';
      }

      if( childStatus === 'not-started' ) {
        notStartedCount++;
      }

      if( childStatus === 'complete' || childStatus === 'skipped' ) {
        completeCount++;
      }

    }

    if( notStartedCount !== 0 && completeCount === 0 ) {
      return 'not-started';
    }

    if( notStartedCount === 0 && completeCount !== 0 ) {
      return 'complete';
    }

    return status;

  }

  getServiceStatus( tile: UserHomeItem ): string {

    let service = tile.userObject;
    let children = tile.children;

    if( typeof( children ) !== 'undefined' ) {
      return this.getParentStatus( tile );
    }

    if( typeof( service ) === 'undefined' ) {
      return 'not-started';
    }

    if( typeof( service['service-part-children'] ) === 'undefined' ) {
      //return 'not-started';
    }

    if( service['service-part-children'] === 0 ) {
      //return 'not-started';
    }

    if( service['service_type_status'] === 'complete' ) {
      return 'complete';
    }

    if( service['service_type_status'] === 'submitted' ) {
      return 'submitted';
    }

    if( service['service_type_status'] === 'skipped' ) {
      return 'skipped';
    }

    return 'started';

  }

  getButtonLabel( tile: UserHomeItem ): string {

    let serviceStatus = this.getServiceStatus( tile );

    if( serviceStatus === 'started' ) {
      return 'View / Update';
      //return 'Continue';
    }

    if( serviceStatus === 'submitted' ) {
      return 'View';
    }

    if( serviceStatus === 'complete' ) {
      return 'View / Update';
    }

    return 'Start Now';

  }

  getButtonColor( tile: UserHomeItem ): string {

    let serviceStatus = this.getServiceStatus( tile );

    if( serviceStatus === 'started' ) {
      return 'secondary';
    }

    if( serviceStatus === 'submitted' ) {
      return 'secondary';
    }

    if( serviceStatus === 'complete' ) {
      return 'secondary';
    }

    return 'default';

  }

  getServiceStatusColor( tile: UserHomeItem ): string {

    let serviceStatus = this.getServiceStatus( tile );

    if( serviceStatus === 'started' ) {
      return 'warning';
    }

    if( serviceStatus === 'submitted' ) {
      return 'warning';
    }

    if( serviceStatus === 'skipped' ) {
      return 'success';
    }

    if( serviceStatus === 'complete' ) {
      return 'success';
    }

    return 'danger';

  }

  getServiceStatusText( tile: UserHomeItem ): string {

    let serviceStatus = this.getServiceStatus( tile );

    if( serviceStatus === 'started' ) {
      return 'Started';
    }

    if( serviceStatus === 'submitted' ) {
      return 'Submitted';
    }

    if( serviceStatus === 'skipped' ) {
      return 'Skipped';
    }

    if( serviceStatus === 'complete' ) {
      return 'Complete';
    }

    return 'Not Started';

  }

  getNominatedPersonTypes() {
    return [
      { navItemType: 'make-things-easy' , type: 'mte-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'letter-of-intent' , type: 'loi-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'when-ive-gone' , type: 'wig-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'lasting-power-of-attorney' , type: 'lpa-store' , label: 'attorney(s)' },
      { navItemType: 'last-will-and-testiment' , type: 'store-a-will' , label: 'executor(s)' },
    ];
  }

  getNominatedPersonText( navigationItem: UserHomeItem ): string {

    let defaultValue = 'nominated person(s)';
    let nominatedPersonTypes = this.getNominatedPersonTypes();

    if( typeof( navigationItem ) === 'undefined' ) {
      return defaultValue;
    }

    let matchedTypes = nominatedPersonTypes.filter( nominatedPersonType => nominatedPersonType.navItemType === navigationItem.slug );
    return ( matchedTypes.length !== 0 ? matchedTypes[0].label : defaultValue );

  }

  hasNominatedPerson( navigationItem: UserHomeItem ): boolean {

    if( ! this.userIsActive()) {
      return true;
    }

    let nominatedPersonTypes = this.getNominatedPersonTypes();

    if( typeof( navigationItem ) === 'undefined' ) {
      return true;
    }

    if( typeof( navigationItem.children ) === 'undefined' ) {
      return true;
    }

    let matchedChildren = navigationItem.children.filter( child => {

      if( typeof( child.userObject ) === 'undefined' ) {
        return false;
      }

      if( typeof( child.userObject['service_part_children'] ) === 'undefined' ) {
        return false;
      }

      if( child.userObject['service_part_children'].length === 0 ) {
        return false;
      }

      return nominatedPersonTypes.filter( nominatedPersonType => nominatedPersonType.type === child.slug ).length > 0;

    });


    return matchedChildren.length > 0;

  }

  hasOutstandingNotifications( navigationItem: UserHomeItem ) {

    if( ! this.userIsActive()) {
      return false;
    }

    let nominatedPersonTypes = this.getNominatedPersonTypes();

    if( typeof( navigationItem ) === 'undefined' ) {
      return false;
    }

    if( typeof( navigationItem.children ) === 'undefined' ) {
      return false;
    }

    if( ! this.hasNominatedPerson( navigationItem )) {
      return false;
    }

    let matchedChildren = navigationItem.children.filter( child => {

      if( typeof( child.userObject ) === 'undefined' ) {
        return false;
      }

      if( typeof( child.userObject['service_part_children'] ) === 'undefined' ) {
        return false;
      }

      if( child.userObject['service_part_children'].length === 0 ) {
        return false;
      }

      return true;

    });

    if( matchedChildren.length === 0 ) {
      return false;
    }

    for( let matchedChild of matchedChildren ) {

      if( typeof( matchedChild.userObject ) === 'undefined' ) {
        continue;
      }

      if( typeof( matchedChild.userObject['notifications_are_complete'] ) === 'undefined' ) {
        continue;
      }

      if( matchedChild.userObject['notifications_are_complete'] ) {
        continue;
      }

      return true;

    }

    return false;

  }

  canCancel( tile: UserHomeItem ): boolean {

    if([ 'started' ].indexOf( this.getServiceStatus( tile )) === -1 ) {
      return false;
    }

    return true;

  }

  canAutoComplete( tile: UserHomeItem ): boolean {

    if([ 'not-started' ].indexOf( this.getServiceStatus( tile )) === -1 ) {
      return false;
    }

    return true;

  }

  showModal( service: UserHomeItem ): void {

    if( typeof( service.component ) === 'undefined' ) {
      return;
    }

    if( typeof( service.userObject ) === 'undefined' ) {
      return this.addService( service );
    }

    if( service.userObject['service_type_status'] === 'skipped' ) {
      this.markStarted( service.userObject );
    }

    let modalOptions = Object.assign(
      {
        data: {
          heading: service.label,
          component: service.component,
          object: service.userObject,
        }
      },
      {
        class: 'modal-lg',
        ignoreBackdropClick: true,
      }
    );

    this.openModal( modalOptions );

  }

  openModal( modalOptions: Object ): void {
    this.modalRef = this.modalService.show( ModalComponent , modalOptions );
  }

  hideSideNav(): void {

    if( this.sideNav.sidenavBreakpoint <= window.innerWidth ) {
      return;
    }

    this.sideNav.hide();

  }

  toggleSideNav(): void {
    this.sideNav.toggle();
  }

  canViewSummary() {

    if( this.currentSection.slug === 'home' ) {
      return false;
    }

    if( this.currentTiles.length === 0 ) {
      return false;
    }

    return true;

  }

  userIsActive(): boolean {

    if( typeof( this.currentUser ) === 'undefined' ) {
      return false;
    }

    if( ! this.currentUser['wig_activation_status'] ) {
      return false;
    }

    if( ! this.currentUser['wig_activation_status']['active'] ) {
      return false;
    }

    return true;

  }

  openAccountSettingsModal(): void {
    console.log( 'Check' );
    this.wpMessagesService.broadcastMessage({
      type: 'open-account-settings',
      message: 'none'
    });
  }

}

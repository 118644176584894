import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { MasterFormComponent } from '../../../../master-form/master-form.component';

import { ThirdPartyDataService } from '../../../../../services/third-party-data/third-party-data.service';

import { SelectOption } from '../../../../../classes/select-option';
import { RantTopic } from '../../../../../classes/rant-topic';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class VaultAddEditComponent implements OnInit {

  @ViewChild( MasterFormComponent ) formComponent: MasterFormComponent;

  @Input() object: any;
  @Input() key: any;
  @Input() extraData: any;

  topics: RantTopic[];
  topicOptions: SelectOption[];
  selectedTopic: number|string;

  loading: boolean;

  constructor(
    private thirdPartyDataService: ThirdPartyDataService,
  ) { }

  ngOnInit() {

    this.loading = true;

    this.thirdPartyDataService.getRantTopics().subscribe(

      resp => {
        this.topics = resp;
        this.topicOptions = this.prepareItems( this.topics );
        this.loading = false;
      },

      error => {
        //console.log( error );
        this.loading = false;
      },

    );

  }

  prepareItems( topics: RantTopic[] ): SelectOption[] {

    let options = []

    for( var i = 0; i < topics.length; i++ ) {

      let option: SelectOption = {
        value: topics[ i ].id,
        label: topics[ i ].title.rendered,
      }

      options.push( option );

    }

    return options;

  }

  getLabelFromValue( value: number ): string|boolean {

    for( var i = 0; i < this.topicOptions.length; i++ ) {

      let thisActivityOption = this.topicOptions[ i ];

      if( thisActivityOption.value === value ) {
        return thisActivityOption.label;
      }

    }

    return false;

  }

  getSelectedOption( event: number ): boolean {

    let value = this.getLabelFromValue( event );

    if( ! value ) {
      return false;
    }

    this.object['item_name'] = value;
    this.formComponent.updateObjectValue( 'item_name' , value );

    return true;

  }

}

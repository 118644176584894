import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { WpConfigService } from '../wp-config/wp-config.service';
import { WpServerService } from '../wp-server/wp-server.service';

import { AppConfig } from '../../classes/app-config/app-config';
import { WpQuery } from '../../classes/wp-query/wp-query';

@Injectable({
  providedIn: 'root'
})
export class WpCacheService {

  private appCache = new BehaviorSubject({});
  currentappCache = this.appCache.asObservable();

  private config: AppConfig;
  private cacheExpiry: Object;

  constructor(
    private wpConfigService: WpConfigService,
    private wpServerService: WpServerService,
  ) {

    this.cacheExpiry = {}

    this.wpConfigService.currentAppConfig.subscribe(
      resp => {

        if( resp === {} ) {
          return;
        }

        this.config = resp;
        this.initiateCacheExpiry();

      }
    );

  }

  updateAppCache( values: Object ): void {
    this.appCache.next( values );
  }

  clearCache(): void {
    localStorage.removeItem( 'gih-cache' );
  }

  initiateCacheExpiry(): void {

    if( typeof( this.config.cacheExpiryService ) === 'undefined' ) {
      return;
    }

    this.updateCacheExpiry();

    setInterval( _ => {
      this.updateCacheExpiry();
    }, this.config.cacheExpiryService.frequency );

  }

  updateCacheExpiry(): any {

    this.wpServerService.executeWpQuery( this.config.cacheExpiryService.url , this.config.cacheExpiryService.nameSpace ).subscribe(
      resp => {

        if( typeof( resp ) === 'undefined' || typeof( resp['body'] ) === 'undefined' ) {
          return;
        }

        this.cacheExpiry = resp['body'];
        this.updateAppCache( this.cacheExpiry );

      }
    );

  }

  cacheRequest( query: WpQuery , url: string , results: HttpResponse<any> ): void {

    if( typeof( results ) === 'undefined' ) {
      return;
    }

    if( ! this.canCacheRequest( query.postType )) {
      return;
    }

    let cache = JSON.parse( localStorage.getItem( 'gih-cache' ));
    cache = ( ! cache ? {} : cache );

    let newHeaders = {
      get: function( findKey ) {
        for( let key in this ) {
          if( key === findKey ) {
            return this[ key ];
          }
        }
      }
    }

    for( let item of this.getHeadersList() ) {
      newHeaders[ item ] = results.headers.get( item );
    }

    newHeaders['time'] = new Date().toISOString();

    results['cachedHeaders'] = newHeaders;

    cache[ url ] = results;

    localStorage.setItem( 'gih-cache' , JSON.stringify( cache ));
    return;

  }

  getCachedValue( query: WpQuery , url: string ): any {

    if( ! this.canCacheRequest( query.postType )) {
      return false;
    }

    let cache = JSON.parse( localStorage.getItem( 'gih-cache' ));
    cache = ( ! cache ? {} : cache );

    let cachedResult = ( typeof( cache[ url ] ) !== 'undefined' ? cache[ url ] : false );

    if( ! cachedResult ) {
      cachedResult = this.getSingleFromCache( query , cache );
    }

    if( ! cachedResult ) {
      return false;
    }

    if( typeof( this.cacheExpiry[ query.postType] ) !== 'undefined' ) {

      let date = new Date( cachedResult['cachedHeaders']['time'] );
      let expiryDate = new Date( this.cacheExpiry[ query.postType] * 1000 );

      if( date < expiryDate ) {
        return false;
      }

    }

    const observableResult = new Observable( observer => {
      observer.next( cachedResult );
    });

    return observableResult;

  }

  canCacheRequest( postType: string ): boolean {

    if( typeof( this.config.cacheRules ) === 'undefined' ) {
      return false;
    }

    if( this.config.cacheRules.neverCache.indexOf( postType ) !== -1 ) {
      return false;
    }

    if( this.config.cacheRules.alwaysCache.indexOf( postType ) === -1 && ! this.config.cacheRules.global ) {
      return false;
    }

    return true;

  }

  getSingleFromCache( query: WpQuery , cache: Object ): any {

    if( typeof( query.id ) === 'undefined' ) {
      return false;
    }

    let postType = query.postType;
    let id = query.id;

    let searchContinue = true;
    var parentCache = false;

    for( var key in cache ) {

      let split = key.split( '?' );
      let keyCheck = split[0];

      if( keyCheck === postType ) {

        for( let item of cache[ key ]['body'] ) {
          if( item['id'] === id ) {
            return {
              cachedHeaders: cache[ key ]['cachedHeaders'],
              body: item,
            };
          }
        }

      }

    }

    return false;

  }

  getHeadersList(): string[] {
    return [
      'x-wp-totalpages',
      'content-type',
      'cache-control',
      'x-wp-total',
    ]
  }

}

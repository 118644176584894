<header id="header" data-sidebar-type="landing-page-intro">
  <!--Navbar-->
  <nav class="navbar navbar-expand-lg navbar-light scrolling-navbar">

		<a href="https://wig-web-dev.bedevious.co.uk/" class="waves-effect waves-light logo-wrapper"><img src="https://wig-web-dev.bedevious.co.uk/wp-content/uploads/2019/11/when-ive-gone.png"></a>

		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse head-container" id="navbarSupportedContent">

			<!--Links-->
			<div class="menu-main-menu-container">
				<ul id="menu-main-menu" class="nav navbar-nav">
					<li *ngFor="let navItem of navItems" class="menu-item nav-item">
            <a *ngIf="showNavItem( navItem ) && navItem['type'] === 'url'" [href]="navItem['target']" class="nav-link waves-effect waves-light">{{ navItem['text'] }}</a>
            <a *ngIf="showNavItem( navItem ) && navItem['type'] === 'route'" [routerLink]="navItem['target']" class="nav-link waves-effect waves-light">{{ navItem['text'] }}</a>
          </li>
				</ul>
			</div>

			<div *ngIf="loggedInStatus === 'logged-in'" class="btn-group head-profile head-drop-btn ml-5" mdbDropdown>
				<button mdbDropdownToggle type="button" class="dropdown-toggle waves-light" mdbWavesEffect>
					<span>Welcome back, <b>{{ userData['first_name'] }}</b></span> | Menu
					<!-- <img class="rounded-circle img-responsive" src="https://mdbootstrap.com/img/Photos/Avatars/img%20%289%29.jpg"> -->
				</button>
				<div class="dropdown-menu dropdown-primary dropdown-menu-right">
					<!-- <a class="dropdown-item" href="#">Edit Profile</a>
					<a class="dropdown-item" href="#">Change Password</a> -->
          <a class="dropdown-item" routerLink="/user-dashboard">Dashboard</a>
          <a class="dropdown-item" (click)="openAccountSettingsModal()">Account Settings</a>
					<div class="divider dropdown-divider"></div>
          <a class="dropdown-item"><span (click)="logout()">Log Out</span></a>
				</div>
			</div>

			<div class="clear"></div>

		</div>
	</nav>
  <!--/.Navbar-->
</header>
<div style="min-height: 3em"></div>

<div *ngIf="! showSummaryOnly()" class="row">
  <div class="col-md-12">

    <div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
      <h2 class="card-title h2">Your Donation Preferences</h2>
      <p>From 20 May 2020, the system for organ donation changed to an ‘opt- out’ system.
        All adults in England are considered to have agreed to donate their own organs when they die,
        unless they record a decision not to donate, known as an ‘opt out’.</p>
    </div>

    <p class="text-center">We <strong>strongly recommend</strong> that you record your donation preferences <a href="https://www.organdonation.nhs.uk/" target="_blank">here with the NHS</a>,
      but you can also record them here so that your family know your wishes as well.</p>

    <app-service-completion [service]="object"></app-service-completion>

    <div class="mb-5 mt-4">
      <p class="h2 text-center">First off, the big question:</p>
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <mdb-select
          [(ngModel)]="object['donate_your_organs']"
          [options]="selectOptions"
          (selected)="updateDonorChoice( $event )"
          placeholder="Please select"
          label="Do you want to donate your organs in the event of your death?"
        ></mdb-select>
      </div>
      <div class="col-md-3"></div>
    </div>

    <div *ngIf="showDonorOption()">

      <div class="mb-2 mt-4">
        <p class="h2 text-center">OK, now choose all the items you are happy to donate:</p>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >
        </div>
        <div class="col-md-3"></div>
      </div>

    </div>

  </div>
</div>

<div *ngIf="showSummaryOnly()">
  <p *ngIf="object['donate_your_organs'] === 'no'">I do not want to donate my organs.</p>
  <p *ngIf="object['donate_your_organs'] === 'yes'">I want to donate my organs as per the following:</p>
  <ul *ngIf="object['donate_your_organs'] === 'yes'">
    <li><strong>Heart:</strong> {{ getYesNoLabels( 'donate_heart' ) }}</li>
    <li><strong>Lungs:</strong> {{ getYesNoLabels( 'donate_lungs' ) }}</li>
    <li><strong>Kidneys:</strong> {{ getYesNoLabels( 'donate_kidneys' ) }}</li>
    <li><strong>Liver:</strong> {{ getYesNoLabels( 'donate_liver' ) }}</li>
    <li><strong>Corneas:</strong> {{ getYesNoLabels( 'donate_corneas' ) }}</li>
    <li><strong>Pancreas:</strong> {{ getYesNoLabels( 'donate_pancreas' ) }}</li>
    <li><strong>Tissues:</strong> {{ getYesNoLabels( 'donate_tissue' ) }}</li>
    <li><strong>Small Bowel:</strong> {{ getYesNoLabels( 'donate_small_bowel' ) }}</li>
  </ul>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { BankAccountAddEditComponent } from './add-edit/add-edit.component';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'bank-account';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: BankAccountAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'bank-account',
        title: 'Bank Accounts',
        type: 'children',
        childType: 'bank-account',
        plural: 'bank accounts',
        singular: 'Bank account',
        min: 0,
        max: 0,
      },
    }

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

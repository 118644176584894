import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

import { StandardTableComponent } from '../../../angular-mdb-pipeline/components/standard-table/standard-table.component';
import { PipelineTab } from '../../../angular-mdb-pipeline/classes/pipeline-tab/pipeline-tab';

import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

import { SingleReferralComponent } from '../../single-referral/single-referral.component';

@Component({
  selector: 'app-referral-table',
  templateUrl: './referral-table.component.html',
  styleUrls: ['./referral-table.component.scss']
})
export class ReferralTableComponent extends StandardTableComponent {

  public modalRef: MDBModalRef;

  openModal( referral: WpObject ) {

    this.modalRef = this.modalService.show( SingleReferralComponent, {
      class: 'modal-lg modal-dialog-scrollable',
      data: {
        referral: referral,
      }
    });

  }

  showFee( tab: PipelineTab ) {
    return ([
      'completed',
      'invoiced',
      'unbillable',
      'remunerated',
      'supplier-completed',
      'supplier-invoiced',
      'supplier-unbillable',
      'supplier-remunerated'
    ].indexOf( tab.slug ) !== -1 ? true: false );
  }

  setToPending( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.setReferralToPending( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  setToLive( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.setReferralToLive( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  returnToLive( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.returnToLive( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  setToComplete( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.setReferralToComplete( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  setToCancelled( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.setReferralToCancelled( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  requestCancel( object: WpObject ): void {

    this.disableActions = true;

    this.actionService.requestReferrtalToCancelled( object ).subscribe(
      resp => {
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

}

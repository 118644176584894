import { Component, OnInit , Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';

@Component({
  selector: 'app-standard-referral-details-form',
  templateUrl: './standard-referral-details-form.component.html',
  styleUrls: ['./standard-referral-details-form.component.scss']
})
export class StandardReferralDetailsFormComponent implements OnInit, OnDestroy {

  @Input() object: Object;
  public key: string;
  public extraData: Object;

  private subscriptions: Subscription[];

  constructor(
    private globalCommsService: GlobalCommsService,
  ) {
      this.subscriptions = [];
  }

  ngOnInit(): void {

    this.key = 'general-referral';

    this.extraData = {
      heading: 'Confirm Referral Details',
    }

    this.subscriptions.push( this.globalCommsService.currentUserData.subscribe(
      resp => {

        let fields = [
          { objectKey: 'referral_full_name' , userKey: 'name' },
          { objectKey: 'referral_email_address' , userKey: 'email' },
          { objectKey: 'referral_contact_telephone_number' , userKey: 'phone-number' },
        ]

        for( let field of fields ) {

          if( typeof( this.object[ field.objectKey ] ) !== 'undefined' && this.object[ field.objectKey ] !== '' ) {
            continue;
          }

          this.object[ field.objectKey ] = resp[ field.userKey ];

        }

      }
    ));

  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

}

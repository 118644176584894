import { Injectable } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { EventModalComponent } from '../../components/event-modal/event-modal.component';
import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public modalRef: MDBModalRef;

  constructor( private modalService: MDBModalService ) { }

  openModal( parent: WpObject ) {

    let modalOptions = {
      class: 'modal-lg',
      data: {
        referral: parent, 
        query: {
          postType: 'events',
          pageNumber: 1,
          args: {
            status: 'private',
            child_type: 'event',
            service_part_parent: parent.id,
          },
        }
      }
    }

    this.modalRef = this.modalService.show( EventModalComponent , modalOptions );

  }

}

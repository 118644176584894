import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { DialogueModalComponent } from '../../components/dialogue-modal/dialogue-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogueModalService {

  private dialogueResponse = new BehaviorSubject({});
  currentDialogueResponse = this.dialogueResponse.asObservable();

  public modalRef: MDBModalRef;

  constructor(
    private modalService: MDBModalService,
  ) { }

  openDialogueModal( modalOptions: Object ): void {

    this.modalRef = this.modalService.show( DialogueModalComponent , modalOptions );

    this.modalRef.content.action.subscribe(
      (result: any) => {
        this.updateDialogueResponse( result );
      }
    );

  }

  updateDialogueResponse( value: any ): void {
    this.dialogueResponse.next( value );
    this.dialogueResponse.next({
      key: 'clear',
    });
  }

}

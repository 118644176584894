import { Component, OnInit, Input } from '@angular/core';
import { MiscItemAddEditComponent } from './parts/add-edit/add-edit.component';

@Component({
  selector: 'app-misc-item',
  templateUrl: './misc-item.component.html',
  styleUrls: ['./misc-item.component.scss']
})
export class MiscItemComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'misc-item';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: MiscItemAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'misc-item',
        title: 'Miscellaneous Items',
        type: 'children',
        childType: 'misc-item',
        plural: 'misc items',
        singular: 'Misc item',
        min: 0,
        max: 0,
      },
    }

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-md-6">
        <h1 class="mb-4">Your Cart</h1>
      </div>
      <div class="col-md-6">
        <div class="float-right">
        </div>
      </div>
    </div>

    <div class="row">

      <!--Rotating card-->
      <div class="col-lg-4 col-xl-3">

        <div class="d-lg-none">
          <mdb-accordion [multiple]="false" aria-multiselectable="true">
            <mdb-accordion-item  [collapsed]="true">
              <mdb-accordion-item-head>View Profile</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <app-user-profile></app-user-profile>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>

        <div class="d-none d-lg-block">
          <app-user-profile></app-user-profile>
        </div>

      </div>
      <!--/.Rotating card-->

      <div class="col-lg-8 col-xl-9">

        <div class="row">
          <div class="col-md-12">
            <!--Card-->
            <div class="card card-cascade narrower">
              <!--Card content-->
              <div class="card-body">

                <h3 class="text-left"><strong>Review Your Purchase</strong></h3>
                <p>Please see below the details of your purchase from When I've Gone</p>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><a>
                          <mdb-icon far icon="times-circle" style="color: red;"></mdb-icon>
                        </a></td>
                      <td><img src="assets/service-images/alvaro-serrano-133360-unsplash.1.jpg" style="width: 50px; height: 50px;" /></td>
                      <td>Simple Will - For you and your partner</td>
                      <td>£19.99</td>
                      <td>1</td>
                      <td>£19.99</td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <input style="width: auto; display: inline-block; height: 46px; position: relative; top: 2px;" class="float-left m-1" type="text" class="form-control" placeholder="Coupon Code">
                        <button type="button" class="btn btn-default">Apply Coupon</button>
                        <button routerLink="/checkout" type="button" class="btn btn-default float-right">Proceed to Checkout</button>
                      </td>
                    </tr>
                  </tfoot>

                </table>

              </div>
              <!--/.Card content-->
            </div>
            <!--/.Card-->
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>

<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
</div>
<div class="modal-body">

  {{ text }}

  <div *ngIf="fields">
    <div *ngFor="let field of fields">

      <div *ngIf="field['type'] === 'number'" class="md-form">
        <input type="number" [id]="field['key']" [name]="field['key']" [(ngModel)]="object[ field['key'] ]" class="form-control" mdbInput autofocus>
        <label [for]="field['key']" class="">{{ field['label'] }}</label>
      </div>

      <div *ngIf="field['type'] === 'textarea'" class="md-form">
        <textarea type="text" [id]="field['key']" [name]="field['key']" [(ngModel)]="object[ field['key'] ]" class="md-textarea form-control" mdbInput autofocus></textarea>
        <label [for]="field['key']">{{ field['label'] }}</label>
      </div>

      <div *ngIf="field['type'] === 'date'" class="md-form">
        <mdb-date-picker
          [inline]="true"
          [id]="field['key']"
          [name]="field['key']"
          [placeholder]="field['label']"
          [(ngModel)]="object[ field['key'] ]"
          [required]="field['required']"
          [options]="field['options']">
        </mdb-date-picker>
      </div>

      <div *ngIf="field['type'] === 'checkbox'" class="md-form">
        <div>{{ field['label'] }}</div>
        <mdb-checkbox *ngFor="let option of field['options']" [id]="option['key']" [name]="option['key']" [(ngModel)]="object[ option['key'] ]">{{ option['label'] }}</mdb-checkbox>
      </div>

    </div>
  </div>

</div>

<div class="modal-footer">
  <button *ngFor="let option of options" type="button" mdbBtn color="{{ option.type }}" class="waves-light" aria-label="Close" (click)="returnValue( option.value )" mdbWavesEffect>
    {{ option.text }}
  </button>
</div>

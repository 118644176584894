export class WpObject {
  author?: number;
  id?: number;
  title?: {
    rendered: string;
  } | string
  content?: {
    rendered: string;
  } | string
  date?: string;
  type?: string;
  parent?: number;
  status?: string;
  author_name?: string;
  author_avatar?: string;
  featured_media?: number;
}

<div class="row">
  <div class="col-lg-12">
    <ng-container *ngTemplateOutlet="ListItemForm"></ng-container>
  </div>
</div>

<ng-template #ListItemForm>

  <p>Add details of your loyalty card below</p>

  <hr />

  <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >

</ng-template>

<mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3 indigo lighten-1'" [contentClass]="'card'">
  <mdb-tab heading="Your Rant">

    <!-- Jumbotron -->
    <div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
      <h2 class="card-title h2">The last ranting testiment of Your Name</h2>
    </div>

    <div *ngIf="sections">
      <div *ngFor="let section of sections">
        <h3 class="text-center" [innerHTML]="section.item_name"></h3>
        <div [innerHTML]="section.content | safeHtml"></div>
        <hr />
      </div>
    </div>

  </mdb-tab>

  <mdb-tab heading="Edit Your Rant">

    <app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
      <div *app-content-loader="let item" class="flex-fill">

        <div class="row">
          <div class="col-sm-12">
            {{ item['item_name'] }}
          </div>
        </div>

      </div>
    </app-service-child-list>


  </mdb-tab>
</mdb-tabset>

<footer data-skin="white-skin" class="page-footer center-on-small-only">
  <!--Footer Links-->
  <div class="container pt-2 pb-2">
    <div class="row">
      <div class="col-md-12">
        <a href="https://wig-web-dev.bedevious.co.uk/" class="waves-effect waves-light logo-wrapper"><img src="https://wig-web-dev.bedevious.co.uk/wp-content/uploads/2019/11/when-ive-gone.png"></a>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-md-6">
        <div class="menu-footer-menu-container">
          <ul id="menu-footer-menu" class="menu">
            <li id="menu-item-205" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-205"><a href="https://wig-web-dev.bedevious.co.uk/why-use-us">Why Use Us</a></li>
            <li id="menu-item-207" class="ww-login-modal-launcher menu-item menu-item-type-custom menu-item-object-custom menu-item-207"><a href="#">Free Will Quote</a></li>
            <li id="menu-item-206" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"><a href="https://wig-web-dev.bedevious.co.uk/pricing">Pricing</a></li>
            <li id="menu-item-208" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-208"><a href="#">FAQs</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 text-right social">
        <a href="" class="inline"><i class="fab fa-facebook fa-2x"></i></a>
        <a href="" class="inline"><i class="fab fa-twitter fa-2x"></i></a>
        <a href="" class="inline"><i class="fab fa-youtube fa-2x"></i></a>
      </div>
    </div>
  </div>
  <!--/.Footer Links-->
  <!--Copyright-->
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          © 2020 When I've Gone - <a href="">Privacy Policy</a> | <a href="">Terms &amp; Conditions</a>
        </div>
        <div class="col-md-6 text-right">
          <a href="#page-top" class="smooth-scroll">Top <i class="fa fa-arrow-up"></i></a>
        </div>
      </div>
    </div>
    <!--/.Copyright-->
  </div>
</footer>

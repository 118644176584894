import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { AppConfig } from '../../classes/app-config/app-config';

@Injectable({
  providedIn: 'root'
})
export class WpConfigService {

  private appConfig = new BehaviorSubject({});
  currentAppConfig = this.appConfig.asObservable();

  constructor() { }

  updateAppConfig( values: AppConfig ): void {
    this.appConfig.next( values );
  }

}

<div class="wig-lw-title-section mb-3">
  <h2></h2>
  <app-powered-by-lm></app-powered-by-lm>
</div>

<p *ngIf="object['service_type_status'] !== 'complete'">Obtain a <b>FREE personalised quote</b> from our trusted solicitors <b>Churchers</b>, who are regulated by the
  <a role="button" class="text-primary"
    mdbPopover="Being regulated by the SRA means that Churchers are bound by strict professional and ethical rules and that they carry professional indemnity insurance."
    placement="right"
    mdbPopoverHeader="Solicitors Regulation Authority"
    triggers="focus">Solicitors Regulation Authority</a>.</p>

<div *ngIf="! hasWillUrl()">

  <p>Wills on whenivegone.com are administered by our legal partners at <b>Churchers</b>.</p>
  <p>In order to begin updating your Will, we need to pass some of your details over to <b>Churchers</b>, which we need your consent to do.</p>
  <p>The information that will be passed across will be:</p>

  <ul>
    <li>Your full name</li>
    <li>Your email address</li>
  </ul>

  <p>Please press the "I Agree" button below to consent to us sharing your data with Churchers</p>

  <button (click)="agreeToTerms()" mdbBtn color="primary" class="my-4 waves-effect z-depth-0" mdbWavesEffect [disabled]="loading">
    <span *ngIf="! loading">I Agree</span>
    <span *ngIf="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Loading...
    </span>
  </button>

</div>

<div *ngIf="object['service_type_status'] === 'complete'">
  <p>Congratulations! Your will with Churchers has been completed!</p>
</div>

<div *ngIf="hasWillUrl()" class="row">

  <div class="col-md-12">

    <p *ngIf="object['service_type_status'] !== 'complete'">Click below for their modern and simple to use online service, and shortly thereafter receive free advice about your options and a fixed fee quote for updating your Will.</p>

    <div class="list-group list-group-flush">
      <hr />

      <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

        <div class="flex-fill">

          <div class="row">
            <div class="col-sm-12">

                Your Will with Churchers

            </div>
          </div>

        </div>

        <div style="min-width: 188px;">
          <button *ngIf="hasWillUrl() && object['service_type_status'] !== 'complete'" (click)="openWillWriter( object )" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Open Will Writer</button>
          <button *ngIf="! hasWillUrl()" (click)="showModal()" target="_blank" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Open Will Writer</button>
          <span *ngIf="object['service_type_status'] === 'complete'">Complete</span>
        </div>
      </div>

    </div>

  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { PensionAddEditComponent } from './parts/add-edit/add-edit.component';

@Component({
  selector: 'app-pension',
  templateUrl: './pension.component.html',
  styleUrls: ['./pension.component.scss']
})
export class PensionComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'pension';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: PensionAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'pension',
        title: 'Pensions',
        type: 'children',
        childType: 'pension',
        plural: 'pensions',
        singular: 'Pension',
        min: 0,
        max: 0,
      },
    }

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

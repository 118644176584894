import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectArrayFilter'
})
export class ObjectArrayFilterPipe implements PipeTransform {

  transform( objects: Object[] , args: Object ): any {

    let key = args['key'];
    let values = args['values'];
    let matchesRequired = ( typeof( args['matches'] ) === 'undefined' ? 1 : args['matches'] );
    let returnObjects = [];

    for( let item of objects ) {

      //console.log( item[ key ] );

      if( typeof( item[ key ] ) === 'undefined' || item[ key ].length === 0 ) {
        returnObjects.push( item );
        continue;
      }

      for( let required of item[ key ] ) {

        if( values.indexOf( required ) === -1 ) {
          continue;
        }

        returnObjects.push( item );

      }

    }

    return returnObjects;

  }

}

import { Component, OnInit, Input, QueryList, ContentChild, TemplateRef, ComponentFactoryResolver } from '@angular/core';
import { CdkDragDrop , moveItemInArray } from '@angular/cdk/drag-drop';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SBItemComponent } from 'ng-uikit-pro-standard';

import { ServicesService } from '../../services/services/services.service';
import { GlobalCommsService } from '../../services/global-comms/global-comms.service';
import { ApiFieldsService } from '../../services/api-fields/api-fields.service';

import { ModalComponent } from '../../components/modal/modal.component';
import { MasterFormComponent } from '../master-form/master-form.component';

import { ContentLoaderDirective } from '../../directives/content-loader.directive';

@Component({
  selector: 'app-service-child-list',
  templateUrl: './service-child-list.component.html',
  styleUrls: ['./service-child-list.component.scss']
})
export class ServiceChildListComponent implements OnInit {

  @ContentChild( ContentLoaderDirective , { read: TemplateRef } ) dynamicContent: ContentLoaderDirective;

  @Input() object: any;
  @Input() key: any;
  @Input() extraData: any;

  items;
  loading: boolean = false;
  wait: boolean = false;
  description: string;
  subDescription: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public servicesService: ServicesService,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private modalClose: GlobalCommsService,
    public apiFieldsService: ApiFieldsService,
  ) {
    this.items = [];
  }

  ngOnInit() {

    let type = this.extraData['section']['childType'];

    this.apiFieldsService.fieldsRef.subscribe(
      resp => {

        if( typeof( resp[ this.key ] ) === 'undefined' ) {
          this.apiFieldsService.setFieldReference( this.key );
          return;
        }

        if( resp[ this.key ].length === 0 ) {
          return;
        }

        if( typeof( resp[ this.key ][0]['description'] ) !== 'undefined' ) {
          this.description = resp[ this.key ][0]['description'];
        }

        if( typeof( resp[ this.key ][0]['detailedDescription'] ) !== 'undefined' ) {
          this.subDescription = resp[ this.key ][0]['detailedDescription'];
        }

      }
    );

    if( typeof( this.object['service_part_children'] ) === 'undefined' ) {

      this.loading = true;

      this.servicesService.getServiceParts( this.object.id , type ).subscribe(
        resp => {
          this.items = resp;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );

    }

    if( typeof( this.object['service_part_children'] ) !== 'undefined' ) {
      this.items = this.object['service_part_children'];
    }

    this.modalClose.currentRef.subscribe(
      modalStatus => {
        if( typeof( modalStatus ) === 'object' && modalStatus['action'] === 'closePartModal' ) {

          this.modalRef.hide();

          if( ! modalStatus['object'] ) {
            return;
          }

          if( modalStatus['object']['service_part_type'] !== this.key ) {
            return;
          }

          if( modalStatus['object']['service_parent'] !== this.object.id ) {
            return;
          }

          if( this.items.filter( item => item.id === modalStatus['object']['id'] ).length !== 0 ) {
            return;
          }

          this.items.push( modalStatus['object'] );

          this.modalClose.updateGlobalRef({
            action: 'replace-items',
            items: this.items,
          });

          this.object[ this.key ]++;

          if( typeof( this.object.class ) !== 'undefined' ) {
            this.object.class.checkProgress();
          }

        }
      }
    );

  }

  showModal( section ) {

    let config = {
      data: {
        ignoreBackdropClick: true,
        heading: 'Add / Edit ' + this.extraData['section']['singular'],
        key:  this.key,
        object: section,
        extraData: {
          heading: this.extraData['section']['title'],
          parentID: this.object.id,
          type: this.extraData['section']['childType'],
        }
      }
    }

    if( typeof( this.extraData['component'] ) !== 'undefined' ) {
      config.data['component'] = this.extraData['component'];
    }

    if( typeof( this.extraData['component'] ) === 'undefined' ) {
      config.data['component'] = MasterFormComponent;
    }

    if( typeof( this.extraData['options'] ) !== 'undefined' ) {
      Object.assign( config , this.extraData['options'] );
    }

    this.modalRef = this.modalService.show( ModalComponent , config );

    this.modalClose.updateGlobalRef( 'open' );

  }

  delete( section ) {

    this.wait = true;

    this.servicesService.deleteServicePart( section.id ).subscribe(

      resp => {

        for( var i = 0; i < this.items.length; i++ ) {
          if( this.items[i]['id'] === resp['id'] ) {
            this.items.splice( i , 1 );
          }
        }

        this.modalClose.updateGlobalRef({
          action: 'replace-items',
          items: this.items,
        });

        this.wait = false;
        this.object[ this.key ]--;
        this.object.class.checkProgress();

      },

      error => {
        this.wait = false;
      }

    );

  }

  moveItem( event: CdkDragDrop<string[]> ) {

    moveItemInArray( this.items , event.previousIndex , event.currentIndex );

    this.modalClose.updateGlobalRef({
      action: 'replace-items',
      items: this.items,
    });

    this.updateItemOrders();

  }

  updateItemOrders() {

    let thisObject = this;

    this.items.forEach( function( item , key ) {
      thisObject.servicesService.updateServicePart(
        thisObject.key,
        item['id'],
        {
          wig_menu_order: key,
        },
        [
          'wig_menu_order',
        ]
      ).subscribe();
    });

  }

  showSummaryOnly() {

    if( typeof( this.extraData ) === 'undefined' ) {
      return true;
    }

    if( typeof( this.extraData['showSummaryOnly'] ) === 'undefined' ) {
      return true;
    }

    return this.extraData['showSummaryOnly'];

  }

}

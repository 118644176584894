<div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Bucket List</h2>
  <p>So much to do, so little time! Create a list of all the things you want to do before you kick the bucket and tick them off when they're done!</p>
</div>

<app-service-completion [service]="object"></app-service-completion>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row">
      <div class="col-sm-8">

        {{ item['item_name'] }}
        <div
          *ngIf="! item['linked_timeline'] && item['bucket_item_done'] && miscData[ item['id'] ] !== 'hide'"
          style="color: blue;cursor: pointer;"
          (click)="create( 'life-events' , item ); miscData[ item['id'] ] = 'hide';"
        ><small>Add to Life Timeline</small></div>

      </div>

      <div class="col-sm-1">
        <mdb-icon class="text-success" fas icon="check" *ngIf="item['bucket_item_done']"></mdb-icon>
      </div>

      <div class="col-sm-3">
        {{ item['bucket_item_date_completed'] | date }}
      </div>
    </div>

  </div>
</app-service-child-list>

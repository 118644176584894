import { Injectable } from '@angular/core';

import { BankAccountsComponent } from '../../parts/vault/bank-accounts/bank-accounts.component';
import { LifeInsuranceComponent } from '../../parts/vault/life-insurance/life-insurance.component';
import { SavingInvestmentComponent } from '../../parts/vault/saving-investment/saving-investment.component';
import { UtilityComponent } from '../../parts/vault/utility/utility.component';
import { DebtComponent } from '../../parts/vault/debt/debt.component';
import { PensionComponent } from '../../parts/vault/pension/pension.component';
import { MiscItemComponent } from '../../parts/vault/misc-item/misc-item.component';

import { SimpleWillComponent } from '../../parts/vault/wills/simple-will/simple-will.component';
import { LmWillComponent } from '../../parts/vault/lw-will/lw-will.component';
import { DonorInformationComponent } from '../../parts/vault/donor-information/donor-information.component';
import { DeathInstructionsComponent } from '../../parts/vault/death-instructions/death-instructions.component';
import { FuneralArrangementsComponent } from '../../parts/vault/funeral-arrangements/funeral-arrangements.component';
import { RantComponent } from '../../parts/vault/rant/rant.component';
import { BucketComponent } from '../../parts/vault/bucket/bucket.component';
import { MyLifeComponent } from '../../parts/vault/my-life/my-life.component';
import { LoyaltyCardsComponent } from '../../parts/vault/loyalty-cards/loyalty-cards.component';
import { PhotoAlbumComponent } from '../../parts/vault/photo-album/photo-album.component';
import { EulogyComponent } from '../../parts/vault/eulogy/eulogy.component';
import { MusicComponent } from '../../parts/vault/music/music.component';
import { LpaComponent } from '../../parts/vault/lpa/lpa.component';
import { LwWillReviewComponent } from '../../parts/vault/lw-will-review/lw-will-review.component';
import { LwWillUpdateComponent } from '../../parts/vault/lw-will-update/lw-will-update.component';
import { LwWillStoreComponent } from '../../parts/vault/lw-will-store/lw-will-store.component';
import { NominatedPersonsComponent } from '../../parts/vault/nominated-persons/nominated-persons.component';
import { LpaStoreComponent } from '../../parts/vault/lpa-store/lpa-store.component';

import { MessagesComponent } from '../../parts/vault/messages/messages.component';
import { SecretsComponent } from '../../parts/vault/secrets/secrets.component';
import { LoveHateComponent } from '../../parts/vault/love-hate/love-hate.component';

import { ComingSoonComponent } from '../../parts/vault/coming-soon/coming-soon.component';

import { UserHomeItem } from '../../classes/user-home-item/user-home-item';

import { InitialWillFormComponent } from '../../parts/initial-will-form/initial-will-form.component';

@Injectable({
  providedIn: 'root'
})
export class ServicesDefinitionService {

  constructor() { }

  getServicesDefinition(): UserHomeItem[] {
    return [
      {
        disabled: false,
        label: 'Make Things Easy',
        slug: 'make-things-easy',
        iconImage: 'assets/icons/dash-easy.png',
        tile: {
          image: 'assets/user-dashboard/make-things-easy.jpg',
          description: 'Help your loved ones by leaving details of everything they need (bank accounts, pensions, subscriptions, financial arrangements etc) in one easily accessible place (your secure vault).',
          buttonType: 'primary',
          buttonLabel: 'Go To Section',
        },
        children: [
          {
            disabled: false,
            label: 'Nominated Person(s)',
            slug: 'mte-nominated-contacts',
            onClick: 'showModal',
            component: NominatedPersonsComponent,
            summarySortOrder: 10,
            tile: {
              image: 'assets/service-images/suhyeon-choi-x2zrIVw1B3o-unsplash.jpg',
              description: 'Nominate people to deal with all the accounts you have listed here on your behalf',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Banks Accounts',
            slug: 'bank-accounts',
            onClick: 'showModal',
            component: BankAccountsComponent,
            tile: {
              image: 'assets/service-images/carlos-muza-hpjSkU2UYSU-unsplash.jpg.jpg',
              description: 'Your executor will withdraw any money and distribute it to your beneficiaries according to your Will',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Life Insurance',
            slug: 'life-insurance',
            onClick: 'showModal',
            component: LifeInsuranceComponent,
            tile: {
              image: 'assets/service-images/lukas-juhas-2JJz3u_R_Ik-unsplash.jpg',
              description: 'Your executor will need to contact your life insurance provider(s) to find out what to do next',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Debts',
            slug: 'debts',
            onClick: 'showModal',
            component: DebtComponent,
            tile: {
              image: 'assets/service-images/steve-johnson-WVUrbhWtRNM-unsplash.jpg',
              description: 'Your debts, such as mortgages, loans or credit cards must be paid off before the remainder of your estate is distributed as per the instructions in your Will',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Savings and Investments',
            slug: 'savings-investments',
            onClick: 'showModal',
            component: SavingInvestmentComponent,
            tile: {
              image: 'assets/service-images/annie-spratt-Jr8byYZmTTU-unsplash.jpg',
              description: 'Your executor will need to know details of your savings and investments',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Pensions',
            slug: 'pensions',
            onClick: 'showModal',
            component: PensionComponent,
            tile: {
              image: 'assets/service-images/diane-helentjaris-5f1RsK-pyZQ-unsplash.jpg.jpg',
              description: 'Your executor will need to contact your pension provider(s) to find out what to do next',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Utility Companies',
            slug: 'utility-companies',
            onClick: 'showModal',
            component: UtilityComponent,
            tile: {
              image: 'assets/service-images/jung-ho-park-f9r2GdqmfcQ-unsplash.jpg',
              description: 'Leave details of your utility companies',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'General / Miscellaneous',
            slug: 'general',
            onClick: 'showModal',
            component: MiscItemComponent,
            tile: {
              image: 'assets/service-images/varun-yadav-4AmLEKgmBm4-unsplash.jpg',
              description: 'Leave details of your subscriptions, loyalty and store cards, memberships etc',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          /*{
            disabled: false,
            label: 'View Summary',
            slug: 'summary',
            onClick: 'showModal',
            component: MiscItemComponent,
            tile: {
              image: 'assets/service-images/varun-yadav-4AmLEKgmBm4-unsplash.jpg  ',
              description: 'View a summary of all the information stored here and record who you want to take care of these things for you!',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
          /*{
            label: 'Subscriptions',
            slug: 'subscriptions',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/freestocks-11SgH7U6TmI-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Finances',
            slug: 'finances',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/campaign-creators-pypeCEaJeZY-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Loyalty Cards and Points',
            slug: 'loyalty',
            onClick: 'showModal',
            component: LoyaltyCardsComponent,
            tile: {
              image: 'assets/service-images/rebecca-aldama-RzLBQH73I7Y-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
        ],
      },
      {
        label: 'Lasting Power of Attorney',
        slug: 'lasting-power-of-attorney',
        iconImage: 'assets/icons/hammer.png',
        tile: {
          image: 'assets/service-images/bret-kavanaugh-MIfUp3FA5ek-unsplash.jpg',
          description: 'A Lasting Power of Attorney (LPA) lets you appoint one or more people to act for you if you have an accident or an illness and cannot make your own decisions.',
          buttonType: 'primary',
          buttonLabel: 'Go To Section',
        },
        children: [
          {
            label: 'Make a Lasting Power of Attorney',
            slug: 'lpa-referral',
            onClick: 'showModal',
            component: LpaComponent,
            showInSummary: false,
            tile: {
              image: 'assets/service-images/online-marketing-hIgeoQjS_iE-unsplash.jpg',
              description: 'A Lasting Power of Attorney (LPA) lets you appoint one or more people to act for you if you have an accident or an illness and cannot make your own decisions. There are two types of LPA, one for your health and welfare, and one for your property and financial affairs.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Store details of your LPA(s)',
            slug: 'lpa-store',
            onClick: 'showModal',
            component: LpaStoreComponent,
            summarySortOrder: 10,
            tile: {
              image: 'assets/service-images/suhyeon-choi-x2zrIVw1B3o-unsplash.jpg',
              description: 'Store details of where your stored your LPA(s) as well as your attornies so that they can be contacted if needed.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          /*{
            label: 'Risks of not having an LPA',
            slug: 'risks',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/loic-leray-fCzSfVIQlVY-unsplash.jpg',
              description: 'If you lose mental capacity without an LPA in place, your family must apply to the Court of Protection to have a deputy appointed to deal with everyday financial matters. This is a slow and very expensive process, costing thousands of Pounds. If you have to use a lawyer it could cost a lot more. If you already have an LPA in place, this will not be necessary.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Health and welfare lasting power of attorney',
            slug: 'health-lpa',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/online-marketing-hIgeoQjS_iE-unsplash.jpg',
              description: 'Use this LPA to give an attorney the power to make decisions about things like your daily routine, for example washing, dressing, eating, medical care, moving into a care home and life-sustaining treatment',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Property and financial affairs lasting power of attorney',
            slug: 'property-lpa',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/tierra-mallorca-rgJ1J8SDEAY-unsplash.jpg',
              description: 'Use this LPA to give an attorney the power to make decisions about money and property for you, for example – managing a bank or building society account, paying bills, collecting benefits or a pension as well as selling your home if it were needed.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
        ],
      },
      {
        disabled: false,
        label: 'Letter of Intent',
        slug: 'letter-of-intent',
        iconImage: 'assets/icons/dash-letter.png',
        tile: {
          image: 'assets/user-dashboard/letter-of-intent-big.jpg',
          description: 'Create a letter of intent with When I’ve Gone and ease the future burden on your loved ones by giving them the comfort of knowing they carried out your final wishes.',
          buttonType: 'primary',
          buttonLabel: 'Go To Section',
        },
        children: [
          {
            disabled: false,
            label: 'Nominated Person(s)',
            slug: 'loi-nominated-contacts',
            onClick: 'showModal',
            component: NominatedPersonsComponent,
            summarySortOrder: 10,
            tile: {
              image: 'assets/service-images/suhyeon-choi-x2zrIVw1B3o-unsplash.jpg',
              description: 'Nominate people who you\'d like to deal with your funeral and other items on your Letter of Intent',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Funeral Arrangements',
            slug: 'funeral',
            onClick: 'showModal',
            component: FuneralArrangementsComponent,
            tile: {
              image: 'assets/service-images/mayron-oliveira-mibn6LLm9kA-unsplash.jpg.jpg',
              description: 'There are various things to consider when thinking about your funeral. For example: the cost; do you choose to be buried or cremated, and in which location; would you prefer an environmentally friendly funeral; are there particular clothes you would like to wear in the coffin?',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Funeral Music',
            slug: 'music',
            onClick: 'showModal',
            component: MusicComponent,
            tile: {
              image: 'assets/service-images/malte-wingen-PDX_a_82obo-unsplash.jpg.jpg',
              description: 'The mourners at your funeral may always associate the music that you choose with you thereafter, so choosing the music is very important. There are usually three opportunities for music to be played at a funeral, once on entry, once on exit and once when the final farewell is said during the ceremony.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Eulogy',
            slug: 'eulogy',
            onClick: 'showModal',
            component: EulogyComponent,
            tile: {
              image: 'assets/service-images/josh-applegate-Mly2F0TL9zc-unsplash.jpg.jpg',
              description: 'Your eulogy captures who you were as a person, so writing your own eulogy allows you to show people how you want them to remember you. Writing your own eulogy allows you a chance to share a final piece of yourself with the people you care about most.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Donor Details',
            slug: 'donor',
            onClick: 'showModal',
            component: DonorInformationComponent,
            tile: {
              image: 'assets/service-images/luann-hunt-X20g2GQsVdA-unsplash.jpg.jpg',
              description: 'From 20 May 2020, the system for organ donation changed to an ‘opt- out’ system. All adults in England are considered to have agreed to donate their own organs when they die, unless they record a decision not to donate, known as an ‘opt out’.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Death Instructions',
            slug: 'death-instructions',
            onClick: 'showModal',
            component: DeathInstructionsComponent,
            tile: {
              image: 'assets/service-images/ahmed-adly-1PtM6b85sdw-unsplash.jpg',
              description: 'Let your next of kin know any important or urgent matters that you\'d need them to take care of in the event of your death.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
        ],
      },
      {
        label: 'Last Will and testament',
        slug: 'last-will-and-testiment',
        iconImage: 'assets/icons/dash-will.png',
        tile: {
          image: 'assets/user-dashboard/last-will-testament-big.jpg',
          description: 'Making a Will is essential to minimise any disagreements over your estate and it guarantees that your wishes are carried out.',
          buttonType: 'primary',
          buttonLabel: 'Go To Section',
        },
        children: [
          // {
          //   label: 'Initial Will Form Test',
          //   slug: 'initial-will',
          //   onClick: 'showModal',
          //   component: InitialWillFormComponent,
          //   showInSummary: false,
          //   tile: {
          //     image: 'assets/service-images/melinda-gimpel-wkfZyteTMOA-unsplash.jpg.jpg',
          //     description: 'Testing the original new will form',
          //     buttonType: 'primary',
          //     buttonLabel: 'Start Now',
          //     onClick: 'showModal',
          //   },
          // },
          {
            label: 'Make a New Will',
            slug: 'lw-will',
            onClick: 'showModal',
            component: LmWillComponent,
            showInSummary: false,
            tile: {
              image: 'assets/service-images/melinda-gimpel-wkfZyteTMOA-unsplash.jpg.jpg',
              description: 'Get a quote for a new Will from our legal partners',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Review or Update an Existing Will',
            slug: 'request-a-review',
            onClick: 'showModal',
            component: LwWillReviewComponent,
            showInSummary: false,
            tile: {
              image: 'assets/service-images/markus-winkler--fRAIQHKcc0-unsplash.jpg.jpg',
              description: 'All set with your Will as far as you know? Request a free Will review to be sure or request an update if you know you need to make changes.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          /*{
            label: 'Request an Update',
            slug: 'request-an-update',
            onClick: 'showModal',
            component: LwWillUpdateComponent,
            tile: {
              image: 'assets/service-images/markus-winkler-cxoR55-bels-unsplash.jpg.jpg',
              description: 'Have something to update on your Will? Request an update here',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
          {
            label: 'Store Details of a Will',
            slug: 'store-a-will',
            onClick: 'showModal',
            component: LwWillStoreComponent,
            tile: {
              image: 'assets/service-images/viktor-talashuk-05HLFQu8bFw-unsplash.jpg.jpg',
              description: 'Store details of your will with us to make it easily available to your next of kin when you die.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
        ],
      },
      {
        disabled: false,
        label: 'When I&apos;ve Gone',
        slug: 'when-ive-gone',
        iconImage: 'assets/icons/dash-gone.png',
        tile: {
          image: 'assets/user-dashboard/when-ive-gone.jpg',
          description: 'Leave messages for loved ones, release secrets and say what you want to say when you have gone.',
          buttonType: 'primary',
          buttonLabel: 'Go To Section',
        },
        children: [
          {
            disabled: false,
            label: 'Nominated Person(s)',
            slug: 'wig-nominated-contacts',
            onClick: 'showModal',
            component: NominatedPersonsComponent,
            summarySortOrder: 10,
            tile: {
              image: 'assets/service-images/suhyeon-choi-x2zrIVw1B3o-unsplash.jpg',
              description: 'Nominate people who you\'d like to receive the items in the When I\'ve Gone section, including passing messages to those you\'ve left behind',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Messages',
            slug: 'messages',
            onClick: 'showModal',
            component: MessagesComponent,
            tile: {
              image: 'assets/service-images/markus-winkler-HJvccOW8nTA-unsplash.jpg.jpg',
              description: 'Would you like to leave messages for your loved ones to be sent when you’ve gone? Schedule birthday wishes or key events, such as anniversaries, to keep in touch when you’ve gone.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'Secrets',
            slug: 'secrets',
            onClick: 'showModal',
            component: SecretsComponent,
            tile: {
              image: 'assets/service-images/kristina-flour-BcjdbyKWquw-unsplash.jpg.jpg',
              description: 'Got a secret to share? Record your deepest secrets and share them privately with your choice of loved ones or share them with the world! ',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          /*{
            disabled: false,
            label: 'Bucket List',
            slug: 'bucket-list',
            onClick: 'showModal',
            component: BucketComponent,
            tile: {
              image: 'assets/service-images/jeff-kingma-ND-5eIFKeiM-unsplash.jpg.jpg',
              description: 'We all have dreams of places we would like to go or activities we would love to do! Leave details of all the things you have accomplished in your life.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
          {
            disabled: false,
            label: 'Love & Hate',
            slug: 'love-hate',
            onClick: 'showModal',
            component: LoveHateComponent,
            tile: {
              image: 'assets/service-images/clem-onojeghuo-QoJ1powdWMU-unsplash.jpg.jpg',
              description: 'Share the top 10 things that you love and hate. Even your loved ones might not know your true thoughts.',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            disabled: false,
            label: 'This is Your Life!',
            slug: 'your-life',
            onClick: 'showModal',
            component: MyLifeComponent,
            tile: {
              image: 'assets/service-images/ravi-roshan-_AdUs32i0jc-unsplash.jpg.jpg',
              description: 'It’s difficult to remember everything that you have experienced unless you recollect them randomly during conversations. Sometimes they are precious memories that your loved ones would be fascinated to know. Document your life and share those memories now or when you’ve gone. ',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          /*{
            label: 'World Leader for a Day',
            slug: 'leader',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/markus-spiske-QozzJpFZ2lg-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Change?',
            slug: 'change',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/ross-findon-mG28olYFgHI-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'THE RANT',
            slug: 'rant',
            onClick: 'showModal',
            component: RantComponent,
            tile: {
              image: 'assets/service-images/photo-boards-25QCezs8-oo-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Voice from the Grave',
            slug: 'voice-grave',
            onClick: 'showModal',
            component: ComingSoonComponent,
            tile: {
              image: 'assets/service-images/scott-rodgerson-ZLHBjxbCCEc-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },
          {
            label: 'Photo Album',
            slug: 'photo-album',
            onClick: 'showModal',
            component: PhotoAlbumComponent,
            tile: {
              image: 'assets/service-images/laura-fuhrman-73OJLcahQHg-unsplash.jpg.jpg',
              description: 'This is a short temporary description just for now',
              buttonType: 'primary',
              buttonLabel: 'Start Now',
              onClick: 'showModal',
            },
          },*/
        ],
      },
    ]
  }

}

import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { WpMessage } from '../../classes/wp-message/wp-message';

@Injectable({
  providedIn: 'root'
})
export class WpMessagesService {

  private message = new BehaviorSubject({});
  currentMessage = this.message.asObservable();

  localMessage: any;

  constructor() {
    this.localMessage = false
  }

  broadcastMessage( message: WpMessage ): void {

    if( ! message ) {
      this.localMessage = false;
    }

    if( this.localMessage ) {
      return;
    }

    this.localMessage = message;
    this.message.next( message );

  }

  clearMessage(): void {
    this.localMessage = false;
  }

}

<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-md-6">
        <h1 class="mb-4">Customer Home</h1>
      </div>
      <div class="col-md-6">
        <div class="float-right">
          <button (click)="servicesModal.show();" type="button" class="btn btn-default">Add to The Vault</button>
        </div>
      </div>
    </div>

    <div class="row">

      <!--Rotating card-->
      <div class="col-lg-4 col-xl-3">

        <div class="d-lg-none">
          <mdb-accordion [multiple]="false" aria-multiselectable="true">
            <mdb-accordion-item  [collapsed]="true">
              <mdb-accordion-item-head>View Profile</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <app-user-profile></app-user-profile>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>

        <div class="d-none d-lg-block">
          <app-user-profile></app-user-profile>
        </div>

      </div>
      <!--/.Rotating card-->

      <div class="col-lg-8 col-xl-9">

        <div class="row">
          <div class="col-md-12">
            <!--Card-->
            <div class="card card-cascade narrower">
              <!--Card content-->
              <div class="card-body">
                <h3 class="text-left mb-5"><strong>Your Vault</strong></h3>
                <div class="row">

                  <div *ngFor="let service of services | objectFilter" class="col-12 col-md-6 col-xl-4 mb-5">
                    <!--Card-->
                    <mdb-card class="height-100-card" cascade="true" narrower="true">

                      <!--Card image-->
                      <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                        <mdb-card-img [src]="service.img"></mdb-card-img>
                        <a (click)="showModal( service );">
                          <div class="mask rgba-white-slight"></div>
                        </a>
                      </div>
                      <!--/Card image-->

                      <mdb-card-body cascade="true" class="text-center">
                        <div class="height-100-card display-flex flex-column">

                          <!--Card content-->
                          <h5 class="pink-text">
                            <i [class]="'fas ' + service.icon"></i> {{ service.typeLabel }}
                          </h5>

                          <!--Title-->
                          <h4 class="card-title" [innerHTML]="service.title.rendered"></h4>
                          <!--Text-->

                          <!--Card content-->
                          <!-- <h6 class="pink-text">
                            Progress: {{ service.class.progress }}
                          </h6> -->

                          <mdb-card-text>{{ service.content.rendered }}</mdb-card-text>

                          <div class="align-bottom" *ngFor="let action of service.actions">
                            <a (click)="showModal( service );" class="btn btn-unique waves-light white-text" mdbBtn color="indigo lighten-1" mdbWavesEffect>
                              <span>View / Update</span>
                              <!-- <span *ngIf="service.class !== 'undefined' && ! service.class.complete">View / Update</span> -->
                            </a>
                          </div>

                          <app-simple-will-actions *ngIf="service.service_type === 'simple-will'" [object]="service" [data]="{view:false,generate:true,regenerate:false,pay:true}"></app-simple-will-actions>

                        </div>
                      </mdb-card-body>
                      <!--/.Card content-->

                    </mdb-card>
                    <!--/.Card-->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- Services Selection Modal -->

    <div mdbModal #servicesModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myServicesModalLabel" aria-hidden="true" style="overflow-y: auto;">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="servicesModal.hide()">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">Choose Services For Your Vault</h4>
          </div>
          <div class="modal-body">

          <div class="list-group">
            <div *ngFor="let service of services; let index = index" class="list-group-item list-group-item-action flex-column align-items-start">

              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ service.title.rendered }}</h5>
                <small>{{ service.typeLabel }}</small>
              </div>

              <p class="mb-1">{{ service.content.rendered }}</p>

              <button *ngIf="! service.active" (click)="addService( index );" [disabled]="wait" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Add to Vault</button>
              <button *ngIf="service.active" (click)="removeService( index );" [disabled]="wait" class="btn-danger"  mdbBtn type="button" size="sm" mdbWavesEffect>Remove from Vault</button>

            </div>
          </div>

          </div>
          <div class="modal-footer">
            <button type="button" mdbBtn color="default" class="waves-light" aria-label="Close" (click)="servicesModal.hide()" mdbWavesEffect>Save & Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>

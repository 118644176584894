import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ServerService } from '../../server.service';

@Injectable({
  providedIn: 'root'
})
export class ApiFieldsService implements OnInit {

  private refObject = new BehaviorSubject({});
  fieldsRef = this.refObject.asObservable();

  masterFieldsRef: object = {};
  fieldList: Array<string> = [];
  i;

  constructor(
    public serverService: ServerService
  ) {

  }

  ngOnInit() {
  }

  getformFields( form ) {
    return this.serverService.getApiFields( form );
  }

  setFieldReference( fieldSet ) {

    if( this.fieldList.indexOf( fieldSet ) !== -1 ) {
      return;
    }

    this.fieldList.push( fieldSet );

    this.setFieldReferences( fieldSet );

  }

  setFieldReferences( fieldSet ) {

    this.getformFields( fieldSet ).subscribe(

      resp => {

        this.masterFieldsRef[ fieldSet ] = resp;
        this.refObject.next( this.masterFieldsRef );

      }

    );

  }

}

import { Component } from '@angular/core';
import { PipelineComponent } from '../angular-mdb-pipeline/components/pipeline/pipeline.component';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent extends PipelineComponent {

  setupTable() {

    this.tabs = [
      {
        title: 'Started',
        slug: 'started',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'started',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Pending',
        slug: 'pending',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'pending',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Live',
        slug: 'live',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'live',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Completed',
        slug: 'completed',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'complete',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,total_billed,referral_amount,title',
          },
        },
      },
      {
        title: 'Invoiced',
        slug: 'invoiced',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'invoiced',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Unbillable',
        slug: 'unbillable',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'unbillable',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Remunerated',
        slug: 'remunerated',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'administrator',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'remunerated',
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Started',
        slug: 'supplier-started',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'started',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Pending',
        slug: 'supplier-pending',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'pending',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status,title',
          },
        },
      },
      {
        title: 'Live',
        slug: 'supplier-live',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'live',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status,title',
          },
        },
      },
      {
        title: 'Completed',
        slug: 'supplier-completed',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'complete',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,total_billed,referral_amount,title',
          },
        },
      },
      {
        title: 'Invoiced',
        slug: 'supplier-invoiced',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'invoiced',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
      {
        title: 'Remunerated',
        slug: 'supplier-remunerated',
        total: 987,
        count: 584,
        actions: [],
        allowedRoles: [
          'supplier',
        ],
        view: 'referral',
        query: {
          postType: 'referrals',
          pageNumber: 1,
          args: {
            status: 'private',
            referral_status: 'remunerated',
            child_type: 'referral',
            supplier_parent: this.searchSupplier,
            _fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title,referral_status',
          },
        },
      },
    ];

    this.globalCommsService.updateGlobalRef( 'stop-global-loading' );

    this.updateActions();
    this.showTabs = true;

  }

}

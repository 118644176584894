import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalCommsService {

  private globalRef = new BehaviorSubject('no-modal-loaded');
  currentRef = this.globalRef.asObservable();

  private services = new BehaviorSubject([{
    action: 'wait',
  }]);
  currentServices = this.services.asObservable();

  private loginStatus = new BehaviorSubject({});
  currentLoginStatus = this.loginStatus.asObservable();

  private userData = new BehaviorSubject({});
  currentUserData = this.userData.asObservable();

  private globalDataStore: Object = {};
  private globalDataSubject = new BehaviorSubject({});
  globalData = this.globalDataSubject.asObservable();

  constructor() { }

  updateGlobalRef( status: any ) {
    this.globalRef.next( status )
  }

  updateCurrentServices( services ) {
    this.services.next( services );
  }

  updateLoginStatus( statusObject: Object ) {
    this.loginStatus.next( statusObject );
  }

  updateUserData( userData: Object ) {
    this.userData.next( userData );
  }

  updateGlobalData( key: string , data: any[] ) {
    this.globalDataStore[ key ] = data;
    this.globalDataSubject.next( this.globalDataStore );
  }

}

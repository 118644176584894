import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

import { WpObject } from '../../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

import { ServerService }  from '../../../../server.service';
import { ServicesService } from '../../../../services/services/services.service';

@Component({
  selector: 'app-lw-will-store-data',
  templateUrl: './lw-will-store-data.component.html',
  styleUrls: ['./lw-will-store-data.component.scss']
})
export class LwWillStoreDataComponent implements OnInit, OnDestroy {

  @Input() object: WpObject;

  private subscriptions: Subscription[];

  public loading: boolean;
  public itemLoading: Object;
  public attachments: WpObject[];

  constructor(
    public modalRef: MDBModalRef,
    private serverService: ServerService,
    private servicesService: ServicesService,
  ) {
    this.subscriptions = [];
    this.itemLoading = {}
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getAttachedMedia();
  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  itemIsLoading( mediaItem: WpObject ): boolean {
    return this.itemLoading[ mediaItem.id ];
  }

  canDownload( mediaItem: WpObject ): boolean {
    return ( typeof( mediaItem['encrypted_file_url'] ) !== 'undefined' && mediaItem['encrypted_file_url'] !== '' );
  }

  getAttachedMedia( releaseId?: number ) {

    this.subscriptions.push( this.serverService.getMedia( this.object['id'] , this.object['author'] ).subscribe(
      resp => {

        this.attachments = resp;
        this.loading = false;

        this.itemLoading[ releaseId ] = false;

      },
      error => {
        this.loading = false;
      },
    ));

  }

  requestDownloadLink( media: WpObject ): void {

    let mediaId = media.id;
    this.itemLoading[ mediaId ] = true;

    this.subscriptions.push( this.servicesService.requestDownloadLink( this.object.id , mediaId ).subscribe(
      resp => {
        this.getAttachedMedia( mediaId );
      },
      error => {
        this.itemLoading[ mediaId ] = false;
      }
    ));

  }

}

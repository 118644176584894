import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormatter'
})
export class PriceFormatterPipe implements PipeTransform {

  transform( value: string , decimal: boolean = false ): string {

    if( typeof( value ) === 'undefined' || value === null ) {
      return '00.00';
    }

    value = value.toString();

    let newValue = ( value.indexOf( '.' ) !== -1 ? value.split( '.' )[ 0 ] : value );
    let decimalValue = ( value.indexOf( '.' ) !== -1 ? '.' + value.split( '.' )[ 1 ] : '.00' );

    decimalValue = ( decimalValue.length === 3 ? decimalValue : decimalValue + '0' );
    decimalValue = parseFloat( decimalValue ).toFixed( 2 );

    newValue = newValue.replace( /[^0-9]/g , '' );
    newValue = ( decimal ? ( parseFloat( newValue ) + parseFloat( decimalValue )).toFixed( 2 ) : newValue );

    let limit = ( decimal ? newValue.indexOf( '.' ) : newValue.length );

    for( var i = 3; i < limit; i = i + 4 ) {

      let vStart = newValue.slice( 0 , limit - i );
      let vEnd = newValue.slice( limit - i );

      newValue = vStart + ',' + vEnd;

    }

    return newValue;

  }

}

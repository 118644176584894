import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-will-form-data',
  templateUrl: './will-form-data.component.html',
  styleUrls: ['./will-form-data.component.scss']
})
export class WillFormDataComponent implements OnInit {

  @Input() object: Object;

  constructor(
    public modalRef: MDBModalRef,
  ) { }

  ngOnInit(): void {
  }

  convertAnswer( string: string ): string {
    return ( string == '1' ? 'Yes' : 'No' );
  }

}

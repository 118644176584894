import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { ExecutorAddEditComponent } from './executor-add-edit/executor-add-edit.component';

@Component({
  selector: 'app-lw-will-store',
  templateUrl: './lw-will-store.component.html',
  styleUrls: ['./lw-will-store.component.scss']
})
export class LwWillStoreComponent implements OnInit, OnDestroy {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  public exectorChildListDetails: Object;

  public loading: boolean;
  private subscriptions: Subscription[];

  constructor() {

    this.loading = false;
    this.subscriptions = [];

  }

  ngOnInit(): void {

    this.key = 'will-store-details';

    this.exectorChildListDetails = {
      key: 'will-store-executor',
      extraData: {
        heading: this.object['title']['rendered'],
        component: ExecutorAddEditComponent,
        showSummaryOnly: this.showSummaryOnly(),
        options: {
          class: 'modal-lg'
        },
        section: {
          key: 'will-store-executor',
          title: 'Executors',
          type: 'children',
          childType: 'will-store-executor',
          plural: 'executors',
          singular: 'Executor',
          min: 0,
          max: 0,
        },
      }
    }

  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

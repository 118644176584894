<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">General / Miscellaneous</h2>
  <p>Your next of kin will need to deal with any services that you have. This could include subscriptions or loyalty or store cards.
    Services can be cancelled but you can let you family make use of any loyalty points or credit you have built up!</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-4">
          Company Name
        </div>
        <div class="col-sm-4">
          Type
        </div>
        <div class="col-sm-4">
          Account Number
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-4">
        {{ item['company_name'] }}
      </div>
      <div class="col-sm-4">
        {{ item['misc_service_type'] }}
      </div>
      <div class="col-sm-4">
        {{ item['account_number'] }}
      </div>
    </div>

  </div>
</app-service-child-list>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServerService } from '../../server.service';

import { Activity } from '../../classes/activity';
import { ActivityCategory } from '../../classes/activity-category';
import { RantTopic } from '../../classes/rant-topic';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyDataService {

  categories: ActivityCategory[];
  items: Activity[];

  constructor(
    private serverService: ServerService,
  ) { }

  getActivityCategories(): Observable<ActivityCategory[]> {
    return this.serverService.getActivityCategories();
  }

  getBucketListItems( categoryArray: number[] ): Observable<Activity[]> {
    let categories = categoryArray.join();
    return this.serverService.getBucketListItems( categories );
  }

  getRantTopics(): Observable<RantTopic[]> {
    return this.serverService.getRantTopics();
  }

}

import { Component, OnInit, Input } from '@angular/core';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { ModalComponent } from '../../../../components/modal/modal.component';
import { PlainTextComponent } from '../../../../parts/plain-text/plain-text.component';

import { GlobalCommsService } from '../../../../services/global-comms/global-comms.service';
import { ServicesService } from '../../../../services/services/services.service';

@Component({
  selector: 'app-simple-will-actions',
  templateUrl: './simple-will-actions.component.html',
  styleUrls: ['./simple-will-actions.component.scss']
})
export class SimpleWillActionsComponent implements OnInit {

  @Input() object: Object;
  @Input() data: Object;

  loading: Boolean

  constructor(
    private modalClose: GlobalCommsService,
    public servicesService: ServicesService,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
  ) { }

  ngOnInit() {
  }

  showModal( info: Object ) {

    this.modalRef = this.modalService.show( ModalComponent , {
      data: {
        heading: info['heading'],
        component: PlainTextComponent,
        extraData: {
          text: info['text'],
        },
      }
    });

  }

  hideModal() {
    this.modalClose.updateGlobalRef( 'close' );
  }

  generateWill() {

    this.loading = true;

    this.servicesService.generateWill( this.object['id'] ).subscribe(

      resp => {
        //console.log( resp );

        this.loading = false;

        if( resp['result'] ) {
          this.showModal({ heading: 'Success!', text: 'Your will was generated!' });
          this.object['will_url'] = resp['data']['url'];
          return;
        }

        alert( resp['message'] );

      },

      error => {
        //console.log( error );
        this.loading = false;
        this.showModal({ heading: 'Error!', text: 'Could not generate will. Check the console for details' });
      }

    );
  }

  addToCart( id ) {

    let cart: Object = JSON.parse( localStorage.getItem( 'cart' ) );

    if( typeof( cart ) === 'undefined' || cart === null ) {
      cart = {};
    }

    cart['serviceId'] = id;

    localStorage.setItem( 'cart' , JSON.stringify( cart ) );

  }

  addAndClose( id ) {
    this.addToCart( id );
    this.hideModal();
  }

}

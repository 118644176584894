import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ApiFieldsService } from '../../services/api-fields/api-fields.service';

import { FormComponent } from '../../components/form/form.component';

@Component({
  selector: 'app-master-form',
  templateUrl: './master-form.component.html',
  styleUrls: ['./master-form.component.scss']
})
export class MasterFormComponent implements OnInit {

  @ViewChild( FormComponent ) formComponent: FormComponent;

  @Input() object: any;
  @Input() key: any;
  @Input() extraData: any;

  displayQuestions: boolean;
  questions: Array<Object>;

  childUpdate: any;

  constructor(
    public apiFieldsService: ApiFieldsService,
  ) {
  }

  ngOnInit() {

    //console.log( this.key );

    this.apiFieldsService.fieldsRef.subscribe(

      resp => {

        if( typeof( resp[ this.key ] ) === 'undefined' ) {
          this.apiFieldsService.setFieldReference( this.key );
          return;
        }

        this.questions = resp[ this.key ];
        this.concatActionQuestions(),
        this.displayQuestions = true;

      }

    );

  }

  concatActionQuestions() {

    let concat = [];
    let questionsLength = this.questions.length;
    let targetIndex = questionsLength - 1;
    let nextAction = '';

    this.questions = this.questions.concat( concat );

    if( this.questions.length === 0 ) {
      return;
    }

    //add fields to serviceParts
    if( this.object['type'] === 'service-part' && typeof( this.extraData ) !== 'undefined' && typeof( this.extraData['parentID'] ) !== 'undefined' ) {

      this.questions[ targetIndex ]['questions'].push({
        name: 'service_parent',
        required: true,
        value: this.extraData['parentID'],
        type: 'hidden',
      });

      this.questions[ targetIndex ]['questions'].push({
        name: 'service_part_type',
        required: true,
        value: this.extraData['type'],
        type: 'hidden',
      });

      if( typeof( this.object['id'] ) === 'undefined' ) {
        nextAction = 'createServicePart';
      } else {
        nextAction = 'updateServicePart';
      }

      this.questions[ targetIndex ]['nextAction'] = {
        label: 'Save Changes',
        action: nextAction,
        atts: {
          type: this.extraData['type'],
          id: this.object['id'],
        },
      }

      return;

    }

    //add a save action to everything else
    this.questions[ targetIndex ]['nextAction'] = {
      label: 'Save Changes',
      action: 'saveChanges',
      atts: {
        type: 'will',
        id: this.object['id'],
      },
    }

    //console.log( this.questions );

  }

  updateObjectValue( key: string , value: any ) {
    this.formComponent.updateObjectValue( key , value );
  }

}

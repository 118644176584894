import { Component, OnInit, Input } from '@angular/core';

import { ApiFieldsService } from '../../../services/api-fields/api-fields.service';
import { ServicesService } from '../../../services/services/services.service';

@Component({
  selector: 'app-funeral-arrangements',
  templateUrl: './funeral-arrangements.component.html',
  styleUrls: ['./funeral-arrangements.component.scss']
})
export class FuneralArrangementsComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  constructor(
    public apiFieldsService: ApiFieldsService,
    public servicesService: ServicesService,
  ) {
  }

  ngOnInit(): void {
    this.key = 'funeral';
    this.extraData = {};
  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

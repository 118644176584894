<app-header></app-header>

<!-- Sidebar navigation -->
<mdb-side-nav #sidenav class="fixed white text-dark" [fixed]="true" [sidenavBreakpoint]="992">
  <!-- Side navigation links -->
  <li>
    <ul class="collapsible collapsible-accordion">
      <mdb-accordion [multiple]="false" [autoExpand]="true" aria-multiselectable="false">

        <mdb-accordion-item class="no-collase">
          <mdb-accordion-item-head (click)="hideSideNav()" routerLink="/user-dashboard/" [customClass]="'text-dark'" (click)="resetTiles()">
            <div class="side-nav-icon"><i class="fas fa-home fa-2x"></i></div>
            <span class="text-dark">Home</span>
          </mdb-accordion-item-head>
        </mdb-accordion-item>

        <!-- Collapsible link -->
        <mdb-accordion-item #SBItemComponent *ngFor="let navigationItem of navigationItems" [class]="( ! navigationItem.children ? 'no-collase' : '' )" [collapsed]="tabIsCollapsed( navigationItem )">
          <mdb-accordion-item-head (click)="hideSideNav()" [routerLink]="'/user-dashboard/' + navigationItem.slug" mdbWavesEffect>
            <div class="side-nav-icon">
              <img style="max-width: 100%;" *ngIf="navigationItem.iconImage" [src]="navigationItem.iconImage">
            </div>
            <!-- <span *ngIf="! navigationItem.disabled" class="badge-{{ getServiceStatusColor( navigationItem ) }} rounded-circle d-inline-block position-relative" style="width: 10px; height: 10px; z-index: 9999999; top: 1px; left: -2px;"></span> -->
            <span class="text-dark" [innerHTML]="navigationItem.label"></span>
          </mdb-accordion-item-head>
          <mdb-accordion-item-body>
            <ul *ngIf="navigationItem.children">
              <li *ngFor="let navigationChild of navigationItem.children">
                <a *ngIf="navigationChild.url && ! navigationItem.disabled" [href]="navigationChild.url" mdbWavesEffect>
                  <span [innerHTML]="navigationChild.label"></span>
                </a>
                <a *ngIf="navigationChild.onClick && ! navigationItem.disabled" (click)="executeMethod( navigationChild.onClick , navigationChild )" mdbWavesEffect>
                  <span [innerHTML]="navigationChild.label"></span>
                </a>
                <a *ngIf="navigationItem.disabled" mdbWavesEffect>
                  <span [innerHTML]="navigationChild.label"></span>
                </a>
              </li>
            </ul>
          </mdb-accordion-item-body>
        </mdb-accordion-item>

      </mdb-accordion>
    </ul>
  </li>
  <!--/. Side navigation links -->
  <div class="sidenav-bg mask-strong"></div>
</mdb-side-nav>
<!--/. Sidebar navigation -->

<div id="dashboard-wrap" class="">
  <div class="p-sm-5 p-3">


  <!-- SideNav slide-out button -->
  <a (click)="toggleSideNav()" mdbBtn class="d-lg-none button-collapse indigo lighten-1">
    Menu
  </a>
  <!--/. SideNav slide-out button -->

  <h1 class="mb-4 dash-title d-flex align-items-center">
    <div [innerHTML]="currentSection.label"></div>
    <div class="ml-4" *ngIf="canViewSummary()"><a mdbBtn class="btn-default btn-sm" [routerLink]="'/user-dashboard/' + currentSection.slug + '/summary'">View Summary</a></div>
   </h1>

   <div *ngIf="! userIsActive()" role="alert" class="alert alert-warning m-2">
     <div><strong>Important!</strong></div>
     <div class="d-flex justify-content-between align-items-center">
       <span>Unless you activate your account, your nominees will have no access to your important information and instructions in the event of your death or incapacity.</span>
       <button (click)="openAccountSettingsModal()" class="btn btn-default btn-sm text-nowrap" mdbBtn>activate now</button>
     </div>
   </div>

   <div class="dash-inner">

     <div *ngIf="! hasNominatedPerson( currentSection )" class="alert alert-danger m-2" role="alert">
       You have not yet added {{ getNominatedPersonText( currentSection ) }} to this section, this means that we can't notify anyone in case of your death or incapacity.
     </div>

     <div *ngIf="hasOutstandingNotifications( currentSection )" class="alert alert-warning d-flex align-items-center justify-content-between m-2" role="alert">
       <span>You have {{ getNominatedPersonText( currentSection ) }} that you have not yet notified that they have been appointed.</span>
       <a [routerLink]="'/user-dashboard/' + currentSection.slug + '/send-notifications'" mdbBtn class="btn-default btn-sm">Notify then now!</a>
     </div>

     <app-service-summary *ngIf="part === 'summary'"></app-service-summary>
      <app-send-notifications *ngIf="part === 'send-notifications'" [navigationItem]="currentSection"></app-send-notifications>

      <div class="d-flex flex-wrap">

        <div *ngFor="let navigationItem of currentTiles; let key = index" class="w-md-50 p-2 flex-grow-1">
          <div class="inner h-100 d-flex flex-column" [style.background]="'url( ' + navigationItem.tile.image + ' )'" [style.background-size]="'cover'" [style.background-position]="'center'">
            <h3 class="title text-white text-center" [innerHTML]="navigationItem.label"></h3>
            <p class="text-white text-center" [innerHTML]="navigationItem.tile.description"></p>
            <div class="mt-auto text-center">

              <button mdbBtn *ngIf="navigationItem.disabled" class="btn btn-default waves-effect waves-light" disabled>
                Coming Soon
              </button>

              <button mdbBtn *ngIf="! navigationItem.tile.onClick && ! navigationItem.disabled" [routerLink]="'/user-dashboard/' + navigationItem.slug" class="btn btn-default waves-effect waves-light">
                {{ navigationItem.tile.buttonLabel }}
              </button>

              <button [color]="getButtonColor( navigationItem )" mdbBtn *ngIf="navigationItem.tile.onClick && ! navigationItem.disabled" (click)="executeMethod( navigationItem.tile.onClick , navigationItem )" class="btn waves-effect waves-light">
                {{ getButtonLabel( navigationItem ) }}
              </button>

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</div>

<app-footer></app-footer>

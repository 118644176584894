import { Component, OnInit, EventEmitter, Input } from '@angular/core';

import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';

import { ServerService } from '../../../server.service';
import { GlobalCommsService } from '../../../services/global-comms/global-comms.service'

import { Attachment } from '../../../classes/attachment';

export class galleryImage {
  img: string;
  thumb: string;
  description: string;
}

@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.scss']
})
export class PhotoAlbumComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  key: string;
  extraData: Object;

  files: Array<any>;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  currentUpload: string;

  loading: boolean;

  images: galleryImage[]

  constructor(
    private serverService: ServerService,
    private globalCommsService: GlobalCommsService,
  ) {

    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.loading = false;
    this.images = []

  }

  ngOnInit() {

    this.loading = true;

    this.globalCommsService.currentUserData.subscribe(
      resp => {

        if( typeof( resp['id'] ) === 'undefined' ) {
          return;
        }

        this.serverService.getMedia( this.object['id'] , resp['id'] ).subscribe(

          resp => {
            this.loading = false;
            this.prepareImages( resp );
          },

          error => {
            this.loading = false;
          }

        );

      }
    )

  }

  prepareImages( images: Attachment[] ): void {

    for( var i = 0; i < images.length; i++ ) {

      this.addImage( images[ i ] );

    }

  }

  addImage( image: Attachment ): void {

    let galleryImage: galleryImage = {
      img: image.media_details.sizes.full.source_url,
      thumb: image.media_details.sizes.shop_catalog.source_url,
      description: image.title.rendered,
    }

    this.images.unshift( galleryImage );

  }

  /**
  *
  * File upload handlers
  *
  **/

  showFiles() {

    let files = '';

    for( let i = 0; i < this.files.length; i++ ) {
      files += this.files[ i ].name;
       if( ! ( this.files.length - 1 === i )) {
         files += ',';
      }
    }

    return files;

   }

  startUpload( questionName: string ): void {
    this.currentUpload = questionName;
    this.serverService.upoadMedia( this.uploadInput , {
      post: this.object['id']
    });
  }

  cancelUpload( id: string ): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  onUploadOutput( output: UploadOutput | any ): void {

    if( output.type === 'allAddedToQueue') {
    }

    if( output.type === 'addedToQueue' ) {
      this.files.push( output.file );
    }

    if( output.type === 'start') {
    }

    if( output.type === 'uploading' ) {
      const index = this.files.findIndex( file => file.id === output.file.id );
      this.files[ index ] = output.file;
      //console.log( this.files[ index ] );
    }

    if( output.type === 'done') {

      let file = output.file;
      let response = file.response;

      this.addImage( response );

      this.files = [];

      this.currentUpload = '';

    }

    this.showFiles();

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<app-header></app-header>

<div class="view">
  <!-- Mask & flexbox options-->
  <div class="view-inner rgba-indigo lighten-1-strong d-flex justify-content-center align-items-center">
    <!-- Content -->
    <div class="container">
      <!--Grid row-->
      <div class="row pt-lg-5 mt-lg-5">
        <!--Grid column-->
        <div class="col-md-6 mb-5 mt-md-0 mt-5 white-text text-center text-md-left wow fadeInLeft" data-wow-delay="0.3s">
          <h1 class="display-4 font-weight-bold">Lorem ipsum</h1>
          <hr class="hr-light">
          <h6 class="mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem repellendus quasi
            fuga
            nesciunt dolorum nulla magnam veniam sapiente, fugiat! Commodi sequi non animi ea
            dolor molestiae, quisquam iste.</h6>
          <a mdbBtn color="white" outline="true" mdbWavesEffect>Learn more</a>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-6 col-xl-5 mb-4">
          <app-initial-will-form></app-initial-will-form>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Content -->
  </div>
  <!-- Mask & flexbox options-->
</div>

<app-footer></app-footer>

import { Component, OnInit, Input } from '@angular/core';
import { DebtAddEditComponent } from './parts/add-edit/add-edit.component';

@Component({
  selector: 'app-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['./debt.component.scss']
})
export class DebtComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'debt';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: DebtAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'debt',
        title: 'Debts',
        type: 'children',
        childType: 'debt',
        plural: 'debts',
        singular: 'Debt',
        min: 0,
        max: 0,
      },
    }

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

import { Component, OnInit, Input } from '@angular/core';

import { ServicesService } from '../../../services/services/services.service';
import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';

import { BucketAddEditComponent } from './parts/add-edit/add-edit.component';

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss']
})
export class BucketComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  key: string;
  extraData: Object;

  userServices: Array<Object>;

  miscData: Object = {};

  loading: boolean;

  constructor(
    private servicesService: ServicesService,
    private globalCommsService: GlobalCommsService,
  ) {

  }

  ngOnInit() {

    this.key = 'bucket-list-items';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: BucketAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'bucket-list-item',
        title: 'Bucket List',
        type: 'children',
        childType: 'bucket-list-item',
        plural: 'bucket list items',
        singular: 'Bucket list item',
        min: 0,
        max: 0,
      },
    }

    this.getUserServices();

  }

  getUserServices() {
    this.globalCommsService.currentServices.subscribe(
      resp => {

        if( resp.length === 0 ) {
          return;
        }

        this.userServices = resp;

        //console.log( resp );

      }
    );
  }

  getServiceId( type ): number|boolean {

    for( var i = 0; i < this.userServices.length; i++ ) {

      let thisService = this.userServices[ i ];

        //console.log( 'Checking ' + thisService['service_type'] );

      if( type === thisService['service_type'] ) {
        //console.log( 'Found a service!' );
        return thisService['id'];
      }

    }

    //console.log( 'Could not find a service!' );

    return false;

  }

  create( type: string , item: Object ): void {

    this.loading = true;

    let parentServiceId = this.getServiceId( 'your-life' );
    let data = {}
    let sendData = [
      'item_name',
      'service_parent'
    ]

    data['service_parent'] = parentServiceId;
    data['item_name'] = item['item_name'];

    if( typeof( item['bucket_item_more_info'] ) !== 'undefined' ) {
      data['text_area_description'] = item['bucket_item_more_info'];
      sendData.push( 'text_area_description' );
    }

    if( typeof( item['bucket_item_date_completed'] ) !== 'undefined' ) {
      data['date'] = item['bucket_item_date_completed'];
      sendData.push( 'date' );
    }

    Object.assign( data , {
      service_parent: parentServiceId,
    });

    this.servicesService.createServicePart( type , data , sendData ).subscribe(

      resp => {
        this.loading = false;
      },

      error => {
        this.loading = false;
      }

    );

    this.servicesService.updateServicePart( 'bucket-list-item' , item['id'] , {
      linked_timeline: 'linked',
    }, [ 'linked_timeline' ]).subscribe();

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<mdb-accordion [multiple]="false" aria-multiselectable="true">
  <mdb-accordion-item  [collapsed]="true">
    <mdb-accordion-item-head>Add an Image to the Gallery</mdb-accordion-item-head>
    <mdb-accordion-item-body>

      <!--  Start file upload field -->
      <div class="md-form">

        <label for="fileUploadInput">Add an image to the gallery</label>
        <br />

        <div class="file-field md-form">
          <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>
            <span>Choose file</span>
            <input id="fileUploadInput" name="fileUploadInput" options="{maxUploads:1}" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
          </div>
          <div class="file-path-wrapper">
            <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
          </div>
        </div>
        <button class="btn btn-md btn-default" (click)="startUpload( 'fileUploadInput' )">Start uploading</button>

        <div><small>Max file size 8mb, only image files allowed.</small></div>

      </div>
      <!-- End file upload field -->

    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>

<div class="row">
  <mdb-image-modal [modalImages]="images" type="margin"></mdb-image-modal>
</div>

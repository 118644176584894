import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AddEditAttorneyComponent } from './add-edit-attorney/add-edit-attorney.component';

@Component({
  selector: 'app-lpa-store',
  templateUrl: './lpa-store.component.html',
  styleUrls: ['./lpa-store.component.scss']
})
export class LpaStoreComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  public attorneyChildListDetails: Object;

  constructor() { }

  ngOnInit(): void {

    this.key = 'lpa-store';

    this.attorneyChildListDetails = {
      key: 'lpa-attorney',
      extraData: {
        heading: this.object['title']['rendered'],
        component: AddEditAttorneyComponent,
        showSummaryOnly: this.showSummaryOnly(),
        options: {
          class: 'modal-lg'
        },
        section: {
          key: 'lpa-attorney',
          title: 'Attornies',
          type: 'children',
          childType: 'lpa-attorney',
          plural: 'attornies',
          singular: 'Attorney',
          min: 0,
          max: 0,
        },
      }
    }

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { UploadInput } from 'ng-uikit-pro-standard';

import { ModalComponent } from './components/modal/modal.component';
import { PlainTextComponent } from './parts/plain-text/plain-text.component';

import { ActivityCategory } from './classes/activity-category';
import { Activity } from './classes/activity';
import { RantTopic } from './classes/rant-topic';
import { Attachment } from './classes/attachment';

import { WpServerService } from './angular-wordpress-rest-api/services/wp-server/wp-server.service';

const API_URL = environment.contentUrl;

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  httpOptions: Object;

  constructor(
    public http: HttpClient,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private wpServerService: WpServerService,
  ) { }

  showModal( text ) {

    this.modalRef = this.modalService.show( ModalComponent , {
      data: {
        heading: 'Error',
        component: PlainTextComponent,
        extraData: {
          text: text,
        },
      }
    });

  }

  getHttpHeaders( auth = true ) {

    let headers = {}
    let token = localStorage.getItem( 'userToken' );

    if( typeof( token ) === 'string' && auth ) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem( 'userToken' );
    }

    return this.httpOptions = {
      headers: new HttpHeaders( headers ),
    }

  }

  //*REGISTER AND LOGIN*//

  //is a user logged in?
  isLoggedIn() {

    return this.validateToken().pipe(

      map( resp => {

        if( resp['data']['status'] != 200 ) {
          return false;
        }

        return true;

      }),

      catchError( () => {
        localStorage.clear();
        return of( false );
      }),

    );

  }

  //check if an email
  emailExists( data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/check-email' , data , this.getHttpHeaders( false ) ).pipe(
      catchError( this.handleError<any>('emailExists') )
    );
  }

  //register a user
  userRegister( data ) {
    return this.wpServerService.userRegister( data );
  }

  //get a token
  getToken( data ) {
    return this.wpServerService.getToken( data );
  }

  validateToken() {
    return this.wpServerService.validateToken();
  }

  getItem( url ) {
    return this.http.get( API_URL +'/wp-json/wp/v2/' + url , this.getHttpHeaders());
  }

  //*UDPATE A USER*//

  //update user
  updateUser( id , data ) {
    return this.http.post( API_URL + '/wp-json/wp/v2/users/' + id , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('updateUser') )
    );
  }

  //get a user
  getUser( id ) {
    return this.http.get( API_URL + '/wp-json/wp/v2/users/' + id + '?context=edit' , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getUser') )
    );
  }

  //reset password
  resetPassword( data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/reset-password/', data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('resetPassword') )
    );
  }

  //setNewPassword
  setNewPassword( data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/set-new-password/', data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('setNewPassword') )
    );
  }

  //*SERVICES*//

  //creare a new services
  createService( data ) {
    return this.http.post( API_URL + '/wp-json/wp/v2/services' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('createService') )
    );
  }

  //get services
  getServices( requestString: string ) {
    return this.http.get( API_URL + '/wp-json/wp/v2/services?per_page=100&status=private' + requestString , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getServices') )
    );
  }

  //update a service
  updateService( id , data ) {
    return this.http.post( API_URL + '/wp-json/wp/v2/services/' + id , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('updateService') )
    );
  }

  //delete a service
  deleteService( id ) {
    return this.http.delete( API_URL + '/wp-json/wp/v2/services/' + id , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('deleteService') )
    );
  }

  //creare a new services
  createServicePart( data ) {
    return this.http.post( API_URL + '/wp-json/wp/v2/service-parts' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('createServicePart') )
    );
  }

  //get service parts
  getServiceParts( parent , type , user: number ) {
    let urlString = '/wp-json/wp/v2/service-parts?status=private&per_page=100&order=asc&orderby=menu_order&child_type=service-part&service_parent=' + parent + '&service-part-type=' + type + '&author=' + user;
    return this.http.get( API_URL + urlString , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getServiceParts') )
    );
  }

  //get service parts
  updateServicePart( id , data ) {
    return this.http.post( API_URL + '/wp-json/wp/v2/service-parts/' + id , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('updateServicePart') )
    );
  }

  //delete a service
  deleteServicePart( id ) {
    return this.http.delete( API_URL + '/wp-json/wp/v2/service-parts/' + id , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('deleteServicePart') )
    );
  }

  //get suppliers
  getSupplier( id ) {
    return this.http.get( API_URL + '/wp-json/wp/v2/suppliers/' + id , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getSupplier') )
    );
  }

  //get suppliers
  getProduct( id ) {
    return this.http.get( API_URL + '/wp-json/wc/v2/products/' + id , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getProduct') )
    );
  }

  //get API form fields
  getApiFields( name ) {
    return this.http.get( API_URL + '/wp-json/wig/v1/api-field-pages?context=' + name , this.getHttpHeaders( false ) ).pipe(
      catchError( this.handleError<any>('getApiFields') )
    );
  }

  //generate a will
  generateWill( id ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/generate-will' , {} , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('generateWill') )
    );
  }

  //send notification email
  sendNotificationEmail( id , note ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/service-parts/' + id + '/send-notification-email', {
      note: note,
    }, this.getHttpHeaders()).pipe(
      catchError( this.handleError<any>('sendNotificationEmail') )
    );;
  }

  //accept will terms
  acceptWillTerms( id , data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/generate-will-url' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('acceptWillTerms') )
    );
  }

  //accept lpa terms
  submitLpa( id , data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/submit-lpa' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('submitLpa') )
    );
  }

  //accept will terms
  submitWill( id , data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/submit-will' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('submitWill') )
    );
  }

  //cancel a service
  cancelAService( id ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/cancel' , {} , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('cancelAService') )
          );
        }

  //request file download link
  requestDownloadLink( id , data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/services/' + id + '/request-download-link' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('requestDownloadLink') )
    );
  }

  //pay for a will
  willPayment( data ) {
    return this.http.post( API_URL + '/wp-json/wig/v1/orders' , data , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('willPayment') )
    );
  }

  //media
  upoadMedia( uploadInput , data = {} ) {
    return this.http.post<Attachment>( API_URL + '/wp-json/wp/v2/media' , data , this.getHttpHeaders( true )).pipe(
      catchError( this.handleError<any>( 'uploadFile' ) )
    );
  }

  getMedia( parent: number , user: number ) {
    return this.http.get<Attachment[]>( API_URL + '/wp-json/wp/v2/media?per_page=100&parent=' + parent , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('willPayment') )
    );
  }

  //**3rd Party APIs*//

  //get a list of countries
  getcountries() {
    return this.http.get( 'https://restcountries.eu/rest/v2/all/' ).pipe(
      catchError( this.handleError<any>('getcountries') )
    );
  }

  //get a list of bucket list activity categories
  getActivityCategories() {
    return this.http.get<ActivityCategory[]>( API_URL + '/wp-json/wp/v2/bucket-list-item-categories' , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getActivityCategories') )
    );
  }

  getBucketListItems( categories: string ) {
    return this.http.get<Activity[]>( API_URL + '/wp-json/wp/v2/bucket-list-items?bucket-list-item-categories=' + categories + '&per_page=100&orderby=title&order=asc' , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getBucketListItems') )
    );
  }

  getRantTopics() {
    return this.http.get<RantTopic[]>( API_URL + '/wp-json/wp/v2/rant-topics?&per_page=100&orderby=title&order=asc' , this.getHttpHeaders() ).pipe(
      catchError( this.handleError<any>('getRantTopics') )
    );
  }

  //handle errors
  private handleError<T> ( operation = 'operation' , result?: T ) {
    return( error: any ): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //alert( `${operation} failed: ${error.message}` );

      let message = `${operation} failed: ${error['error']['message']}`;

      if( typeof( error['error'] ) !== 'undefined' && typeof( error['error']['message'] ) !== 'undefined' ) {
        message = `${error['error']['message']}`
      }

      this.showModal( message );

      throw result;

    };
  }

}

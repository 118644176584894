<h3 class="text-left mt-2"><strong>{{ tab['title'] }}</strong></h3>
<p *ngIf="totalCount !== 0">Total referral fees: £ {{ totalValue | priceFormatter:true }} ({{ totalCount }} referrals)</p>

<div *ngIf="loading" class="row">
  <div class="col-12">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="! loading">

  <form class="form-sm mb-3">

    <div class="md-form form-group input-group">
      <input class="form-control form-control-sm ml-3 w-75" type="text" placeholder="Search Customer Name" name="customerSearch" [(ngModel)]="currentQuery['args']['s']" aria-label="Search" mdbInput>
      <div class="input-group-append">
        <button (click)="searchRecords()" class="m-0 px-3 py-2" mdbBtn type="button" color="primary" size="md" mdbWavesEffect>Search</button>
        <button *ngIf="currentQuery['args']['s']" (click)="clearSearch()" class="m-0 px-3 py-2" mdbBtn type="button" color="danger" size="md" mdbWavesEffect>Clear</button>
      </div>
    </div>

  </form>

  <div *ngIf="referrals.length === 0" class="row">
    <div class="col-12">
      No data to show
    </div>
  </div>

  <table class="table table-striped" *ngIf="referrals.length !== 0">

    <thead>
      <tr>
        <th>Date</th>
        <th>Customer</th>
        <th>Supplier</th>
        <th>Product</th>
        <th *ngIf="showFee( tab )">Total Billed</th>
        <th *ngIf="showFee( tab )">Ref Fee</th>
        <th style="text-align: center;">Notes</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let referral of referrals" data-id="{{ referral.id }}">
        <td>
          {{ referral['date'] | date:'dd/MM/yyyy' }}<br />
          <a (click)="openModal( referral )">View</a>
        </td>
        <td>
          <div>{{ referral['author_name'] }}</div>
        </td>
        <td>{{ referral['supplier_parent_title'] }}</td>
        <td>{{ referral['product_type'] }}</td>
        <td *ngIf="showFee( tab )" style="white-space: nowrap;">
          £ {{ referral['total_billed'] | priceFormatter:true }}
        </td>
        <td *ngIf="showFee( tab )" style="white-space: nowrap;">
          £ {{ referral['referral_amount'] | priceFormatter:true }}
        </td>
        <td style="text-align: center;">
          <mdb-icon *ngIf="referral['event_count']" style="cursor: pointer;" (click)="showEvents( referral )" fas icon="sticky-note" color="success"></mdb-icon>
        </td>
        <td>
          <div *ngIf="tab['actions']">
            <button mdbBtn (click)="maybeWarnMethod( action , referral )" *ngFor="let action of tab['actions']"
              type="button" class="btn btn-{{ action.type }} table-action btn-sm btn-block mb-1" [disabled]="disableActions">
              {{ action.label }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>

  </table>

  <nav aria-label="pagination" *ngIf="referrals.length !== 0">
    <ul class="pagination pg-blue justify-content-center flex-wrap">

      <li [class]="( currentQuery['pageNumber'] === 1 ? 'page-item disabled' : 'page-item' )">
        <a (click)="onPageChange( currentQuery['pageNumber'] - 1 )" class="page-link" tabindex="-1" mdbWavesEffect>Previous</a>
      </li>

      <li *ngFor="let page of pageItems" [class]="( page == currentQuery['pageNumber'] ? 'page-item active' : 'page-item' )">
        <a (click)="onPageChange( page )" class="page-link" mdbWavesEffect>{{ page }}</a>
      </li>

      <li [class]="( currentQuery['pageNumber'] === totalPages.length ? 'disabled page-item' : 'page-item' )">
        <a (click)="onPageChange( currentQuery['pageNumber'] + 1 )" class="page-link" mdbWavesEffect>Next</a>
      </li>

    </ul>
  </nav>

</div>

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ApiFieldsService } from '../../services/api-fields/api-fields.service';
import { AccountManagementService } from '../../services/account-management/account-management.service';
import { ServicesService } from '../../services/services/services.service';

@Component({
  selector: 'app-initial-will-form',
  templateUrl: './initial-will-form.component.html',
  styleUrls: ['./initial-will-form.component.scss']
})
export class InitialWillFormComponent implements OnInit {

  @Input() object: Object;

  public displayQuestions: boolean;
  public questions: Array<Object>;

  public progress;

  constructor(
    public apiFieldsService: ApiFieldsService,
    public accountManagementService: AccountManagementService,
    public servicesService: ServicesService,
    public router: Router
  ) {

    this.questions = [];
    this.displayQuestions = false;

  }

  ngOnInit() {

    console.log( this.object );

    this.apiFieldsService.getformFields( 'simple-wills-initial-form' ).subscribe(

      resp => {
        this.questions = resp;
        this.concatActionQuestions();
        this.displayQuestions = true;
      }

    );

  }

  concatActionQuestions() {

    let questionsLength = this.questions.length;
    let targetIndex = questionsLength - 1;
    let nextAction = '';

    this.questions[ targetIndex ]['questions'].push({
      name: 'will_option',
      required: true,
      value: false,
      type: 'hidden',
    });

    this.questions[ targetIndex ]['nextAction'] = {
      label: 'Next',
      action: 'evaluateHiddenField',
      atts: {
        field: 'will_option',
        ignoreList: [
          'email',
          'first_name',
          'last_name',
          'password',
          'terms',
          'heldValues',
          'recaptcha',
        ],
        outcomes: [
          {
            value: 'simple-will',
            defaultCheck: '0',
            specificChecks: {
              who_for: 'any',
              has_children: 'any',
              are_you_over_the_age_of_18: '1',
            },
          },
          {
            value: 'custom-will',
            defaultCheck: 'any',
            specificChecks: {},
          },
        ],
      },
    }

    let concat = [
      {
        name: 'simple-will',
        description: 'Hoorah! Based on your answers it looks like we are able to generate your will online for just £xx.xx! Click save to select a solicitor.',
        questions: [],
        nextAction: {
          label: 'Save',
          action: 'saveOnly',
          atts: {
            type: 'lw-will',
            id: this.object['id'],
          },
        },
      },
      {
        name: 'custom-will',
        heading: 'Why we might not be able to generate your will online',
        description: 'Based on your answers it looks like you might need to speak to a specialist solicitor about your will and as our online will generator might not cover all of your assets. \
          Fortunately we are able to help out here and put you in touch with some solicitors as well as give you an estimated cost. Click save to save your answers and get a quote from a solicitor.',
        detailedDescription: 'Here is some more info about why we might not be able to generate a will for you online.',
        questions: [],
        nextAction: {
          label: 'Save',
          action: 'saveOnly',
          atts: {
            type: 'lw-will',
            id: this.object['id'],
          },
        },
        prevAction: {
          label: 'Check My Answers',
          action: 'goToQuestionPage',
          atts: {
            i: 0,
          }
        },
      },
    ];

    this.questions = this.questions.concat( concat );

  }

}

<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel"><span class="mb-4" [innerHTML]="referral.title['rendered']"></span> Referral #{{ referral.id }}</h4>
  </div>
  <div class="modal-body">

    <div class="p-5">

      <div class="row">
        <div class="col-md-12">

          <div class="clearfix">
            <h2 class="float-left">Services for this Referral</h2>
            <button *ngIf="canChangeServices()" (click)="addNewService.toggle()" mdbBtn type="button" class="btn btn-default btn-sm mb-1 float-right" [disabled]="disableActions">Add a Service</button>
          </div>

          <div mdbCollapse #addNewService="bs-collapse">
            <form (submit)="addServiceToReferral( newServiceType )" class="d-flex">

              <div class="md-form m-0 w-100">
                <mdb-select name="newServiceType" [(ngModel)]="newServiceType" [options]="serviceSelectOptions" placeholder="Choose a service type" required></mdb-select>
              </div>

              <button mdbBtn type="submit" class="btn btn-default btn-sm mb-1 float-right" [disabled]="disableActions">Add</button>

            </form>
          </div>

          <hr />

          <app-service-table [tab]="referralTab"></app-service-table>

          <hr />

          <app-events-table [tab]="eventTab"></app-events-table>

          <hr />

        </div>
      </div>

    </div>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialogue-modal',
  templateUrl: './dialogue-modal.component.html',
  styleUrls: ['./dialogue-modal.component.scss']
})
export class DialogueModalComponent implements OnInit {

  public action: Subject<any> = new Subject();

  public heading: string;
  public text: string;
  public options: Object[];
  public key: string;
  public object: any;
  public fields: Object[];
  public data: Object;

  constructor(
    public modalRef: MDBModalRef,
  ) { }

  ngOnInit() {
    //console.log( this );
  }

  returnValue( value: any ): void {

    this.action.next({
      value: value,
      key: this.key,
      object: this.object,
      data: this.data,
      fields: this.fields,
      options: this.options,
    });

    this.modalRef.hide();

  }

}

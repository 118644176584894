import { Component, OnInit, Input } from '@angular/core';
import { ServicesService } from '../../services/services/services.service';

export class CheckBoxEvent {
  checked: boolean;
}

@Component({
  selector: 'app-service-completion',
  templateUrl: './service-completion.component.html',
  styleUrls: ['./service-completion.component.scss']
})
export class ServiceCompletionComponent implements OnInit {

  @Input() service: Object;
  private serviceStatus: string;

  constructor(
    private servicesService: ServicesService,
  ) { }

  ngOnInit(): void {
  }

  updateService( data: Object , sendData: string[] ): void {
    this.servicesService.updateService( this.service['service_type'] , this.service['id'] , data , sendData ).subscribe(
      resp => {
        this.service = resp;
        this.servicesService.patchService( resp );
      },
      error => {
        //console.log( error );
      },
    );
  }

  isChecked(): boolean {
    return ( this.service['service_type_status'] === 'complete' ? true : false );
  }

  onChange( event: CheckBoxEvent ): void {
    let status = ( event.checked ? 'complete' : 'started' );
    this.updateService( { service_type_status: status } , ['service_type_status'] );
  }

}

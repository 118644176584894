import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MdbWysiwygComponent } from 'mdb-wysiwyg';

import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  @ViewChild( SignaturePad ) signaturePad: SignaturePad;
  @ViewChild( 'myCanvas' ) canvasRef: ElementRef;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    dotSize: 0.5,
    canvasWidth: 500,
    canvasHeight: 300
  };

  registerForm: FormGroup;
  submitted = false;

  formValues: Object;

  constructor(
    private formBuilder: FormBuilder,
    public http: HttpClient
  ) { }

  ngOnInit() {

  }

  draw() {

    let canvas = document.getElementById( 'myCanvas' ) as HTMLCanvasElement;
    let ctx: CanvasRenderingContext2D = this.canvasRef.nativeElement.getContext( '2d' );

    ctx.clearRect( 0 , 0 , 500 , 500 );

    ctx.font = '52px Qwigley';
    ctx.fillText( 'Dominic Vermeulen-Smith' , 10 , 50 );

    //console.log( canvas.toDataURL() );

  }

  clear() {
    this.signaturePad.clear();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  summary: Array<Object>;
  quotes: Array<Object>;

  constructor() { }

  ngOnInit() {

    this.summary = [
      {
        'title': 'Your Details',
        'content': 'Dominic Vermeulen-Smith',
      },
      {
        'title': 'Recommended Type of Will',
        'content': 'Single Discretionary Trust Will',
      },
      {
        'title': 'Best Price',
        'content': '£550 + VAT',
      },
    ];

    this.quotes = [
      {
        'logo': 'assets/logos/logo-ex.png',
        'location': 'Portsmouth',
        'fee': '£549.99',
        'disbursements': '£136.85',
        'total': '£686.84',
        'referral': '£39.99',
      },
      {
        'logo': 'assets/logos/logo-ex.png',
        'location': 'Portsmouth',
        'fee': '£549.99',
        'disbursements': '£136.85',
        'total': '£686.84',
        'referral': '£39.99',
      },
      {
        'logo': 'assets/logos/logo-ex.png',
        'location': 'Portsmouth',
        'fee': '£549.99',
        'disbursements': '£136.85',
        'total': '£686.84',
        'referral': '£39.99',
      },
      {
        'logo': 'assets/logos/logo-ex.png',
        'location': 'Portsmouth',
        'fee': '£549.99',
        'disbursements': '£136.85',
        'total': '£686.84',
        'referral': '£39.99',
      },
      {
        'logo': 'assets/logos/logo-ex.png',
        'location': 'Portsmouth',
        'fee': '£549.99',
        'disbursements': '£136.85',
        'total': '£686.84',
        'referral': '£39.99',
      },
    ];

  }

}

import { Injectable } from '@angular/core';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { ServerService } from '../../server.service';

import { WpLoginService } from '../../angular-wordpress-rest-api/services/wp-login/wp-login.service';
import { WpSaveService } from '../../angular-wordpress-rest-api/services/wp-save/wp-save.service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementService {

  constructor(
    public serverService: ServerService,
    private wpLoginService: WpLoginService,
    private wpSaveService: WpSaveService,
  ) { }

  //prepare sendData
  prepareData( userData , sendData ) {

    let data = {}

    for( var i = 0; i < sendData.length; i++ ) {

      if( typeof( userData[ sendData[ i ] ] ) === 'undefined' ) {
        //@todo handle missing required data
        //console.log( 'Error, please provide a ' + sendData[ i ] );
        return;
      }

      data[ sendData[ i ] ] = userData[ sendData[ i ] ];

    }

    return data;

  }

  //check if an email exists
  emailExists( userData ) {

    let sendData = [
      'email'
    ]

    let data = this.prepareData( userData , sendData );

    return this.serverService.emailExists( data );

  }

  //log a user in
  register( userData ) {

    let sendData = [
      'email',
      'password',
      'recaptcha',
    ]

    let data = this.prepareData( userData , sendData );

    data['username'] = data['email'];

    return this.serverService.userRegister( data ).pipe(

      //NB. merge map because we need to wait for this and we're not subscribing to what we're returning
      mergeMap( resp => {

        return this.login( userData ).pipe(

          //NB. tap because we don't need to wait for this and we're subscribing right here.
          mergeMap( resp => {

            return this.update( userData , [
              'first_name',
              'last_name'
            ]);

          })

        );

      }),

    );

  }

  //log a user in
  login( userData ) {

    let sendData = [
      'email',
      'password'
    ]

    let data = this.prepareData( userData , sendData );

    data['username'] = data['email'];
    delete data['email'];

    return this.wpLoginService.login( data ).pipe(
      tap( resp => {

        let token = 'missing';

        if( typeof( resp['token'] ) !== 'undefined' ) {
          token = resp['token'];
        }

        if( typeof( resp['data']['token'] ) !== 'undefined' ) {
          token = resp['data']['token'];
        }

        if( token !== 'missing' ) {
          localStorage.setItem( 'userToken' , token );
        }

        return resp;

      }),
    );

  }

  //update profile
  update( userData , sendData ) {

    let data = this.prepareData( userData , sendData );

    data['name'] = data['first_name'] + ' ' + data['last_name'];

    return this.serverService.updateUser( 'me' , data ).pipe(

      tap( resp => {
        //console.log( resp );
      }),

    );

  }

  //reset resetPassword
  resetPassword( userData , sendData ) {
    let data = this.prepareData( userData , sendData );
    return this.serverService.resetPassword( data );
  }

  //reset resetPassword
  setNewPassword( userData , sendData ) {
    let data = this.prepareData( userData , sendData );
    return this.serverService.setNewPassword( data );
  }

  //get current user's Profile
  getMe() {
    return this.serverService.getUser( 'me' );
  }

  //get a user's Profile
  getUser( id: number ) {
    return this.serverService.getUser( id );
  }

  //is the user logged in?
  isLoggedIn() {
    return this.serverService.isLoggedIn();
  }

  logout() {
    localStorage.clear();
  }

  triggerEmailConfirmation() {
    return this.wpSaveService.saveObject( 'me/send-email-verification' , {} , [] , '/wig/v1/' )
  }

  triggerPhoneConfirmation() {
    return this.wpSaveService.saveObject( 'me/send-sms-verification' , {} , [] , '/wig/v1/' )
  }

  validateCode( code: string , type: string ) {
    let sendObject = { code: code , type: type };
    return this.wpSaveService.saveObject( 'me/validate-code' , sendObject , [ 'code' , 'type' ] , '/wig/v1/' );
  }

}

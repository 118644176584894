<div class="m-2 mt-4" *ngIf="! loading">

  <div *ngFor="let service of ( navigationItem.children | objectFilter:filterObject ) | objectSort:sortString">
    <h3 [innerHTML]="service.label"></h3>

    <div *ngIf="! service.userObject">
      <i>No items have been added.</i>
    </div>

    <div *ngIf="service.userObject">
      <ng-template app-content-loader [object]="service"></ng-template>
    </div>

    <hr />

  </div>
</div>

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Store Details of an LPA</h2>
  <p>Store details of your LPA here so that it is easy for your next of kin to find details for it if it is needed.</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div class="mx-2">

  <h3 *ngIf="! showSummaryOnly()">General Details</h3>
  <app-master-form *ngIf="! showSummaryOnly()" [object]="object" [key]="key" [extraData]="extraData"></app-master-form>

  <ul *ngIf="showSummaryOnly()">
    <li><strong>My Property & Financial Affairs LPA is stored here:</strong> {{ object['lpa_store_location'] }}</li>
    <li><strong>My Health & Welfare LPA is stored here:</strong> {{ object['my_health_welfare_lpa_is_stored_here'] }}</li>
  </ul>

  <hr />

  <h3>Attorney Details</h3>

  <app-service-child-list [object]="object" [key]="attorneyChildListDetails['key']" [extraData]="attorneyChildListDetails['extraData']">
    <div *app-content-loader="let item" class="flex-fill">

      <div class="row align-items-center">
        <div class="col-sm-6">
          <strong>{{ item['lpa_store_attorney_name'] }}</strong><br />
          <span *ngIf="item['property_financial_affairs_attorney']">(Property & Financial Affairs)</span><br />
          <span *ngIf="item['health_welfare_attorney']">(Health & Welfare)</span>
        </div>
        <div class="col-sm-6">
          <small>{{ item['lpa_store_attorney_email'] }}</small><br />
          <small>{{ item['lpal_store_attorney_contact'] }}</small>
        </div>
      </div>

    </div>
  </app-service-child-list>

</div>

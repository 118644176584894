import { Component } from '@angular/core';

import { StandardTableComponent } from '../../../angular-mdb-pipeline/components/standard-table/standard-table.component';
import { PipelineTab } from '../../../angular-mdb-pipeline/classes/pipeline-tab/pipeline-tab';

import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

import { LpaFormDataComponent } from '../../vault/lpa/lpa-form-data/lpa-form-data.component';
import { LwWillStoreDataComponent } from '../../vault/lw-will-store/lw-will-store-data/lw-will-store-data.component';
import { StandardReferralDetailsComponent } from '../../standard-referral-details/standard-referral-details.component';
import { WillFormDataComponent } from '../../vault/lw-will/will-form-data/will-form-data.component';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  styleUrls: ['./service-table.component.scss']
})
export class ServiceTableComponent extends StandardTableComponent {

  private modalRef;

  showFee( tab: PipelineTab ) {
    return ([
      'completed',
      'invoiced',
      'unbillable',
      'remunerated',
      'supplier-completed',
      'supplier-invoiced',
      'supplier-unbillable',
      'supplier-remunerated'
    ].indexOf( tab.slug ) !== -1 ? true: false );
  }

  moveToNewReferral( object: WpObject ) {

    this.disableActions = true;

    this.actionService.moveServiceToReferral( object ).subscribe(
      resp => {
      this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  removeService( object: WpObject ) {

    this.disableActions = true;

    this.actionService.removeServiceFromReferral( object ).subscribe(
      resp => {
        this.removeFromReferrals( resp );
        this.disableActions = false;
      },
      error => {
        this.disableActions = false;
      },
    );

  }

  showViewLink( object: WpObject ): boolean {
    return ( [ 'lpa-referral' , 'store-a-will' , 'lw-will' , 'request-a-review' ].indexOf( object['service_type'] ) !== -1 ? true : false );
  }

  openModal( object: WpObject ): void {

    let modalComponent;

    if( [ 'lpa-referral' , 'store-a-will' , 'lw-will' , 'request-a-review' ].indexOf( object['service_type'] ) === -1 ) {
      return;
    }

    if( object['service_type'] === 'lpa-referral' ) {
      modalComponent = LpaFormDataComponent;
    }

    if( object['service_type'] === 'lw-will' ) {
      modalComponent = WillFormDataComponent;
    }

    if( object['service_type'] === 'request-a-review' ) {
      modalComponent = StandardReferralDetailsComponent;
    }

    this.modalRef = this.modalService.show( modalComponent , {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        object: object,
      }
    });

  }

}

<div class="row">
  <div class="col-md-12">

    <div class="list-group list-group-flush">

      <div *ngFor="let section of sections" (click)="showModal( section )" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between" style="cursor: pointer;">
        {{ section.title }}
        <mdb-icon *ngIf="show[ section.key ]" fas icon="check" class="green-text pr-3" aria-hidden="true"></mdb-icon>
        <mdb-icon *ngIf="! show[ section.key ]" fas icon="chevron-right" class="red-text pr-3" aria-hidden="true"></mdb-icon>
      </div>

    </div>

    <div class="mt-3 float-right">

      <app-simple-will-actions [object]="object" [data]="{view:true,generate:true,regenerate:true,pay:true}"></app-simple-will-actions>

    </div>

  </div>
</div>

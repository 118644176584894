import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ng-uikit-pro-standard';

import { ServicesService } from '../../../services/services/services.service';
import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';

import { MyLifeAddEditComponent } from './parts/add-edit/add-edit.component';

import { LifeEvent } from '../../../classes/life-event';

@Component({
  selector: 'app-my-life',
  templateUrl: './my-life.component.html',
  styleUrls: ['./my-life.component.scss']
})
export class MyLifeComponent implements OnInit {

  @ViewChild( 'staticTabs', { static: true }) staticTabs: TabsetComponent;

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  key: string;
  extraData: Object;

  sections: LifeEvent[];

  loading: any;

  constructor(
    private servicesService: ServicesService,
    private modalClose: GlobalCommsService,
  ) { }

  ngOnInit() {

    this.key = 'life-events';
    this.extraData = {
      heading: this.object['title']['rendered'],
      component: MyLifeAddEditComponent,
      showSummaryOnly: this.showSummaryOnly(),
      options: {
        class: 'modal-lg'
      },
      section: {
        key: 'life-events',
        title: 'Life Events',
        type: 'children',
        childType: 'life-events',
        plural: 'life events',
        singular: 'Life event',
        min: 0,
        max: 0,
      },
    }

    this.modalClose.currentRef.subscribe(
      modalStatus => {

        if( typeof( modalStatus ) !== 'object' ) {
          return;
        }

        if( modalStatus['action'] === 'replace-items' && typeof( modalStatus['items'] ) !== 'undefined' ) {
          this.sections = modalStatus['items'];
        }

      }
    );

    this.loadSections();

  }

  loadSections() {

    this.loading = true;

    this.servicesService.getServiceParts( this.object['id'] , this.key ).subscribe(

      resp => {
        this.sections = resp;
        this.loading = false;
      },

      error => {
        this.loading = false;
      }

    );

  }

  changeActiveTab( tab: number ): void {
    this.staticTabs.setActiveTab( tab );
  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">LPA Form Answers</h4>
  </div>
  <div class="modal-body">

    <div class="p-5">

      <div class="row">
        <div class="col-md-12">

          <p>Below is the customer information that was submitted with this request</p>

          <ul>
            <li><strong>Name:</strong> {{ object['referral_full_name'] }}</li>
            <li><strong>Email Address:</strong> {{ object['referral_email_address'] }}</li>
            <li><strong>Phone Number:</strong> {{ object['referral_contact_telephone_number'] }}</li>
            <li><strong>Comments:</strong> {{ object['refferral_any_other_info'] }}</li>
          </ul>

          <ul>
            <li><strong>LPA Type:</strong> {{ convertAnswer( object['lpa_type'] ) }}</li>
            <li><strong>Existing LPA:</strong> {{ convertAnswer( object['existing_lpa'] ) }}</li>
            <li><strong>Existing EPA:</strong> {{ convertAnswer( object['existing_epa'] ) }}</li>
            <li><strong>Existing LW:</strong> {{ convertAnswer( object['existing_lw'] ) }}</li>
            <li><strong>Existing AD:</strong> {{ convertAnswer( object['existing_ad'] ) }}</li>
            <li><strong>Accountant:</strong> {{ convertAnswer( object['accountant'] ) }}</li>
            <li><strong>Financial Advisor:</strong> {{ convertAnswer( object['financial_adviser'] ) }}</li>
            <li><strong>Business Interest:</strong> {{ convertAnswer( object['business_interest'] ) }}</li>
          </ul>

        </div>
      </div>

    </div>
  </div>

</div>

import { Component, Input } from '@angular/core';
import { StandardTableComponent } from '../standard-table/standard-table.component';

import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent extends StandardTableComponent {

  @Input() referral: WpObject;

  public addNoteAction: Object = {
    "label": "Add a note",
    "type": "primary",
    "method": "addNote",
    "warningTitle": "Add a note",
    "warning": "To add a note to this record, enter it below and click save.",
    "allowedRoles": [],
    "allowedStatus": [],
    "fields": [{
      "key": "notes",
      "type": "textarea",
      "label": "Notes",
      "required": true
    }]
  }

  addNote( object: WpObject ): void {

    this.actionService.addNote( object ).subscribe(
      resp => {
        this.refreshItems();
      },
      error => { }
    );

  }

}

import { UserHomeTile } from '../user-home-tile/user-home-tile';

export class UserHomeItem {
  children?: UserHomeItem[];
  component?: any;
  disabled?: boolean;
  icon?: string;
  iconImage?: string;
  label: string;
  onClick?: string;
  routerLink?: string;
  showInSummary?: boolean;
  slug: string;
  summarySortOrder?: number;
  tile?: UserHomeTile;
  userObject?: Object;
}

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Store Details of a Will</h2>
  <p>Store details of your will here so that it is easy for your next of kin to find details for it in the event of your death.</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div class="mx-2">

  <h3 *ngIf="! showSummaryOnly()">General Details</h3>
  <app-master-form *ngIf="! showSummaryOnly()" [object]="object" [key]="key" [extraData]="extraData"></app-master-form>

  <div *ngIf="showSummaryOnly()">
    <strong>My will is stored:</strong> {{ object['will_store_location'] }}
  </div>

  <hr />

  <h3>Executor Details</h3>

  <app-service-child-list [object]="object" [key]="exectorChildListDetails['key']" [extraData]="exectorChildListDetails['extraData']">
    <div *app-content-loader="let item" class="flex-fill">

      <div class="row align-items-center">
        <div class="col-sm-6">
          <strong>{{ item['will_store_executor_name'] }}</strong>
        </div>
        <div class="col-sm-6">
          <small>{{ item['will_store_executor_email'] }}</small><br />
          <small>{{ item['will_store_executor_contact'] }}</small>
        </div>
      </div>

    </div>
  </app-service-child-list>

</div>

<span *ngIf="loading">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...
</span>

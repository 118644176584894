import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { WpMessagesService } from '../wp-messages/wp-messages.service';
import { WpServerService } from '../wp-server/wp-server.service';
import { WpConfigService } from '../wp-config/wp-config.service';
import { WpUserService } from '../wp-user/wp-user.service';

@Injectable({
  providedIn: 'root'
})
export class WpLoginService {

  private loginStatus = new BehaviorSubject( 'unknown' );
  currentLoginStatus = this.loginStatus.asObservable();

  private loginResponse = new BehaviorSubject({});
  latestLoginResponse = this.loginResponse.asObservable();

  public userToken: string;

  private cookieLoginChecked: boolean = false;

  constructor(
    private wpMessagesService: WpMessagesService,
    private wpServerService: WpServerService,
    private wpConfigService: WpConfigService,
    private wpUserService: WpUserService,
  ) { }

  updateLoginStatus( status: string ): void {
    this.loginStatus.next( status );
  }

  updateLatestLoginResponse( response: Object ): void {
    this.loginResponse.next( response );
  }

  checkLoginStatus(): void {
    this.wpConfigService.currentAppConfig.subscribe(
      resp => {

        if( typeof( resp['authType'] ) === 'undefined' || resp['authType'] === 'jwt' ) {
          return this.checkTokenLoginStatus();
        }

        if( typeof( resp['authType'] ) !== 'undefined' && resp['authType'] === 'cookie' ) {
          return this.checkCookieLoginStatus();
        }

        return this.checkTokenLoginStatus();

      }
    );
  }

  checkCookieLoginStatus(): void {

    this.wpUserService.currentUser.subscribe(

      resp => {

        if( ! this.cookieLoginChecked ) {
          return;
        }

        if( typeof( resp['roles'] ) === 'undefined' || resp['roles'].length === 0 ) {
          return this.logout( false );
        }

        return this.updateLoginStatus( 'logged-in' );

      },

      error => {
        this.logout( false );
      }

    );

    this.cookieLoginChecked = true;
    this.wpUserService.getCurrentUser();

  }

  checkTokenLoginStatus(): void {

    let token = localStorage.getItem( 'userToken' );

    if( typeof( token ) !== 'string' ) {
      return this.logout();
    }

    this.wpServerService.validateToken().subscribe(

      resp => {

        if( typeof( resp['statusCode'] ) !== 'undefined' && resp['statusCode'] != 200 ) {
          this.wpMessagesService.broadcastMessage({
            title: {
              rendered: 'Error',
            },
            type: 'error',
            message: resp['message'],
          });
        }

        this.updateLatestLoginResponse( resp );

        if( resp['data']['status'] == 200 || resp['statusCode'] == 200 ) {
          return this.updateLoginStatus( 'logged-in' );
        }

        this.logout();

      },

      error => {
        this.logout();
      },

    )

  }

  login( data ): Observable<{}> {
    return this.wpServerService.getToken( data ).pipe(
      tap( resp => {
        this.processRequestToken( resp );
      })
    );
  }

  processRequestToken( response: Object ) {

    let status: string = 'not-logged-in';

    if( typeof( response ) === 'undefined' ) {
      return this.updateLoginStatus( status );
    }

    if( typeof( response['token'] ) !== 'undefined' ) {
      localStorage.setItem( 'userToken' , response['token'] );
      status = 'logged-in';
    }

    if( typeof( response['data'] ) !== 'undefined' && typeof( response['data']['token'] ) !== 'undefined' ) {
      localStorage.setItem( 'userToken' , response['data']['token'] );
      status = 'logged-in';
    }

    if( typeof( response['statusCode'] ) !== 'undefined' && response['statusCode'] != 200 ) {
      this.wpMessagesService.broadcastMessage({
        title: {
          rendered: 'Error',
        },
        type: 'error',
        message: response['message'],
      });
    }

    this.updateLatestLoginResponse( response );
    return this.updateLoginStatus( status );

  }

  registerUser( userData: Object ) {
    userData['username'] = userData['email'];
    return this.wpServerService.userRegister( userData );
  }

  resetPassword( userData: Object ) {
    return this.wpServerService.createObject( '/reset-password/' , userData , '/wig/v1' );
  }

  setNewPassword( userData: Object ) {
    return this.wpServerService.createObject( '/set-password/' , userData , '/wig/v1' );
  }

  logout( clearCache: boolean = true ):void {

    this.updateLoginStatus( 'not-logged-in' );

    if( clearCache ) {
      localStorage.clear();
    }

  }

}

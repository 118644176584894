import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectSort'
})
export class ObjectSortPipe implements PipeTransform {

  transform( list: Array<Object> , key: string ): Array<Object> {

    list.sort(( a: Object, b ?: Object ) => {

      a[ key ] = ( typeof( a[ key ] ) === 'string' ) ? new Date( a[ key ] ).getTime() : a[ key ];
      b[ key ] = ( typeof( b[ key ] ) === 'string' ) ? new Date( b[ key ] ).getTime() : b[ key ];

      if( ! a[ key ] && ! b[ key ] ) {
        return 0;
      }

      if( ! b[ key ] || a[ key ] < b[ key ] ) {
        return -1;
      }

      if( ! a[ key ] || a[ key ] > b[ key ] ) {
        return 1;
      }

      return 0;

    });

    return list;

  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { WpUserService } from '../../../angular-wordpress-rest-api/services/wp-user/wp-user.service';
import { WpQueryService } from '../../../angular-wordpress-rest-api/services/wp-query/wp-query.service';
import { WpQuery } from '../../../angular-wordpress-rest-api/classes/wp-query/wp-query';
import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';
import { WpUser } from '../../../angular-wordpress-rest-api/classes/wp-user/wp-user';

import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';
import { PipelineActionsService } from '../../services/pipeline-actions/pipeline-actions.service';

import { PipelineTab } from '../../classes/pipeline-tab/pipeline-tab';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent implements OnInit, OnDestroy {

  public activeIndex: number = 0;

  onActiveTabChange( event ) {

    if( typeof( event ) === 'undefined' ) {
      return;
    }

    if( typeof( event['activeTabIndex'] ) === 'undefined' ) {
      return;
    }

    this.activeIndex = event['activeTabIndex'];

  }

  public currentUser: Object;
  public currentUserRoles: string[];
  public searchSupplier: number;

  public tabs: PipelineTab[];
  public currentTab: PipelineTab;
  public currentQuery: WpQuery;

  public referrals: WpObject[];
  public currentPage: number;
  public totalPages: any;

  public currentUserId: number;
  public showTabs: boolean;

  private subscriptions: Subscription[];

  constructor(
    private wpUserService: WpUserService,
    public wpQueryService: WpQueryService,
    public globalCommsService: GlobalCommsService,
    public pipelineActionsService: PipelineActionsService,
  ) {
    this.showTabs = false
    this.tabs = []
    this.subscriptions = []
  }

  ngOnInit() {

    this.subscriptions.push( this.wpUserService.currentUser.subscribe(
      resp => {

        if( typeof( resp['roles'] ) === 'undefined' || resp['roles'].length === 0 ) {
          return;
        }

        this.currentUser = resp;
        this.currentUserRoles = resp['roles'];
        this.loadSuppliers();
        this.loadBranches();

      }
    ));

  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  loadSuppliers() {

    console.log( 'Pipeline loadSuppliers' );

    this.subscriptions.push( this.wpQueryService.getPosts({
      postType: 'suppliers',
      args: {
        _fields: 'id,title,type',
        orderby: 'title',
        order: 'asc',
        parent: 0,
        per_page: 100,
        status: 'private',
      },
    }).subscribe(
      resp => {

        console.log( 'Pipeline loadSuppliers resp' , resp );

        if( resp['body'].length !== 0 ) {
          this.searchSupplier = resp['body'][0]['id'];
        }

        this.setupTable();
        this.globalCommsService.updateGlobalData( 'suppliers' , resp['body'] );

      },
      error => {
        //console.log( error );
        this.setupTable();
      }
    ));

  }

  loadBranches() {

    console.log( 'Pipeline loadBranches' );

    this.subscriptions.push( this.wpQueryService.getPosts({
      postType: 'branches',
      args: {
        _fields: 'id,title,type',
        status: 'private',
        orderby: 'title',
        order: 'asc',
      },
    }).subscribe(
      resp => {

        console.log( 'Pipeline loadBranches resp' , resp );
        this.globalCommsService.updateGlobalData( 'branches' , resp['body'] );
      },
      error => {
        //console.log( error );
      }
    ));

  }

  setupTable() { }

  updateActions() {
    this.tabs = this.pipelineActionsService.addActionsToTabs( this.tabs );
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WpQueryService } from '../wp-query/wp-query.service';
import { WpServerService } from '../wp-server/wp-server.service';

import { WpQuery } from '../../classes/wp-query/wp-query';
import { WpObject } from '../../classes/wp-object/wp-object';

@Injectable({
  providedIn: 'root'
})

export class WpSaveService {

  constructor(
    private wpQueryService: WpQueryService,
    private wpServerService: WpServerService,
  ) { }

  saveObject( route: string , object: WpObject , sendArgs: string[] = [] , nameSpace?: string ): Observable<WpObject> {

    object = ( sendArgs.length === 0 ? object : this.parseObject( object , sendArgs ));

    if( typeof( object.id ) === 'undefined' || object.id === 0 ) {
      return this.createObject( route , object , nameSpace );
    }

    return this.updateObject( route , object , nameSpace );

  }

  updateObject( route: string , object: WpObject , nameSpace?: string ): Observable<WpObject> {

    let id = object.id;
    delete object.id;

    return this.wpServerService.updateObject( route , id , object , nameSpace );

  }

  createObject( route: string , object: WpObject , nameSpace?: string ): Observable<WpObject> {

    if( typeof( object.id ) !== 'undefined' ) {
      delete object.id;
    }

    return this.wpServerService.createObject( route , object , nameSpace );

  }

  deleteObject( query: WpQuery , nameSpace?: string ) {
    let url = this.wpQueryService.generateQueryUrl( query );
    return this.wpServerService.deleteObject( url , nameSpace );
  }

  parseObject( object: WpObject , sendArgs: string[] ): WpObject {

    let returnObject: WpObject = {}

    for( var key in object ) {

      if( sendArgs.indexOf( key ) === -1 ) {
        continue;
      }

      returnObject[ key ] = ( typeof( object[ key ] ) === 'object' ? JSON.stringify( object[ key ] ) : object[ key ] );

    }

    return returnObject;

  }

}

<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 pt-3 pb-3">

    <div class="row">
      <div class="col-xl-4 col-lg-3"></div>

      <div class="col-xl-4 col-lg-6">

        <!-- Nav Tabs -->
        <mdb-tabset #staticTabs [buttonClass]="'md-tabs indigo lighten-1 white-text text-center'" [contentClass]="'card'">

          <!--Panel 1-->
          <mdb-tab heading="Login" tabOrder="1">
            <div class="row">
              <div class="col-12">

                <!-- Form -->
                <form style="color: #757575;" (ngSubmit)="login( i )" #i="ngForm">

                  <!-- Email -->
                  <div class="md-form">
                    <input [(ngModel)]="email" name="email" type="email" id="email" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="email">E-mail *</label>
                  </div>

                  <!-- Password -->
                  <div class="md-form">
                    <input [(ngModel)]="password" name="password" type="password" id="password" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="password">Password *</label>
                  </div>

                  <div class="d-flex justify-content-around">

                    <div>
                      <mdb-checkbox>Remember me</mdb-checkbox>
                    </div>

                    <div>
                      <a [routerLink]="'/login/reset-password'">Forgot password?</a>
                    </div>

                  </div>

                  <!-- Sign in button -->
                  <button mdbBtn color="info" outline="true" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect type="submit" [disabled]="! i.valid || loading">
                    <span *ngIf="! loading">Sign in</span>
                    <span *ngIf="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </span>
                  </button>

                  <!-- Register -->
                  <p>Not a member?
                    <a [routerLink]="" role="button" (click)="changeActiveTab(2)">Register</a>
                  </p>

                </form>
                <!-- Form -->

                <!-- Material form login -->

              </div>
            </div>
          </mdb-tab>

          <!--Panel 2-->
          <mdb-tab heading="Register" tabOrder="2">
            <div class="row">
              <div class="col-12">

                <!-- Form -->
                <form class="text-center" style="color: #757575;" (ngSubmit)="register( f )" #f="ngForm">

                  <!-- First Name -->
                  <div class="md-form">
                    <input [(ngModel)]="first_name" name="first_name" type="text" id="first_name" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="first_name">First Name *</label>
                  </div>

                  <!-- Last Name -->
                  <div class="md-form">
                    <input [(ngModel)]="last_name" name="last_name" type="text" id="last_name" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="last_name">Last Name *</label>
                  </div>

                  <!-- Email -->
                  <div class="md-form">

                    <input [(ngModel)]="email" name="email" type="email" id="email" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="email">E-mail *</label>

                  </div>

                  <!-- Password -->
                  <div class="md-form">

                    <input [(ngModel)]="password" name="password" type="password" id="password" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="password">Password *</label>

                  </div>

                  <re-captcha [(ngModel)]="captcha" name="captcha" required (resolved)="resolved( $event )" [siteKey]="captchaKey"></re-captcha>
                  <hr />

                  <!-- Start Checkbox Fields -->
                  <div class="">
                    <label for="terms">Please check the box to confirm that you agreed to our <a href="">Terms & Conditions</a>.</label>
                    <mdb-checkbox [(ngModel)]="terms" name="terms" required mdbValidate>I agree *</mdb-checkbox>
                  </div>

                  <!-- Sign in button -->
                  <button mdbBtn color="info" outline="true" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect type="submit" [disabled]="! f.valid || loading">
                    <span *ngIf="! loading">Register</span>
                    <span *ngIf="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </span>
                  </button>

                </form>
                <!-- Form -->

              </div>
            </div>
          </mdb-tab>

        </mdb-tabset>

      </div>
      <div class="col-xl-4 col-lg-3"></div>
    </div>

  </div>
</div>

<app-footer></app-footer>

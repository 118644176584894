import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-lpa-form-data',
  templateUrl: './lpa-form-data.component.html',
  styleUrls: ['./lpa-form-data.component.scss']
})
export class LpaFormDataComponent implements OnInit {

  @Input() object: Object;

  constructor(
    public modalRef: MDBModalRef,
  ) { }

  ngOnInit(): void {
  }

  convertAnswer( string: string ): string {
    return string.replace( /-/g , ' ' );
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  currentUser: Object;

  constructor() { }

  ngOnInit() {

    this.currentUser = {
      name: 'Dominic Vermeulen-Smith',
      first_name: 'Dominic',
      last_name: 'Vermeulen-Smith',
      email: 'contact@bedevious.co.uk',
      password: '*********',
    }

  }

}

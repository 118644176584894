import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { GlobalCommsService } from '../../../../services/global-comms/global-comms.service';
import { ApiFieldsService } from '../../../../services/api-fields/api-fields.service';
import { ServicesService } from '../../../../services/services/services.service';

import { ModalComponent } from '../../../../components/modal/modal.component';

@Component({
  selector: 'app-simple-will',
  templateUrl: './simple-will.component.html',
  styleUrls: ['./simple-will.component.scss']
})
export class SimpleWillComponent implements OnInit {

  object: any;
  modalStatus: string;

  show;

  sections;

  constructor(
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private modalClose: GlobalCommsService,
    private apiFieldsService: ApiFieldsService,
    public servicesService: ServicesService,
  ) {

  }

  ngOnInit() {

    this.sections = this.getSections();
    this.show = this.object.class.show;

    this.modalClose.currentRef.subscribe(
      modalStatus => {
        if( modalStatus === 'close' ) {
          this.modalRef.hide();
          this.checkProgress();
        }
      }
    );

  }

  showModal( section ) {

    let config = {
      ignoreBackdropClick: true,
      data: {
        heading: section.title,
        component: section.component,
        key: section.key,
        object: this.object,
        extraData: {
          heading: section.title,
          section: section,
        }
      }
    }

    if( typeof( section.listComponent ) !== 'undefined' ) {
      config.data['listComponent'] = section.listComponent;
    }

    this.modalRef = this.modalService.show( ModalComponent , config );

    this.modalClose.updateGlobalRef( 'open' );

  }

  getSections() {
    return this.object.class.sections;
  }

  checkProgress() {
    this.object.class.checkProgress();
  }

}

import { Injectable } from '@angular/core';
import { empty } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { ServerService } from '../../server.service';
import { GlobalCommsService } from '../global-comms/global-comms.service'

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  private currentServices: Object[];

  constructor(
    public serverService: ServerService,
    public globalCommsService: GlobalCommsService,
  ) {
    this.globalCommsService.currentServices.subscribe(
      resp => {

        if( typeof( resp[0] ) !== 'undefined' && typeof( resp[0]['action'] ) !== 'undefined' && resp[0]['action'] === 'wait' ) {
          return;
        }

        this.currentServices = resp;

      }
    );
  }

  //prepare sendData
  prepareData( type , userData , sendData = [] ) {

    let defaults = this.getDefaults( type );
    sendData = sendData.concat( this.getSendDataRequirements( type ) );

    let data = {}

    for( var i = 0; i < sendData.length; i++ ) {
      let currentData = sendData[ i ];

      if( typeof( userData[ currentData ] ) === 'undefined' ) {

        if( typeof( defaults[ currentData ] ) === 'undefined' ) {

          //@todo handle missing required data
          alert( 'Error, please provide a ' + currentData );
          return;

        } else {
          data[ currentData ] = defaults[ currentData ];
        }

      } else {


        //this assigns standard field values
        if( typeof( userData[ currentData ] ) !== 'object' ) {
          data[ currentData ] = userData[ currentData ];
        }

        //take rendered content objects into account
        if( typeof( userData[ currentData ] ) === 'object' && typeof( userData[ currentData ]['rendered'] ) !== 'undefined' ) {
          data[ currentData ] = userData[ currentData ]['rendered'];
        }

        //this replaces a field value with a held value, the purpose being, for example, that if there is a select field
        //that has an "other" option, it will be replaced by it's free text counterpart
        if( typeof( userData['heldValues'] ) !== 'undefined' && typeof( userData['heldValues'][ currentData ] ) !== 'undefined' && userData['heldValues'][ currentData ] !== false ) {
          data[ currentData ] = userData['heldValues'][ currentData ];
        }

      }

    }

    return data;

  }

  //get services
  getServices( args: Object = { context: 'embed' } ) {
    return this.globalCommsService.currentUserData.pipe(
      mergeMap( resp => {

        if( typeof( resp['id'] ) === 'undefined' ) {
          return empty();
        }

        let requestArgs = ( typeof( args ) === 'undefined' ? {} : args );
        let requestString = '';

        if( typeof( requestArgs['author'] ) === 'undefined' || requestArgs['author'] ) {
          requestString += '&author=' + resp['id'];
        } else {
          delete requestArgs['author'];
        }

        for( var key in requestArgs ) {
          requestString += '&' + key + '=' + requestArgs[ key ];
        }

        return this.serverService.getServices( requestString ).pipe(
          tap( resp => {
            this.globalCommsService.updateCurrentServices( resp );
          })
        );

      })
    )
  }


  patchService( object: Object ): void {

    let updated = false;

    for( let key in this.currentServices ) {

      let service = this.currentServices[ key ];

      if( service['service_type'] !== object['service_type'] ) {
        continue;
      }

      this.currentServices[ key ] = object;
      updated = true;

    }

    if( ! updated ) {
      this.currentServices.push( object );
    }

    this.globalCommsService.updateCurrentServices( this.currentServices );

  }


  patchServicePart( object: Object ): void {

    for( let key in this.currentServices ) {
      let service = this.currentServices[ key ];

      if( typeof( service['service_part_children'] ) === 'undefined' || service['service_part_children'].length === 0 ) {
        continue;
      }

      for( let spKey in service['service_part_children'] ) {
        let servicePart = service['service_part_children'][ spKey ];

        if( servicePart['id'] !== object['id'] ) {
          continue;
        }

        this.currentServices[ key ]['service_part_children'][ spKey ] = object;

      }

    }

    this.globalCommsService.updateCurrentServices( this.currentServices );

  }

  insertServicePart( object: Object ) {

    for( let key in this.currentServices ) {
      let service = this.currentServices[ key ];

      if( service['id'] !== object['service_parent'] ) {
        continue;
      }

      if( typeof( this.currentServices[ key ]['service_part_children'] )) {
        this.currentServices[ key ]['service_part_children'] = [];
      }

      this.currentServices[ key ]['service_part_children'].push( object );

    }

    return this.globalCommsService.updateCurrentServices( this.currentServices );

  }


  removeService( object: Object ): void {

    for( let key in this.currentServices ) {

      let service = this.currentServices[ key ];

      if( service['service_type'] !== object['service_type'] ) {
        continue;
      }

      this.currentServices.splice( parseInt( key ) , 1 );

    }

    //console.log( this.currentServices );

    this.globalCommsService.updateCurrentServices( this.currentServices );

  }


  //cretae a new services
  createService( type , userData , sendData = [] ) {
    let data = this.prepareData( type , userData , sendData );
    return this.serverService.createService( data );
  }

  deleteService( id ) {
    return this.serverService.deleteService( id );
  }

  updateService( type , id , userData , sendData = [] ) {
    let data = this.prepareData( type , userData , sendData );
    return this.serverService.updateService( id , data );
  }

  getService( id ) {
    return this.serverService.getItem( 'services/' + id );
  }

  //get services
  getServiceParts( parent , type ) {
    return this.globalCommsService.currentUserData.pipe(
      mergeMap( resp => {

        if( typeof( resp['id'] ) === 'undefined' ) {
          return empty();
        }

        return this.serverService.getServiceParts( parent , type , resp['id'] );

      })
    )
  }

  //cretae a new services
  createServicePart( type , userData , sendData = [] ) {
    let data = this.prepareData( type , userData , sendData );
    return this.serverService.createServicePart( data ).pipe(
      map( resp => {
        this.insertServicePart( resp );
        this.getServices();
        return resp;
      })
    );
  }

  deleteServicePart( id ) {
    return this.serverService.deleteServicePart( id );
  }

  updateServicePart( type , id , userData , sendData = [] ) {
    let data = this.prepareData( type , userData , sendData );
    return this.serverService.updateServicePart( id , data );
  }

  getServicePart( id ) {
    return this.serverService.getItem( 'service-parts/' + id );
  }

  generateWill( id ) {
    return this.serverService.generateWill( id );
  }

  acceptWillTerms( id ) {
    return this.serverService.acceptWillTerms( id , {
      terms_accepted: true,
    });
  }

  sendNotificationEmail( object ) {
    return this.serverService.sendNotificationEmail( object.id , object['note'] );
  }

  submitLpa( id ) {
    return this.serverService.submitLpa( id , {
      terms_accepted: true,
    });
  }

  submitWill( id ) {
    return this.serverService.submitWill( id , {
      terms_accepted: true,
    });
  }

  cancelAService( id ) {
    return this.serverService.cancelAService( id );
  }

  requestDownloadLink( id , mediaId ) {

    let data = {
      file_requested: mediaId,
    }

    return this.serverService.requestDownloadLink( id , data );

  }

  willPayment( userData ) {
    return this.serverService.willPayment( userData );
  }

  getSendDataRequirements( type ) {

    let sendData = {}

    if( typeof( sendData[ type ] ) === 'undefined' ) {
      return [
        'title',
        'status',
        'service_type',
        'service_part_type',
      ]
    }

    return sendData[ type ];

  }

  getDefaults( type ) {

    let defaults = {}

    if( typeof( defaults[ type ] ) === 'undefined' ) {
      return {
        title: 'My ' + type,
        status: 'private',
        service_type: type,
        service_part_type: type,
      }
    }

    return defaults[ type ];

  }

}

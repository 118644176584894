<div class="wig-form-container">

  <p class="alert indigo lighten-1 white-text mb-4" *ngIf="questions[ currentQuestionPage ]['description']">
    <strong>{{ questions[ currentQuestionPage ].description }}</strong><br />
    <a *ngIf="questions[ currentQuestionPage ].detailedDescription" class="btn white-text btn-default" (click)="basicModal.toggle()">Read More >></a>
  </p>

  <p *ngIf="questions[ currentQuestionPage ]['label']">{{ questions[ currentQuestionPage ]['label'] }}</p>

  <form [formGroup]="questions[ currentQuestionPage ]['formGroup']">

    <div *ngFor="let question of questions[ currentQuestionPage ]['questions']">

      <!-- Start Text, Email, Password Fields -->
      <div *ngIf="['text','email','password','number','date'].indexOf( question.type ) !== -1">
        <div class="md-form">
          <input class="form-control" [type]="question.type" [name]="question.name" [id]="question.name" [formControlName]="question.name" [required]="question.required" mdbInput mdbValidate />
          <label [for]="question.name">{{ question.text }} <span *ngIf="question.required && f[ question.name ].value">*</span><span *ngIf="question.required && ! f[ question.name ].value">(required)</span><span *ngIf="! question.required && ! f[ question.name ].touched">(optional)</span></label>
          <div><small>{{ question.description }}</small></div>
        </div>
      </div>
      <!-- End Text, Email, Password Fields -->

      <!-- Start Radio Fields -->
      <div *ngIf="question.type === 'radio'" style="position: relative;">

        <p>{{ question.text }} <span *ngIf="question.required">*</span><span *ngIf="! question.required && ! f[ question.name ].touched">(optional)</span></p>

        <div *ngFor="let option of question.options" [class]="formInline( question )" class="form-check">
          <input type="radio" class="form-check-input" [name]="question.name" [id]="option.id" [formControlName]="question.name" [value]="option.value" [required]="question.required" mdbInput mdbValidate />
          <label class="custom-control-label form-check-label" [for]="option.id">{{ option['label'] }}</label>
        </div>

        <mdb-error *ngIf="f[ question.name ].invalid && (f[ question.name ].dirty || f[ question.name ].touched)" style="top: initial; bottom: 0;">This field is required.</mdb-error>

        <div><small>{{ question.description }}</small></div>

        <hr />

      </div>
      <!-- End Radio Fields -->

      <!-- Start Checkbox Fields -->
      <div *ngIf="question.type === 'checkbox'" style="position: relative;">

        <label [for]="question.name" [innerHTML]="question['label']"></label>
        <mdb-checkbox [id]="question.name" [value]="question.value" [name]="question.name" [formControlName]="question.name">{{ question.text }} <span *ngIf="question.required">*</span><span *ngIf="! question.required && ! f[ question.name ].touched">(optional)</span></mdb-checkbox>

        <mdb-error *ngIf="f[ question.name ].invalid && (f[ question.name ].dirty || f[ question.name ].touched)" style="top: initial;">This field is required.</mdb-error>

        <div><small>{{ question.description }}</small></div>

      </div>
      <!-- End Checkbox Fields -->

      <!-- Start Select Fields -->
      <div *ngIf="question.type === 'select'" class="row mb-4">
        <div class="col-md-12">
          <label [for]="question.name" [innerHTML]="question['text']"></label>
          <mdb-select placeholder="Choose option..." (ngModelChange)="selectValueChange( $event , question.name )" [options]="question.options" [id]="question.name" [formControlName]="question.name" [required]="question.required" mdbInput mdbValidate></mdb-select>
          <div><small>{{ question.description }}</small></div>
        </div>
      </div>
      <!-- End Select Fields -->

      <!-- Start Select Free Text Fields -->
      <div *ngIf="question.type === 'select' && showFreeText( question.name )">
        <div class="md-form">

          <input class="form-control" type="text" [name]="'held-' + question.name" [id]="'held-' + question.name" [(ngModel)]="formValues['heldValues'][question.name]" [ngModelOptions]="{standalone: true}" [required]="question.required" mdbInput mdbValidate />
          <label [for]="'held-' + question.name">Enter your text here <span *ngIf="question.required && formValues['heldValues'][question.name]">*</span><span *ngIf="question.required && ! formValues['heldValues'][question.name]">(required)</span><span *ngIf="! question.required && ! f[ question.name ].touched">(optional)</span></label>

        </div>
      </div>
      <!-- End Select Free Text Fields -->

      <!-- Start Hidden Fields -->
      <div *ngIf="question.type === 'hidden'">
        <input type="hidden" [name]="question.name" [formControlName]="question.name" [value]="question.value" />
      </div>
      <!-- End Hidden Fields -->

      <!-- Start recaptcha -->
      <!-- NB recaptcha is always required, but still must be specified as required in order for the form validation to consider it -->
      <div *ngIf="question.type === 'recaptcha'">
        <re-captcha [formControlName]="question.name" name="question.name" required siteKey="6LdhxqEUAAAAALDjZT4ahnsFIDyaEii7TrL_3HFi"></re-captcha>
        <mdb-error *ngIf="f[ question.name ].invalid && (f[ question.name ].dirty || f[ question.name ].touched)" style="top: initial; left: 20px;">You must complete the recaptcha.</mdb-error>
        <hr />
      </div>
      <!-- End recaptcha -->

      <!-- Start textarea field -->
      <div class="md-form" *ngIf="question.type === 'textarea' && question.name.indexOf( '_canvas' ) === -1">
        <textarea type="text" class="md-textarea md-textarea-auto form-control" [name]="question.name" [id]="question.name" [formControlName]="question.name" [required]="question.required" mdbInput mdbValidate></textarea>
        <label [for]="question.name" [innerHTML]="question['text']"></label>
        <div><small>{{ question.description }}</small></div>
      </div>
      <!-- End textarea field -->

      <!-- Start WYSIWYG field -->
      <div class="md-form" *ngIf="question.type === 'wysiwyg'">
        <label [for]="question.name" [innerHTML]="question['text']"></label>
        <br />
        <mdb-wysiwyg #wysiwyg [id]="question.name" [formControlName]="question.name" [required]="question.required"></mdb-wysiwyg>
        <div><small>{{ question.description }}</small></div>
      </div>
      <!-- End WYSIWYG field -->

      <!--  Start file upload field -->
      <div class="md-form" *ngIf="question.type === 'image'">

        <label [for]="question.name" [innerHTML]="question['text']"></label>
        <br />

        <div *ngIf="! formValues[ question.name ] && ! formValues['heldValues'][ question.name ]">
          <form (ngSubmit)="startUpload( question.name )">
            <div class="file-field md-form">
              <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>
                <span>Choose file</span>
                <input type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [options]="{ concurrency: 1 , maxUploads: 1 }" readonly>
              </div>
              <div class="file-path-wrapper">
                <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
              </div>
            </div>
            <button type="submit" mdbBtn color="primary" [disabled]="files.length === 0 || loading">Start uploading</button>
          </form>
        </div>

        <div class="file-field md-form" *ngIf="formValues['heldValues'][ question.name ]">
          <img [src]="formValues['heldValues'][ question.name ]" class="img-fluid d-block mx-auto">
        </div>

        <div class="file-field md-form" *ngIf="formValues[ question.name ]">
          <img [src]="formValues[ question.name ]" class="img-fluid d-block mx-auto">
        </div>

        <div><small>{{ question.description }}</small></div>

      </div>
      <!-- End file upload field -->

      <!-- Start textarea canvas field -->
      <div class="md-form" *ngIf="question.type === 'textarea' && question.name.indexOf( '_canvas' ) !== -1">
        <input class="form-control" type="text" [name]="question.name" [id]="question.name" [formControlName]="question.name" [required]="question.required" mdbInput mdbValidate />
        <canvas width="500" height="100" #canvas class="form-control" type="text" [id]="question.name.replace( '_canvas' , '_image_url' )"></canvas>
        <button class="white-text" (click)="setCanvas( question.name )" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>Create / Update Signature</button>
        <div><small>{{ question.description }}</small></div>
      </div>
      <!-- End textarea canvas field -->


    </div>

  </form>

  <div class="text-center mt-3">

    <button class="white-text" *ngIf="canGoBack() && ! hasPrevAction()" (click)="previousPage()" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>
      << Previous
    </button>

    <button class="white-text" *ngIf="canGoForward() && ! hasNextAction()" (click)="nextPage()" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>
      Next >>
    </button>

    <button class="white-text" *ngIf="hasPrevAction() && ! loading" (click)="runPrevAction()" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>
      << {{ questions[ currentQuestionPage ].prevAction['label'] }}
    </button>

    <button class="white-text" *ngIf="hasNextAction() && ! loading" (click)="runNextAction()" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>
      {{ questions[ currentQuestionPage ].nextAction['label'] }} >>
    </button>

    <button class="white-text" *ngIf="!canGoForward() && formValues['service_type'] === 'custom-will'" routerLink="/quotes" mdbBtn class="indigo lighten-1 white-text" mdbWavesEffect>Show Me Quotes!</button>

    <button *ngIf="loading" mdbBtn color="primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Loading...
    </button>

    <hr>

  </div>

</div>

<!-- <div class="progress">
  <mdb-progress [value]="progress" min="0" max="100" type="info" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
</div> -->

<div mdbModal #basicModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="overflow-y: scroll;" [config]="helpModalOptions">
  <div class="modal-dialog modal-full-height modal-right" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 *ngIf="helpHeading" class="modal-title w-100" id="myModalLabel">{{ helpHeading }}</h4>
      </div>
      <div class="modal-body" [innerHTML]="questions[ currentQuestionPage ]['detailedDescription']"></div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="default" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>

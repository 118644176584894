<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">New Will Form Answers</h4>
  </div>
  <div class="modal-body">

    <div class="p-5">

      <div class="row">
        <div class="col-md-12">

          <p>Below is the customer information that was submitted with this request</p>

          <ul>
            <li><strong>Name:</strong> {{ object['referral_full_name'] }}</li>
            <li><strong>Email Address:</strong> {{ object['referral_email_address'] }}</li>
            <li><strong>Phone Number:</strong> {{ object['referral_contact_telephone_number'] }}</li>
            <li><strong>Comments:</strong> {{ object['refferral_any_other_info'] }}</li>
          </ul>

          <p>Initial Will Form Answers</p>

          <ul>
            <li><strong>Who is this will for?:</strong> {{ convertAnswer( object['who_for'] ) }}</li>
            <li><strong>Do you have children?:</strong> {{ convertAnswer( object['has_children'] ) }}</li>
            <li><strong>Are you over the age of 18?:</strong> {{ convertAnswer( object['are_you_over_the_age_of_18'] ) }}</li>
            <li><strong>Are you are divorced or separated?:</strong> {{ convertAnswer( object['are_you_are_divorced_or_separated'] ) }}</li>
            <li><strong>Are you engaged or considering marriage?:</strong> {{ convertAnswer( object['are_you_engaged_or_considering_marriage'] ) }}</li>
            <li><strong>Do you have children from a previous relationship?:</strong> {{ convertAnswer( object['do_you_have_children_from_a_previous_relationship'] ) }}</li>
            <li><strong>Do you have disabled or vulnerable children or beneficiaries?:</strong> {{ convertAnswer( object['do_you_have_disabled_or_vulnerable_children_or_beneficiaries'] ) }}</li>
            <li><strong>Do you have agricultural or business assets?:</strong> {{ convertAnswer( object['business_assets'] ) }}</li>
            <li><strong>Do the value of your assets exceed £325,000?:</strong> {{ convertAnswer( object['assets_exceed'] ) }}</li>
            <li><strong>Do you have any foreign assets?:</strong> {{ convertAnswer( object['foreign_assets'] ) }}</li>
            <li><strong>Are you excluding a spouse or dependent relative from your will?:</strong> {{ convertAnswer( object['excluding_spouse_dependent'] ) }}</li>
            <li><strong>Are you are blind or partially sighted or cannot read?:</strong> {{ convertAnswer( object['blind_partially_sighted'] ) }}</li>
            <li><strong>Is there any reason you cannot sign or can only mark your will?:</strong> {{ convertAnswer( object['reason_you_cannot_sign'] ) }}</li>
          </ul>

          <p>Will Form Outcome:</p>
          <ul>
            <li><strong>Outcome:</strong> {{ object['will_option'] }}</li>
          </ul>

        </div>
      </div>

    </div>
  </div>

</div>

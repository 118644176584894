import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer} from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ServicesService } from '../../../../services/services/services.service';
import { GlobalCommsService } from '../../../../services/global-comms/global-comms.service';

@Component({
  selector: 'app-lw-will-writer',
  templateUrl: './lw-will-writer.component.html',
  styleUrls: ['./lw-will-writer.component.scss'],
})
export class LwWillWriterComponent implements OnInit, OnDestroy {

  @ViewChildren( 'iframe' ) public iframes: QueryList<ElementRef>;

  public loading: boolean;
  public case: Object;
  public iFrame: string;

  private subscriptions: Subscription[];

  constructor(
    public activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private domSanitizer: DomSanitizer,
    private globalCommsService: GlobalCommsService,
  ) {
    this.loading = true;
    this.subscriptions = [];
  }

  ngOnInit() {

    this.subscriptions.push( this.activatedRoute.paramMap.subscribe(
      resp => {
        this.loadWillCase( resp['params']['id'] );
      }
    ));


  }

  ngAfterViewInit() {
    this.iframes.changes.subscribe(( element: QueryList<ElementRef> ) => {

      let items = element.toArray();
      let firstItem = items[0];
      let elementRef = firstItem['elementRef'];
      let iframe = elementRef['nativeElement'];

      iframe.scrolling = 'yes';

    });
  }

  ngOnDestroy() {
    for( let subscription of this.subscriptions ) {
      subscription.unsubscribe();
    }
  }

  loadWillCase( id: number ): void {

    this.subscriptions.push( this.servicesService.getService( id ).subscribe(

      resp => {
        this.case = resp;
        this.case['lw_ww_url'] = this.case['lw_ww_url'].replace( '&amp;' , '&' );
        this.loading = false;
        this.globalCommsService.updateGlobalRef( 'stop-global-loading' );
      },

      error => {
        this.loading = false;
      }

    ));

  }

  getIframe( url: string ) {
    return this.domSanitizer.bypassSecurityTrustHtml( '<iframe id="wig-will-writer-iframe" src="' + url + '" scrolling="no"></iframe>' );
  }

  iFrameIsLoaded() {
    alert( 'iFrame is loaded' );
  }

}

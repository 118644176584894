<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <h1 class="mb-4">Referral Pipeline</h1>

    <div class="row">
      <div class="col-md-12">
        <!--Card-->
        <div class="card card-cascade narrower">
          <!--Card content-->
          <div class="card-body">

            <!-- Nav Tabs -->
            <mdb-tabset *ngIf="showTabs" #staticTabs [buttonClass]="'md-tabs tabs-primary'" [contentClass]="'card'">
              <mdb-tab *ngFor="let tab of tabs | objectArrayFilter:{ key: 'allowedRoles' , values: currentUserRoles }" heading="{{ tab.title }}">

                <div class="row">
                  <div class="col-12">

                    <app-referral-table [tab]="tab"></app-referral-table>

                  </div>
                </div>

              </mdb-tab>
            </mdb-tabset>

          </div>

        </div>

      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>

<div class="row">
  <div class="col-md-12">

    <form (ngSubmit)="updateProfile()" #i="ngForm">

        <div class="row">
          <div class="col-sm-6">

            <div *ngFor="let question of profileEditQuestions">
              <div *ngIf="['text','email','password'].indexOf( question.type ) !== -1">
                <p *ngIf="question.label">{{ question.label }}</p>
                <div class="md-form mb-0">
                  <input [readonly]="! passwordIsActive" (focus)="onFocus()" [type]="question.type" [name]="question.name" [id]="question.name" [(ngModel)]="currentUser[question.name]" #input="ngModel" [required]="question.required" class="form-control" mdbInput mdbValidate />
                  <label [for]="question.name">{{ question.text }}</label>
                </div>
              </div>
            </div>

          </div>
          <div class="col-sm-6">

            <div *ngFor="let activationVar of getActivationVars()">

              <div *ngIf="! activationVar.value" class="alert alert-danger d-flex justify-content-between align-items-center py-0" role="alert">
                <small [innerHTML]="activationVar.descriptions.negative"></small>
                <button (click)="runActivationTrigger( activationVar )" type="button" [disabled]="loading" mdbBtn class="btn btn-danger btn-sm">Let's go!</button>
              </div>

              <div *ngIf="activationVar.value" class="alert alert-success d-flex justify-content-between align-items-center" role="alert">
                <small [innerHTML]="activationVar.descriptions.positive"></small>
                <mdb-icon class="green-text" fas icon="check"></mdb-icon>
              </div>

              <div *ngIf="showCodeVerification( activationVar )">
                <form (ngSubmit)="validateCode( activationVar )" #codeForm="ngForm" class="d-flex justify-content-between">

                  <div class="md-form flex-grow-1 my-0">
                    <input #input="ngModel" [(ngModel)]="activationVar.activationCode" type="text" name="verification-code" id="verification-code" required="true" class="form-control" placeholder="Enter your code here" mdbInput mdbValidate />
                    <label for="verification-code">Verification Code</label>
                  </div>

                  <div class="">
                    <button class="btn btn-default btn-sm" mdbBtn [disabled]="! codeForm.valid || loading">Submit Code</button>
                  </div>

                </form>
              </div>

            </div>

          </div>
        </div>

        <div *ngIf="currentUser['address']">
          <div class="row">
            <div class="col-sm-4">
              <div class="md-form">
                <input #addressFormFocusField type="text" name="house-name" id="house-name" [(ngModel)]="currentUser['address']['house-name']" #input="ngModel" class="form-control" mdbInput />
                <label for="house-name">House Name</label>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="md-form">
                <input type="text" name="house-number" id="house-number" [(ngModel)]="currentUser['address']['house-number']" #input="ngModel" class="form-control" mdbInput />
                <label for="house-number">Number</label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="md-form">
                <input type="text" name="address-line-1" id="address-line-1" [(ngModel)]="currentUser['address']['address-line-1']" #input="ngModel" class="form-control" mdbInput />
                <label for="address-line-1">Address Line 1 *</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="md-form mb-0">
                <input type="text" name="address-line-2" id="address-line-2" [(ngModel)]="currentUser['address']['address-line-2']" #input="ngModel" class="form-control" mdbInput />
                <label for="address-line-2">Address Line 2</label>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="md-form mb-0">
                <input type="text" name="town-city" id="town-city" [(ngModel)]="currentUser['address']['town-city']" #input="ngModel" class="form-control" mdbInput />
                <label for="town-city">Town / City *</label>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="md-form mb-0">
                <input type="text" name="post-code" id="post-code" [(ngModel)]="currentUser['address']['post-code']" #input="ngModel" class="form-control" mdbInput />
                <label for="post-code">Post Code *</label>
              </div>
            </div>
          </div>
        </div>

        <button class="btn white-text btn-default my-3 ml-0" data-card="card-1" type="submit" [disabled]="! i.valid || loading" mdbWavesEffect>
          <span *ngIf="! loading">Save Changes</span>
          <span *ngIf="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </span>
        </button>

      </form>

  </div>
</div>

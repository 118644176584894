import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AccountManagementService } from '../../services/account-management/account-management.service';
import { GlobalCommsService } from '../../services/global-comms/global-comms.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {

  password: string;
  email: string;
  code: string;
  sent: boolean;
  sentMessage: string;

  loading: Boolean;

  constructor(
    public accountManagementService: AccountManagementService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private globalCommsService: GlobalCommsService,
  ) {
    this.sent = false;
  }

  ngOnInit() {

    this.globalCommsService.updateGlobalRef( 'stop-global-loading' );

    this.activatedRoute.queryParams.subscribe( params => {

      let code = params['code'];
      let email = params['email'];

      if( typeof( code ) === 'undefined' ) {
        this.router.navigate(['/login/reset-password']);
      }

      if( typeof( email ) === 'undefined' ) {
        this.router.navigate(['/login/reset-password']);
      }

      this.code = code;
      this.email = email;

    });

  }

  setNewPassword() {

    this.loading = true;

    this.accountManagementService.setNewPassword({
      password: this.password,
      email: this.email,
      code: this.code,
    }, [ 'password' , 'email' , 'code' ] ).subscribe(

      resp => {

        this.loading = false;

        if( resp['status'] !== 200 ) {
          alert( resp['message'] );
          return;
        }

        this.sent = true;
        this.sentMessage = resp['message'];

      },

      error => {
        this.loading = false;
      }

    );

  }

}

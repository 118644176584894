<div class="d-flex justify-content-between align-items-center mb-3">
  <h2></h2>
  <app-powered-by-lm></app-powered-by-lm>
</div>

<p *ngIf="object['service_type_status'] !== 'complete'">Obtain a <b>FREE personalised quote</b> from our trusted solicitors <b>Churchers</b>, who are regulated by the
  <a role="button" class="text-primary"
    mdbPopover="Being regulated by the SRA means that Churchers are bound by strict professional and ethical rules and that they carry professional indemnity insurance."
    placement="right"
    mdbPopoverHeader="Solicitors Regulation Authority"
    triggers="focus">Solicitors Regulation Authority</a>.</p>

<div *ngIf="! loading && ! hasStarted()">
  <p>To get started just answer a few questions to see if you're likely to qualify for a basic will or whether you need to get further advice based on your personal circumstances.</p>
  <app-initial-will-form [object]="object"></app-initial-will-form>
</div>

<div *ngIf="! loading && canEditForm()">

  <p>In order to get a quote for a New Will from <b>Churchers</b> we need send them some of your details, which we need your consent to do.</p>
  <p>The information that will be passed across will be:</p>

  <ul>
    <li>Your full name</li>
    <li>Your email address</li>
    <li>Your phone number</li>
  </ul>

  <p *ngIf="canSubmitForm()" class="alert alert-warning
  ">Please press the "Submit Quote Request" button below, by doing so you are indicating that you consent to us sharing your data with Churchers.
    <b>Only you and your solicitor will have access to your data</b> and will be in touch with your soon after.</p>

</div>

<div *ngIf="object['service_type_status'] === 'submitted'">
  <p>You have successfully submitted your New Will Request and we have passed your details to <b>Churchers</b>. A solicitor will be in touch soon to discuss the next steps with you.</p>
</div>

<div *ngIf="object['service_type_status'] === 'complete'">
  <p>Congratulations! Your Will with Churchers has been completed!</p>
</div>

<div *ngIf="! loading && hasStarted()" class="list-group list-group-flush">
  <hr />

  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">

      <div class="row">
        <div class="col-sm-12">
            Your New Will Request with Churchers
        </div>
      </div>

    </div>

    <div style="min-width: 188px;">

      <button *ngIf="canEditForm()" (click)="openReferralForm()" class="indigo lighten-1 white-text" mdbBtn type="button" size="sm" mdbWavesEffect>
        <span *ngIf="canStartForm()">Confirm Referral details</span>
        <span *ngIf="object['service_type_status'] === 'ready'">Edit Referral Details</span>
      </button>

      <button *ngIf="canSubmitForm()" (click)="submitWill()" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Submit Quote Request</button>

      <span *ngIf="object['service_type_status'] === 'submitted'">Submitted</span>
      <span *ngIf="object['service_type_status'] === 'complete'">Complete</span>

      <span *ngIf="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </span>

    </div>

  </div>

</div>

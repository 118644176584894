<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-md-6">
        <h1 class="mb-4">Your Will Quotes</h1>
      </div>
      <div class="col-md-6">
        <div class="float-right">
          <button type="button" class="btn btn-default">Print Quotes</button>
          <button type="button" class="btn btn-default">Email Quotes</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-xl-3">

        <div class="row">
          <div class="col-md-12">
            <!--Card-->
            <div class="card card-cascade narrower">
              <!--Card content-->
              <div class="card-body">
                <h3 class="text-left"><strong>Your Details</strong></h3>

                <div class="list-group">

                  <div *ngFor="let item of summary" class="list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">{{ item.title }}</h5>
                    </div>
                    <p class="mb-1">{{ item.content }}</p>
                  </div>

                </div>

                <button type="button" class="btn btn-default mt-4">Edit Quote</button>

              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="col-lg-8 col-xl-9">

        <div class="row">
          <div class="col-md-12">
            <!--Card-->
            <div class="card card-cascade narrower">
              <!--Card content-->
              <div class="card-body">

                <h3 class="text-left"><strong>Your Quotes</strong></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mattis neque eget convallis volutpat.
                  Curabitur suscipit metus at augue imperdiet, non blandit metus faucibus.</p>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Supplier</th>
                      <th>Location</th>
                      <th>Legal Fee</th>
                      <th>Disbursements</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let quote of quotes">
                      <td><img class="quote-logo" [src]="quote.logo" /></td>
                      <td>{{ quote.location }}</td>
                      <td>{{ quote.fee }}</td>
                      <td>{{ quote.disbursements }}</td>
                      <td><span class="adjust-10">{{ quote.total }}<br /><small>Includes {{ quote.referral }} Referral Fee</small></span></td>
                      <td>
                        <button type="button" class="btn btn-default table-action">Proceed</button>
                        <button type="button" class="btn btn-default table-action">More Info</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <!--/.Card content-->
            </div>
            <!--/.Card-->
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { WpMessagesService } from '../../angular-wordpress-rest-api/services/wp-messages/wp-messages.service';

import { LoginService } from '../../services/login/login.service';
import { GlobalCommsService } from '../../services/global-comms/global-comms.service';
import { AccountManagementService } from '../../services/account-management/account-management.service';

import { User } from '../../classes/user';

export class NavItem {
  target: string;
  type: string;
  text: string;
  requiredLoginStatus: string|boolean;
  requiredRoles: string[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public loading: boolean;

  public navItems: NavItem[];

  public loggedInStatus: string;
  private userData: Object;

  constructor(
    public router: Router,
    private loginService: LoginService,
    private globalCommsService: GlobalCommsService,
    private accountManagementService: AccountManagementService,
    private wpMessagesService: WpMessagesService,
  ) {

    this.loading = true;
    this.loggedInStatus = 'not-logged-in';

    this.navItems = [
      /*{
        target: 'https://wig-web-dev.bedevious.co.uk/help-support',
        type: 'url',
        text: 'Help & Support',
        requiredLoginStatus: 'logged-in',
        requiredRoles: [],
      },*/
      {
        target: 'https://wig-web-dev.bedevious.co.uk/how-it-works',
        type: 'url',
        text: 'How It Works',
        requiredLoginStatus: 'logged-in',
        requiredRoles: [],
      },
      {
        target: 'https://wig-web-dev.bedevious.co.uk/help-support/contact-us',
        type: 'url',
        text: 'Contact Us',
        requiredLoginStatus: 'logged-in',
        requiredRoles: [],
      },
      {
        target: 'https://wig-web-dev.bedevious.co.uk/why-use-us',
        type: 'url',
        text: 'Why Use Us',
        requiredLoginStatus: 'not-logged-in',
        requiredRoles: [],
      },
      {
        target: '#',
        type: 'url',
        text: 'Free Will Quote',
        requiredLoginStatus: 'not-logged-in',
        requiredRoles: [],
      },
      {
        target: 'https://wig-web-dev.bedevious.co.uk/pricing',
        type: 'url',
        text: 'Pricing',
        requiredLoginStatus: 'not-logged-in',
        requiredRoles: [],
      },
      {
        target: 'https://wig-web-dev.bedevious.co.uk/faqs',
        type: 'url',
        text: 'FAQs',
        requiredLoginStatus: 'not-logged-in',
        requiredRoles: [],
      },
      {
        target: '/referral-pipeline',
        type: 'route',
        text: 'Referral Pipeline',
        requiredLoginStatus: 'logged-in',
        requiredRoles: [
          'supplier',
          'editor',
          'administrator',
        ],
      },
    ]

  }

  ngOnInit() {

    this.globalCommsService.currentLoginStatus.subscribe(
      resp => {
        this.loggedInStatus = resp['status'];
      }
    );

    this.globalCommsService.currentUserData.subscribe(
      resp => {
        this.userData = resp;
        this.loading = false;
      }
    );

  }

  logout() {
    this.accountManagementService.logout();
    this.router.navigate(['/login']);
    this.globalCommsService.updateLoginStatus({
      status: 'not-logged-in',
    });
  }

  openAccountSettingsModal(): void {
    this.wpMessagesService.broadcastMessage({
      type: 'open-account-settings',
      message: 'none'
    });
  }

  showNavItem( navItem: NavItem ): boolean {

    if( ! navItem.requiredLoginStatus && navItem.requiredRoles.length === 0 ) {
      return true;
    }

    if( navItem.requiredLoginStatus && navItem.requiredLoginStatus !== this.loggedInStatus ) {
      return false;
    }

    if( navItem.requiredLoginStatus && navItem.requiredRoles.length === 0 && navItem.requiredLoginStatus === this.loggedInStatus ) {
      return true;
    }

    if( navItem.requiredRoles.length !== 0 && typeof( this.userData['id'] ) === 'undefined' ) {
      return false;
    }

    if( navItem.requiredRoles.length !== 0 && typeof( this.userData['id'] ) !== 'undefined' ) {

      for( var i = 0; i < this.userData['roles'].length; i++ ) {

        let thisRole = this.userData['roles'][ i ];

        if( navItem.requiredRoles.indexOf( thisRole ) !== -1 ) {
          return true;
        }

      }

    }

    return false;

  }

}

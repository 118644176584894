import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { ModalComponent } from '../components/modal/modal.component';

import { ServicesService } from '../services/services/services.service';
import { ApiFieldsService } from '../services/api-fields/api-fields.service';
import { GlobalCommsService } from '../services/global-comms/global-comms.service';

import { SimpleWillComponent } from '../parts/vault/wills/simple-will/simple-will.component';
import { LmWillComponent } from '../parts/vault/lw-will/lw-will.component';
import { DonorInformationComponent } from '../parts/vault/donor-information/donor-information.component';
import { RantComponent } from '../parts/vault/rant/rant.component';
import { BucketComponent } from '../parts/vault/bucket/bucket.component';
import { MyLifeComponent } from '../parts/vault/my-life/my-life.component';
import { LoyaltyCardsComponent } from '../parts/vault/loyalty-cards/loyalty-cards.component';
import { PhotoAlbumComponent } from '../parts/vault/photo-album/photo-album.component';

import { SimpleWill } from  '../classes/simple-will';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

  loggedIn: any;

  services: Array<any>;
  serviceDefaults: Array<any>;
  continuedWillQuestions: Array<Object>;
  wait = false;

  constructor(
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    public servicesService: ServicesService,
    private apiFieldsService: ApiFieldsService,
    private globalCommsService: GlobalCommsService,
  ) {
    this.services = []
  }

  ngOnInit() {

    this.servicesService.getServices().subscribe(
      resp => {
        this.parseDefaults( resp );
      }
    );

    this.globalCommsService.currentRef.subscribe(
      resp => {
        if( resp === 'close-all-modals' ) {
          this.modalRef.hide();
        }
      }
    );

    this.serviceDefaults = this.getServiceDefaults();

  }

  parseDefaults( services ) {

    let defaults = this.serviceDefaults;

    //loop services we're passing
    for( var i = 0; i < services.length; i++ ) {

      var thisService = services[ i ];

      this.parseDefault( thisService );

    }

    //add non-existant parseDefaults
    for( var x = 0; x < defaults.length; x++ ) {

      var thisDefault = defaults[ x ];

      if( ! thisDefault['active'] ) {
        this.services.push( thisDefault );
      }

    }

  }

  parseDefault( thisService ) {

    let index = this.removeFromServices( thisService );

    let defaults = this.serviceDefaults;

    //loop the defaults to find the defaults
    for( var x = 0; x < defaults.length; x++ ) {

      var thisDefault = defaults[ x ];

      if( thisDefault.service_type !== thisService.service_type ) {
        continue;
      }

      //set the service default object active to true
      thisDefault['active'] = true;

      //attach the class for this service
      thisDefault['class'] = new SimpleWill( this.apiFieldsService );
      thisDefault['class'].setObject( thisService );

      //loop the default properties and see if they are set in the service
      //replace those that are not
      for( var key in thisDefault ) {

        if( typeof( thisService[ key ] ) !== 'undefined' ) {
          continue;
        }

        thisService[ key ] = thisDefault[ key ];

      }

      this.services.splice( x , 0 , thisService );

    }

    return;

  }

  removeFromServices( service ) {

    let services = this.services;

    for( var i = 0; i < services.length; i++ ) {

      var thisService = services[ i ];

      if( service.service_type !== thisService.service_type ) {
        continue;
      }

      this.services.splice( i , 1 );
      return i;

    }

    return 0;

  }

  addService( index ) {

    this.wait = true;

    let service = this.services[ index ];
    let serviceType = service.service_type;

    service.active = true;

    this.servicesService.createService( serviceType , service ).subscribe(

      resp => {
        this.parseDefault( resp );
        this.wait = false;
      },

      error => {
        this.wait = false;
      }

    );

  }

  removeService( index ) {

    this.wait = true;

    this.services[ index ]['active'] = false;
    this.parseDefault( this.services[ index ]['service_type'] );

    this.servicesService.deleteService( this.services[ index ]['id'] ).subscribe(

      resp => {
        this.wait = false;
      },

      error => {
        this.wait = false;
      }

    );

  }

  showModal( service ) {

    let components = {
      'simple-will': {
        component: SimpleWillComponent,
      },
      'lw-will': {
        component: LmWillComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'donor': {
        component: DonorInformationComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'rant': {
        component: RantComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'bucket-list': {
        component: BucketComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'your-life': {
        component: MyLifeComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'loyalty': {
        component: LoyaltyCardsComponent,
        options: {
          class: 'modal-lg'
        },
      },
      'photo-album': {
        component: PhotoAlbumComponent,
        options: {
          class: 'modal-lg'
        },
      },
    }

    if( typeof( components[ service.service_type ] ) === 'undefined' ) {
      return;
    }

    let modalOptions = Object.assign({
      data: {
        heading: service.title.rendered,
        component: components[ service.service_type ]['component'],
        object: service,
      }
    }, components[ service.service_type ]['options'] );

    this.modalRef = this.modalService.show( ModalComponent , modalOptions );

  }

  getServiceDefaults() {

    return [
      /*{
        title: {
          rendered: 'Your Will',
        },
        img: 'assets/service-images/alvaro-serrano-133360-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Will',
        service_type: 'simple-will',
        content: {
          rendered: 'Your will is the essential record of what you want to happen to your body and possessions after you die.',
        },
        active: false,
        actions: [
          {
            label: 'Continue',
            actionType: 'link',
            action: '#',
          },
        ],
      },*/
      {
        title: {
          rendered: 'Your Will',
        },
        img: 'assets/service-images/alvaro-serrano-133360-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Will',
        service_type: 'lw-will',
        content: {
          rendered: 'Your will is the essential record of what you want to happen to your body and possessions after you die.',
        },
        active: false,
        actions: [
          {
            label: 'Continue',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Funeral Choices',
        },
        img: 'assets/service-images/mayron-oliveira-1224441-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Funeral',
        service_type: 'funeral',
        content: {
          rendered: 'Update your funeral choices so the people you leave behind know what you want!',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Donor information',
        },
        img: 'assets/service-images/luann-hunt-1056437-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Medical',
        service_type: 'donor',
        content: {
          rendered: 'Make sure your family know what you want to happen to your organs when you are gone.',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Loyalty Cards and Points',
        },
        img: 'assets/service-images/rebecca-aldama-660180-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Financial',
        service_type: 'loyalty',
        content: {
          rendered: 'Keep a record of your loyalty cards and points so that someone can make use of them when you no longer can!',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Bucket List',
        },
        img: 'assets/service-images/glenn-carstens-peters-190592-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'bucket-list',
        content: {
          rendered: 'What is on your bucket list? Keep a track here!',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'THE RANT',
        },
        img: 'assets/service-images/gabriel-matula-300398-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'rant',
        content: {
          rendered: 'Go on, let it all out, what really grinds your gears?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Voice from the Grave',
        },
        img: 'assets/service-images/moira-dillon-567915-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'voice-grave',
        content: {
          rendered: 'What advice have your got for us still walking amongst the living?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Photo Album',
        },
        img: 'assets/service-images/laura-fuhrman-696993-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'photo-album',
        content: {
          rendered: 'Somewhere to keep those most precious memories.',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'This is Your Life!',
        },
        img: 'assets/service-images/hoang-minh-155716-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'your-life',
        content: {
          rendered: 'What is your story?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Secrets',
        },
        img: 'assets/service-images/kristina-flour-185592-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'secrets',
        content: {
          rendered: 'Somewhere to keep all those things that you don&apos;t want anyone to know. Yet...',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Love & Hate',
        },
        img: 'assets/service-images/t-chick-mcclure-609632-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'love-hate',
        content: {
          rendered: 'What do you love? What do you hate?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'Change?',
        },
        img: 'assets/service-images/ross-findon-303091-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'change',
        content: {
          rendered: 'You can change one thing about the world, what is it?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
      {
        title: {
          rendered: 'World Leader for a Day',
        },
        img: 'assets/service-images/markus-spiske-1142262-unsplash.1.jpg',
        icon: 'fa-book-dead',
        typeLabel: 'Life',
        service_type: 'leader',
        content: {
          rendered: 'One day. You are in charge. What do you do?',
        },
        active: false,
        actions: [
          {
            label: 'Update',
            actionType: 'link',
            action: '#',
          },
        ],
      },
    ]

  }

}

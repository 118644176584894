<div *ngIf="! showSummaryOnly()" class="d-flex justify-content-between align-items-center mb-3">
  <h2></h2>
  <app-powered-by-lm></app-powered-by-lm>
</div>

<div *ngIf="canStartForm()">

  <p>Obtain a <b>FREE personalised quote</b> from our trusted solicitors <b>Churchers</b>, who are regulated by the
    <a role="button" class="text-primary"
      mdbPopover="Being regulated by the SRA means that Churchers are bound by strict professional and ethical rules and that they carry professional indemnity insurance."
      placement="right"
      mdbPopoverHeader="Solicitors Regulation Authority"
      triggers="focus">Solicitors Regulation Authority</a>.
  </p>

  <p>To get started, use the link below to answer a few simple questions about your current circumstances.

</div>

<div *ngIf="canSubmitForm() && ! showSummaryOnly()">

  <p>LPAs on whenivegone.com are administered by our legal partners at <b>Churchers</b>. In order to connect you with a solicitor we will need to pass across some of your personal details, and we need your consent to do this, the information that will be passed across will be:</p>

  <ul>
    <li>Your full name</li>
    <li>Your email address</li>
    <li>The answers you gave on the LPA form</li>
  </ul>

  <p>By pressing the "Submit Details" button below, you are indicating that you consent to us sharing your data with Churchers.</p>

</div>

<div *ngIf="object['service_type_status'] === 'submitted' && ! showSummaryOnly()">
  <p>You have successfully submitted your Lasting Power of Attorney (LPA) form and we have passed your details to <b>Churchers</b>. A solicitor will be in touch soon to discuss the next steps with you.</p>
</div>

<div *ngIf="object['service_type_status'] === 'complete' && ! showSummaryOnly()">
  <p>Congratulations! Your LPA with Churchers has been completed!</p>
</div>

<div class="list-group list-group-flush">
  <hr />

  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">

      <div class="row">
        <div class="col-sm-12">
            Your Lasting Power of Attorney (LPA) with Churchers
        </div>
      </div>

    </div>

    <div style="min-width: 188px;">

      <button *ngIf="canEditForm() && ! showSummaryOnly()" (click)="openLpaForm()" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>
        <span *ngIf="canStartForm()">Complete LPA Form</span>
        <span *ngIf="object['service_type_status'] === 'ready'">Edit LPA Form</span>
      </button>

      <button *ngIf="canSubmitForm() && ! showSummaryOnly()" (click)="submitLpaForm()" class="indigo lighten-1 white-text" mdbBtn type="button" size="sm" mdbWavesEffect>Submit Details</button>

      <span *ngIf="object['service_type_status'] === 'submitted'">Submitted</span>
      <span *ngIf="object['service_type_status'] === 'complete'">Complete</span>

      <span *ngIf="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </span>

    </div>
  </div>

</div>

import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { WpServerService } from '../../angular-wordpress-rest-api/services/wp-server/wp-server.service';
import { WpObject } from '../../angular-wordpress-rest-api/classes/wp-object/wp-object';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private wpServerService: WpServerService,
  ) { }

  setReferralToPending( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/mark-pending' , sendObject , '/wig/v1/' );

  }

  setReferralToLive( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/mark-live' , sendObject , '/wig/v1/' );

  }

  returnToLive( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/return-to-live' , sendObject , '/wig/v1/' );

  }

  setReferralToComplete( object: WpObject ) {

    let sendObject = {
      id: object.id,
      complete_date: object['completeDate'],
      total_billed: object['totalBilled'],
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/mark-complete' , sendObject , '/wig/v1/' );

  }

  setReferralToCancelled( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/mark-cancelled' , sendObject , '/wig/v1/' );

  }

  addServiceToReferral( object: WpObject , type: string ) {

    let sendObject = {
      id: object.id,
      service_type: type,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/add-service' , sendObject , '/wig/v1/' );

  }

  requestReferrtalToCancelled( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'referrals/' + object.id + '/request-cancel' , sendObject , '/wig/v1/' );

  }

  moveServiceToReferral( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'services/' + object.id + '/move-to-referral' , sendObject , '/wig/v1/' );

  }

  removeServiceFromReferral( object: WpObject ) {

    let sendObject = {
      id: object.id,
    }

    return this.wpServerService.createObject( 'services/' + object.id + '/remove-from-referral' , sendObject , '/wig/v1/' );

  }

  addNote( object: WpObject ) {
    return of({});
  }

}

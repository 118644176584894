<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Love and Hate</h2>
  <p>Go on, what do you really thing? What do you love and hate! Let us have it!</p>
</div>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-3">
          Name
        </div>
        <div class="col-sm-2">
          Love / Hate
        </div>
        <div class="col-sm-7">
          Description
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

      <div class="row align-items-center">
        <div class="col-sm-3">
          {{ item['give_it_a_title'] }}
        </div>
        <div class="col-sm-2">
          {{ item['love_or_hate'] }}
        </div>
        <div class="col-sm-7">
          {{ item['tell_us_more'] }}
        </div>
      </div>

  </div>
</app-service-child-list>

<div class="row" *ngIf="! object.id">
  <div class="col-lg-4 border-right">

    <strong>Need inspiration? Why not browse our list of rant topics?</strong>
    <hr />

    <mdb-select [options]="topicOptions" (ngModelChange)="getSelectedOption($event)" [(ngModel)]="selectedTopic" placeholder="Choose topic"></mdb-select>


  </div>
  <div class="col-lg-8">

    <ng-container *ngTemplateOutlet="ListItemForm"></ng-container>

  </div>
</div>

<div class="row" *ngIf="object.id">
  <div class="col-lg-12">
    <ng-container *ngTemplateOutlet="ListItemForm"></ng-container>
  </div>
</div>

<ng-template #ListItemForm>

  <p>Add a section to your rant below. Give it a title and then let it rip!</p>

  <hr />

  <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >

</ng-template>

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Death Instructions</h2>
  <p>There might be things that you need to be taken care of if you were to die. Perhaps you have pets than need taking care of, perhaps you are looking after someone,
    perhaps you have a business that would need taking care of.
  <p>Whatever it is, you could make this easier for your loved ones by listing things here.</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-8">
          Instructions
        </div>
        <div class="col-sm-4">
          Who Should Do This?
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-8">
        {{ item['instruction'] }}
      </div>
      <div class="col-sm-4">
        {{ item['who_should_do_this'] }}
      </div>
    </div>

  </div>
</app-service-child-list>

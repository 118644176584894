import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { MasterFormComponent } from '../../../../master-form/master-form.component';

import { ThirdPartyDataService } from '../../../../../services/third-party-data/third-party-data.service';

import { SelectOption } from '../../../../../classes/select-option';
import { Activity } from '../../../../../classes/activity';
import { ActivityCategory } from '../../../../../classes/activity-category';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class BucketAddEditComponent implements OnInit {

  @ViewChild( MasterFormComponent ) formComponent: MasterFormComponent;

  @Input() object: any;
  @Input() key: any;
  @Input() extraData: any;

  categories: ActivityCategory[];
  categoryOptions: SelectOption[];
  selectedCategory: number|string;

  activities: Activity[];
  activityOptions: SelectOption[];
  selectedOption: number|string;

  loading: boolean;

  constructor(
    private thirdPartyDataService: ThirdPartyDataService,
  ) { }

  ngOnInit() {

    this.loading = true;

    this.thirdPartyDataService.getActivityCategories().subscribe(

      resp => {
        this.categories = resp;
        this.categoryOptions = this.prepareOptions( this.categories );
        this.loading = false;
      },

      error => {
        //console.log( error );
        this.loading = false;
      },

    );

  }

  getBucketListItems( category: number ):void {

    this.loading = true;

    this.thirdPartyDataService.getBucketListItems([ category ]).subscribe(

      resp => {
        this.activities = resp;
        this.activityOptions = this.prepareItems( this.activities );
        this.loading = false;
      },

      error => {
        //console.log( error );
        this.loading = false;
      },

    );

  }

  prepareOptions( categories: ActivityCategory[] ): SelectOption[] {

    let options = []

    for( var i = 0; i < categories.length; i++ ) {

      let option: SelectOption = {
        value: categories[ i ].id,
        label: categories[ i ].name + ' (' + categories[ i ].count + ')',
      }

      options.push( option );

    }

    return options;

  }

  prepareItems( activities: Activity[] ): SelectOption[] {

    let options = []

    for( var i = 0; i < activities.length; i++ ) {

      let option: SelectOption = {
        value: activities[ i ].id,
        label: activities[ i ].title.rendered,
      }

      options.push( option );

    }

    return options;

  }

  getLabelFromValue( value: number ): string|boolean {

    for( var i = 0; i < this.activityOptions.length; i++ ) {

      let thisActivityOption = this.activityOptions[ i ];

      if( thisActivityOption.value === value ) {
        return thisActivityOption.label;
      }

    }

    return false;

  }

  getSelectedCategory( event: number ): void {
    this.getBucketListItems( event );
  }

  getSelectedOption( event: number ): boolean {

    let value = this.getLabelFromValue( event );

    if( ! value ) {
      return false;
    }

    this.object['item_name'] = value;
    this.formComponent.updateObjectValue( 'item_name' , value );

    return true;

  }

}

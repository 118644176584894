<h3 class="text-left mt-2"><strong>{{ tab['title'] }}</strong></h3>

<div *ngIf="loading" class="row">
  <div class="col-12">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="! loading">

  <button mdbBtn type="button" class="btn btn-primary btn-sm mb-1 float-right" (click)="maybeWarnMethod( addNoteAction , referral )">{{ addNoteAction['label'] }}</button>

  <div *ngIf="referrals.length === 0" class="row">
    <div class="col-12">
      Nothing to show
    </div>
  </div>

  <table class="table table-striped" *ngIf="referrals.length !== 0">

    <thead>
      <tr>
        <th>Date</th>
        <th>Action</th>
        <th>Added by</th>
        <th>Note</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let referral of referrals" data-id="{{ referral.id }}">
        <td>{{ referral['date'] | date:'dd/MM/yyyy' }}</td>
        <td>{{ referral.title['rendered'] }}</td>
        <td>{{ referral['author_name']}}</td>
        <td>{{ referral['event_notes'] }}</td>
        <td>
          <div *ngIf="tab['actions']">
            <button mdbBtn (click)="maybeWarnMethod( action , referral )" *ngFor="let action of tab['actions'] | objectArrayFilter:{ key: 'allowedRoles' , values: currentUserRoles }"
              type="button" class="btn btn-{{ action.type }} table-action btn-sm btn-block mb-1" [disabled]="disableActions">
              {{ action.label }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>

  </table>

  <app-pagination *ngIf="referrals.length !== 0" [totalPages]="totalPages" [currentPageNumber]="currentQuery['pageNumber']" (onPageChange)="onPageChange( $event )"></app-pagination>

</div>

<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-xl-4 col-lg-3"></div>

      <div class="col-xl-4 col-lg-6">

        <div class="row">
          <div class="col-12">

            <mdb-card>

              <!--Card content-->
              <mdb-card-body>
                <!--Title-->
                <mdb-card-title>
                  <h4>Reset Password</h4>
                </mdb-card-title>

                <!-- Form -->
                <form *ngIf="!sent" class="text-center" style="color: #757575;" (ngSubmit)="resetPassword( i )" #i="ngForm">

                  <!-- Email -->
                  <div class="md-form">
                    <input [(ngModel)]="email" name="email" type="email" id="email" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                    <label for="email">E-mail *</label>
                  </div>

                  <!-- Sign in button -->
                  <button mdbBtn color="info" outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect type="submit" [disabled]="! i.valid || loading">
                    <span *ngIf="! loading">Reset Password</span>
                    <span *ngIf="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </span>
                  </button>

                </form>
                <!-- Form -->

                <div *ngIf="sent">
                  <p>{{ sentMessage }}</p>
                </div>

                <!-- Material form login -->

              </mdb-card-body>
            </mdb-card>

          </div>
        </div>

        </div>
        <div class="col-xl-4 col-lg-3"></div>

      </div>

  </div>
</div>

<app-footer></app-footer>

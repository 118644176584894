<mdb-tabset *ngIf="! showSummaryOnly()" #staticTabs [buttonClass]="'md-tabs tabs-3 indigo lighten-1'" [contentClass]="'card'">
  <mdb-tab heading="Your Timeline">

    <!-- Jumbotron -->
    <div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
      <h2 class="card-title h2">This is Your Life</h2>
      <p>It's great to be able to look back at your most treasured moments in life. Keep a track of them here and leave them for your loved ones when you're gone.</p>
    </div>

    <app-service-completion [service]="object"></app-service-completion>

    <div *ngIf="loading" class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <ng-template [ngTemplateOutlet]="timeline" [ngTemplateOutletContext]="{ sections: sections }"></ng-template>

  </mdb-tab>

  <mdb-tab heading="Edit Your Timeline">

    <app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
      <div *app-content-loader="let item" class="flex-fill">

        <div class="row">
          <div class="col-sm-12">
            {{ item['item_name'] }}
          </div>
        </div>

      </div>
    </app-service-child-list>


  </mdb-tab>
</mdb-tabset>

<ng-template *ngIf="showSummaryOnly()" [ngTemplateOutlet]="timeline" [ngTemplateOutletContext]="{ sections: sections }"></ng-template>

<ng-template #timeline let-sections="sections">
  <!-- Timeline -->
  <div class="container">
    <div class="">

      <div *ngIf="sections && sections.length === 0" class="text-center mb-4">
        <p>You haven't added any items yet...</p>
        <button (click)="changeActiveTab( 2 )" type="button" mdbBtn color="default" mdbWavesEffect>Add some items</button>
        <hr />
      </div>

      <div *ngIf="loading" class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <div class="timeline-main">
        <!-- Timeline Wrapper -->
        <ul *ngIf="sections" class="stepper stepper-vertical timeline timeline-simple timeline-images pl-0">

          <li *ngFor="let section of (sections | objectSort:'date'); let index=index; let odd=odd; let even=even;" [class.timeline-inverted]="odd">
            <!--Section Title -->
            <a href="#!">
              <span class="circle cyan lighten-1"></span>
            </a>

            <!-- Section Description -->
            <div class="step-content ml-3 p-0 hoverable">
              <img *ngIf="section.image" [src]="section.image" class="img-fluid" alt="Responsive image">
              <h4 class="font-weight-bold p-4 mb-0">{{ section.item_name }}</h4>
              <p class="text-muted px-4 mb-0"><i class="far fa-clock" aria-hidden="true"></i> {{ section.date | date }}</p>
              <p class="mb-0 p-4">{{ section.text_area_description }}</p>
            </div>
          </li>

        </ul>
        <!-- Timeline Wrapper -->
      </div>
    </div>
  </div>
  <!-- Timeline -->
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectFilter',
  pure: false,
})
export class ObjectFilterPipe implements PipeTransform {

  transform( items: any[] , args: Object[] ): any {

    let returnArray = [];

    for( let item of items ) {

      let passed = true;

      for( let arg of args ) {

        let key = arg['key'];
        let value = arg['value'];
        let compare = arg['compare'];

        let checkValue = item[ key ];

        if( compare === '==' && ! this.equals( value , checkValue )) {
          passed = false;
        }

        if( compare === '!=' && ! this.notEquals( value , checkValue )) {
          passed = false;
        }

      }

      if( passed ) {
        returnArray.push( item );
      }

    }

    return returnArray;

  }

  equals( value , checkValue ) {
    return ( value == checkValue ? true : false );
  }

  notEquals( value , checkValue ) {
    return ( value != checkValue ? true : false );
  }

}

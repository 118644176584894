import { Injectable } from '@angular/core';

import { ServerService } from '../../server.service';
import { GlobalCommsService } from '../global-comms/global-comms.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private serverService: ServerService,
    private globalCommsService: GlobalCommsService,
  ) { }

  isLoggedIn():void {

    let token = localStorage.getItem( 'userToken' );

    if( typeof( token ) !== 'string' ) {

      this.globalCommsService.updateLoginStatus({
        status: 'not-logged-in',
      });

    }

    this.serverService.validateToken().subscribe(

      resp => {

        if( resp['data']['status'] != 200 ) {

          localStorage.clear();

          this.globalCommsService.updateLoginStatus({
            status: 'not-logged-in',
          });

        }

        this.globalCommsService.updateLoginStatus({
          status: 'logged-in',
        });

      },

      error => {

        localStorage.clear();

        this.globalCommsService.updateLoginStatus({
          status: 'not-logged-in',
        });

      },

    );

  }

}

import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[app-content-loader]'
})
export class ContentLoaderDirective {

  @Input() object: Object;

  constructor( public viewContainerRef: ViewContainerRef ) { }

}

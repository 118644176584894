<mdb-tabset *ngIf="! showSummaryOnly()" #staticTabs [buttonClass]="'md-tabs tabs-3 indigo'" [contentClass]="'card'">

  <mdb-tab heading="View Your Eulogy">

    <div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
      <h2 class="card-title h2">Your Eulogy</h2>
      <p>Your eulogy captures who you were as a person, so writing your own eulogy allows you to
        show people how you want them to remember you. Writing your own eulogy allows you a
        chance to share a final piece of yourself with the people you care about most.</p>
    </div>

    <app-service-completion [service]="object"></app-service-completion>

    <div *ngIf="hasContent">
      <div [innerHTML]="object['eulogy_content'] | safeHtml"></div>
      <hr />
    </div>

    <div *ngIf="! hasContent" class="text-center mb-4">
      <p>You haven't started your eulogy yet...</p>
      <button (click)="changeActiveTab( 2 )" type="button" mdbBtn color="default" mdbWavesEffect>Start Your Eulogy</button>
      <hr />
    </div>

  </mdb-tab>

  <mdb-tab heading="Edit Your Eulogy">
    <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >
  </mdb-tab>

</mdb-tabset>

<div *ngIf="showSummaryOnly()">
  <div [innerHTML]="object['eulogy_content'] | safeHtml"></div>
</div>

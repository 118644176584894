import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContentLoaderDirective } from '../../directives/content-loader.directive';

import { GlobalCommsService } from '../../services/global-comms/global-comms.service';
import { ServicesDefinitionService } from '../../services/services-definition/services-definition.service';

import { UserHomeItem } from '../../classes/user-home-item/user-home-item';
import { UserHomeTile } from '../../classes/user-home-tile/user-home-tile';

@Component({
  selector: 'app-service-summary',
  templateUrl: './service-summary.component.html',
  styleUrls: ['./service-summary.component.scss']
})
export class ServiceSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren( ContentLoaderDirective ) dynamicComponents!: QueryList<ContentLoaderDirective>;

  public filterObject: Object[] = [{ key: 'showInSummary' , value: false , compare: '!=' }]
  public sortString: string = 'summarySortOrder';

  public loading: boolean = true;
  public navigationItem: UserHomeItem;

  private navigationItems: UserHomeItem[];
  private services: Object[] = [];
  private subscriptions: Subscription = new Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private globalCommsService: GlobalCommsService,
    private router: Router,
    private servicesDefinitionService: ServicesDefinitionService,
  ) { }

  ngOnInit(): void {

    this.navigationItems = this.servicesDefinitionService.getServicesDefinition();

    this.subscriptions.add( this.activatedRoute.paramMap.pipe().subscribe(
      resp => {

        for( let navigationItem of this.navigationItems ) {

          if( navigationItem.slug === resp['params']['section'] ) {
            this.navigationItem = navigationItem;
          }

        }

      }
    ));

    this.subscriptions.add( this.globalCommsService.currentServices.pipe(
      filter( resp => resp[0]['action'] !== 'wait' )
    ).subscribe(
      resp => {
        this.services = this.assignObjects( resp );
        this.loading = false;
      }
    ));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {

    this.setUpDynamicComponents();

    this.subscriptions.add( this.dynamicComponents.changes.subscribe(( dynamicComponents ) => {
      this.setUpDynamicComponents();
    }));

  }

  setUpDynamicComponents() {

    this.dynamicComponents.forEach( dynamicComponent => {

      let componentFactory = this.componentFactoryResolver.resolveComponentFactory( dynamicComponent.object['component'] );
      let viewContainerRef = dynamicComponent.viewContainerRef;

      viewContainerRef.clear();

      setTimeout(() => {
        let componentRef = viewContainerRef.createComponent( componentFactory );
        componentRef['instance']['object'] = dynamicComponent.object['userObject'];
        componentRef['instance']['summaryOnly'] = true;
      }, 0 );

    });

  }

  assignObjects( userObjects: Object[] ) {

    for( let navigationItem of this.navigationItems ) {
      for( let child of navigationItem.children ) {
        delete child.userObject;
      }
    }

    for( let userObject of userObjects ) {
      let userHomeItem = this.pluckUserHomeItem( this.navigationItems , userObject['service_type'] );
      userHomeItem.userObject = userObject;
    }

    return userObjects;

  }

  pluckUserHomeItem( items: UserHomeItem[] , type: string ): any {

    for( let userHomeItem of items ) {

      if( userHomeItem.slug === type ) {
        return userHomeItem;
      }

      if( typeof( userHomeItem.children ) !== 'undefined' ) {

        let pluckChild = this.pluckUserHomeItem( userHomeItem.children , type );

        if( pluckChild.slug !== 'unknown-item' ) {
          return pluckChild;
        }

      }

    }

    return {
      label: 'Unknown item',
      slug: 'unknown-item',
    }

  }

}

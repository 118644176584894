import { Pipe, PipeTransform } from '@angular/core';

import { WpUser } from '../../angular-wordpress-rest-api/classes/wp-user/wp-user';
import { NavItem } from '../../classes/nav-item/nav-item';

@Pipe({
  name: 'navFilter'
})
export class NavFilterPipe implements PipeTransform {

  transform( value: NavItem[], args: Object ): any {

    let returnItems = [];

    if( args['loginStatus'] === 'unknown' ) {
      return returnItems;
    }

    for( var i = 0; i < value.length; i++ ) {

      let thisItem = value[ i ];
      let matchedRoles = [];

      if( thisItem.loginStatus.indexOf( args['loginStatus'] ) === -1 ) {
        continue;
      }

      for( let role of args['roles'] ) {
        if( thisItem.allowedRoles.indexOf( role ) !== -1 ) {
          matchedRoles.push( role );
        }
      }

      if( matchedRoles.length === 0 ) {
        continue;
      }

      returnItems.push( thisItem );

    }

    return returnItems;

  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleFilter'
})
export class SimpleFilterPipe implements PipeTransform {

  transform( items: any[] , filter: Object , filterBy: string ): any {

    if ( ! items || ! filter ) {
      return items;
    }

    return items.filter( item => item[ filterBy ].indexOf( filter ) !== -1 );

  }

}

import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit {

  public tab: Object;
  private query: Object;
  public referral: Object;

  constructor( public modalRef: MDBModalRef ) {}

  ngOnInit() {

    //console.log( this );

    this.tab = {
      title: 'Notes and Events',
      slug: 'notes-events',
      view: 'quotes',
      filters: [],
      actions: [],
      allowedRoles: [
        'agent',
        'branch_manager',
        'administrator',
        'subscriber'
      ],
      query: this.query,
    }

  }

}

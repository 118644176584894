<div *ngFor="let nominatedPersonServicePart of getNominatedPersonServiceTypes()" class="card">
  <div class="card-body row">

    <div class="col-sm-4">
      <h5 class="card-title">
        <span [innerHTML]="nominatedPersonServicePart['nominated_persons_name']"></span>
        <span [innerHTML]="nominatedPersonServicePart['lpa_store_attorney_name']"></span>
        <span [innerHTML]="nominatedPersonServicePart['will_store_executor_name']"></span>
      </h5>
      <p class="card-text">
        <span [innerHTML]="nominatedPersonServicePart['nominated_persons_email_address']"></span>
        <span [innerHTML]="nominatedPersonServicePart['lpa_store_attorney_email']"></span>
        <span [innerHTML]="nominatedPersonServicePart['will_store_executor_email']"></span>
        <br />
        <span [innerHTML]="nominatedPersonServicePart['nominated_persons_contact_number']"></span>
        <span [innerHTML]="nominatedPersonServicePart['lpal_store_attorney_contact']"></span>
        <span [innerHTML]="nominatedPersonServicePart['will_store_executor_contact']"></span>
        <br />
        <mdb-badge *ngIf="nominatedPersonServicePart['has_been_notified']" default="true">Notified</mdb-badge>
      </p>

      <form *ngIf="! nominatedPersonServicePart['has_been_notified']" (submit)="notifyNominatedPerson( nominatedPersonServicePart )">
        <div class="md-form">
          <textarea
            type="text"
            [id]="'extra-message-' + nominatedPersonServicePart.id"
            class="md-textarea md-textarea-auto form-control"
            mdbInput
            [name]="'extra-message-' + nominatedPersonServicePart.id"
            [(ngModel)]="nominatedPersonServicePart['note']"
          ></textarea>
          <label [for]="'extra-message-' + nominatedPersonServicePart.id" >Add an extra note to include in the email (optional)</label>
        </div>

        <button mdbBtn class="btn-default float-right" [disabled]="loading">
          <span *ngIf="! loading">Send!</span>
          <span *ngIf="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Sending...
          </span>
        </button>

      </form>
    </div>

    <div class="col-sm-8">

      <div *ngIf="! nominatedPersonServicePart['has_been_notified']">

        <h5 class="mb-4"><strong>We'll send this email to <span [innerHTML]="nominatedPersonServicePart['nominated_persons_name']"></span>:</strong></h5>

        <div *ngIf="currentUser">
          <p>"Dear
            <span [innerHTML]="nominatedPersonServicePart['nominated_persons_name']"></span>
            <span [innerHTML]="nominatedPersonServicePart['lpa_store_attorney_name']"></span>
            <span [innerHTML]="nominatedPersonServicePart['will_store_executor_name']"></span>
            ,</p>
          <p>We're sending you this email because <span [innerHTML]="currentUser.name"></span> has listed you on their account at <a href="">When I've Gone</a>
            as someone that they'd like to help take care of their affairs in the case of their death or incapacitation.</p>

          <h6><strong>What do you need to do?</strong></h6>
          <p>Right now, you don't need to do anything, but keep this email, perhaps print it and store it somewhere safe.</p>
          <p>In the unfortunate event that something were to happen to <span [innerHTML]="currentUser.first_name"></span>, all you need to do is
            <a href="">get in contact with us</a> and we'll help get you the information you need to take care of the things <span class="card-title" [innerHTML]="currentUser.first_name"></span>
            is asking of you.</p>
          <p>If you have any questions about the specific things on the list, you should get in touch with <span [innerHTML]="currentUser.first_name"></span> to chat about it
            some more</p>

          <p><strong>The When I've Gone Team</strong>"</p>
        </div>
      </div>
    </div>

  </div>
</div>

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ServicesDefinitionService } from '../../services/services-definition/services-definition.service';
import { ServicesService } from '../../services/services/services.service';
import { WpUserService } from '../../angular-wordpress-rest-api/services/wp-user/wp-user.service';

import { UserHomeItem } from '../../classes/user-home-item/user-home-item';
import { WpObject } from '../../angular-wordpress-rest-api/classes/wp-object/wp-object';
import { WpUser } from '../../angular-wordpress-rest-api/classes/wp-user/wp-user';

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.scss']
})
export class SendNotificationsComponent implements OnInit, OnDestroy {

  @Input() navigationItem: UserHomeItem;

  public currentUser: WpUser;
  public loading: boolean = false;

  private subscriptions: Subscription = new Subscription;

  constructor(
    private servicesService: ServicesService,
    private wpUserService: WpUserService,
  ) { }

  ngOnInit(): void {

    this.subscriptions.add( this.wpUserService.currentUser.pipe(
      filter( resp => resp['id'] )
    ).subscribe(
      resp => {
        this.currentUser = resp;
        console.log( resp );
      }
    ));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getNominatedPersonTypes() {
    return [
      { navItemType: 'make-things-easy' , type: 'mte-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'letter-of-intent' , type: 'loi-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'when-ive-gone' , type: 'wig-nominated-contacts' , label: 'nominated person(s)' },
      { navItemType: 'lasting-power-of-attorney' , type: 'lpa-store' , label: 'attorney(s)' },
      { navItemType: 'last-will-and-testiment' , type: 'store-a-will' , label: 'executor(s)' },
    ];
  }

  getNominatedPersonServices( navigationItem: UserHomeItem ) {

    let nominatedPersonTypes = this.getNominatedPersonTypes();

    let matchedChildren = navigationItem.children.filter( child => {

      if( typeof( child.userObject ) === 'undefined' ) {
        return false;
      }

      if( typeof( child.userObject['service_part_children'] ) === 'undefined' ) {
        return false;
      }

      if( child.userObject['service_part_children'].length === 0 ) {
        return false;
      }

      return true;

    });

    return matchedChildren;

  }

  getNominatedPersonServiceTypes() {

    let nominatedPersonServices = this.getNominatedPersonServices( this.navigationItem );

    if( nominatedPersonServices.length === 0 ) {
      return [];
    }

    return nominatedPersonServices[0].userObject['service_part_children'];

  }

  notifyNominatedPerson( person: WpObject ) {

    this.loading = true;

    this.servicesService.sendNotificationEmail( person ).subscribe(
      resp => {
        person = { ...resp };
        this.refreshServices();
      },
      error => {
        this.loading = false;
      }
    );

  }

  refreshServices() {
    this.servicesService.getServices().subscribe(
      resp => {
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

}

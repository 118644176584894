<a *ngIf="object['class']['complete'] && ! object['paid'] && data['pay']" routerLink="/cart" (click)="addAndClose( object.id , 'cart' ) " type="button" class="btn btn-default">
    <span *ngIf="! loading">Purchase Your Will!</span>
</a>

<a *ngIf="object['will_url'] && data['view']" [href]="object['will_url']" target="_blank" type="button" class="btn btn-default">
  <span>View Your Will</span>
</a>

<button *ngIf="object['class']['complete'] && object['paid'] && ! object['will_url'] && data['generate']" (click)="generateWill()" type="button" class="btn btn-default" [disabled]="loading">
  <span *ngIf="! loading">Generate Will</span>
  <span *ngIf="loading">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Loading...
  </span>
</button>

<button *ngIf="object['class']['complete'] && object['paid'] && object['will_url'] && data['regenerate']" (click)="generateWill()" type="button" class="btn btn-default" [disabled]="loading">
  <span *ngIf="! loading">Regenerate Will</span>
  <span *ngIf="loading">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Loading...
  </span>
</button>

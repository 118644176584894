import { Component, OnInit, AfterViewInit, OnDestroy, Input, ElementRef, ViewChildren, EventEmitter  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';

import * as FontFaceObserver from 'fontfaceobserver';

import { ServerService } from '../../server.service';
import { AccountManagementService } from '../../services/account-management/account-management.service';
import { ServicesService } from '../../services/services/services.service';
import { GlobalCommsService } from '../../services/global-comms/global-comms.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

  @Input() questions: Array<Object>;
  @Input() object: any; //allow this to recieve an object to get existing data from
  @Input() extraData: Object;

  @ViewChildren( 'canvas' ) canvas: any;
  @ViewChildren( 'wysiwyg' ) wysiwyg: any;

  sendData: Array<string>;
  currentQuestionPage: number;
  progress: number;
  loading: boolean;
  formValues: Object;
  helpModalOptions: Object;

  heldValues: Object;
  freeTextFields: Object;

  helpHeading: String;

  validatingForm: FormGroup;

  files: Array<any>;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  currentUpload: string;

  constructor(
    public serverService: ServerService,
    public accountManagementService: AccountManagementService,
    public servicesService: ServicesService,
    public router: Router,
    private modalClose: GlobalCommsService,
    private formBuilder: FormBuilder,
  ) {

    this.questions = [];
    this.currentQuestionPage = 0;
    this.sendData = [];
    this.formValues = {};
    this.helpModalOptions = {
      backdrop: false,
      ignoreBackdropClick: true,
      show: false,
    };

    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;

  }

  ngOnInit() {

    if( typeof( this.extraData ) !== 'undefined' && typeof( this.extraData['helpModalOptions'] ) !== 'undefined' ) {
      this.helpModalOptions = Object.assign( this.helpModalOptions , this.extraData['helpModalOptions'] );
    }

    if( typeof( this.object ) !== 'undefined' ) {
      this.formValues = this.object;
    }

    this.formValues['heldValues'] = {};

    this.setUpFormValues();
    this.setProgress();

  }

  ngAfterViewInit() {

    this.drawCanvas();

    if( typeof( this.object ) !== 'undefined' && typeof( this.object['id'] ) === 'undefined' ) {
      this.wysiwyg.forEach( function( item , key ) {
        item.value = '';
      });
    }

  }

  ngOnDestroy() {
    this.removeValidation();
  }

  get input() { return this.validatingForm.get( 'required' ); }

  setUpFormValues() {

    for( var i = 0; i < this.questions.length; i++ ) {
      let thisQuestionSet = this.questions[ i ];
      let thisFormGroup = {}


      for( var x = 0; x < thisQuestionSet['questions'].length; x++ ) {

        let thisQuestion = thisQuestionSet['questions'][ x ];

        let theseOptions = this.questions[ i ]['questions'][ x ]['options'];
        let checkOptions = false;
        let currentValue = this.formValues[ thisQuestion['name'] ];

        //if the current object doesn't have a value for this field...
        if( typeof( currentValue ) === 'undefined' ) {
          this.formValues[ thisQuestion['name'] ] = '';
        }

        //if this is a hidden field we'll just set it's value...
        if( thisQuestion['type'] === 'hidden' ) {
          this.formValues[ thisQuestion['name'] ] = thisQuestion.value;
        }

        //if a question is tagged for autoSend we'll add it straight to the sendData...
        //NB it's assumed that a field should be sent if it has been included
        if( typeof( thisQuestion['autoSend'] ) === 'undefined' || thisQuestion['autoSend'] ) {
          this.sendData.push( thisQuestion['name'] );
        }

        let validators = []

        if( thisQuestion['required'] && thisQuestion['type'] !== 'image' ) {
          validators.push( Validators.required );
        }

        //here we'll look for canvas Fields
        if( thisQuestion['name'].indexOf( '_canvas' ) !== '1' ) {
          let canvasId = thisQuestion['name'].replace( '_canvas' , '_image_url' );
          this.sendData.push( canvasId );

        }

        thisFormGroup[ thisQuestion['name'] ] = [
          this.formValues[ thisQuestion['name'] ],
          validators,
        ]

        //if this question has no options we're done here
        if( typeof( thisQuestion['options'] ) === 'undefined' || thisQuestion['options'].length === 0 ) {
          continue;
        }

        for( var a = 0; a < theseOptions.length; a++ ) {
          let thisOption = theseOptions[ a ];

          //this purely sets the IDs for the options to feed into the form template
          this.questions[ i ]['questions'][ x ]['options'][ a ]['id'] = thisQuestionSet['name'] + '_' + x + '_' + a;

          //we're going to check if we've found the current value of the field in the field options
          if( currentValue === thisOption['value'] ) {
            checkOptions = true;
          }

        }

        //after looping the options, we'll check if the field has a value that wasn't in the options
        //if this is the case we'll set it to 'free-text' and show the extra field
        if( ! checkOptions && currentValue ) {
          this.selectValueChange( 'free-text' , thisQuestion['name'] );
          this.formValues[ thisQuestion['name'] ] = 'free-text';
          this.formValues['heldValues'][ thisQuestion['name'] ] = currentValue;
        }

      }

      this.questions[ i ]['formGroup'] = this.formBuilder.group( thisFormGroup );

    }

  }

  setUpValidation() {

    //we will only add validation to the current question set
    let currentQuestionSet = this.questions[ this.currentQuestionPage ];

    for( var i = 0; i < this.questions.length; i++ ) {
      let thisQuestionSet =  this.questions[ i ];

      for( var x = 0; x < thisQuestionSet['questions'].length; x++ ) {
        let thisQuestion = thisQuestionSet['questions'][ x ];

        if( ! thisQuestion['required'] ) {
          continue;
        }

        if( currentQuestionSet === thisQuestionSet && ! Array.isArray( this.formValues[ thisQuestion['name'] ] ) ) {
          this.formValues[ thisQuestion['name'] ] = [
            this.formValues[ thisQuestion['name'] ],
            Validators.required,
          ]
        }

      }
    }

    this.validatingForm = this.formBuilder.group( this.formValues );

  }

  removeValidation() {

    for( var i = 0; i < this.questions.length; i++ ) {
      let thisQuestionSet =  this.questions[ i ];

      for( var x = 0; x < thisQuestionSet['questions'].length; x++ ) {
        let thisQuestion = thisQuestionSet['questions'][ x ];

        if( Array.isArray( this.formValues[ thisQuestion['name'] ] ) ) {

          let currentValue = this.validatingForm.controls[ thisQuestion['name'] ].value;

          this.formValues[ thisQuestion['name'] ] = currentValue;

          this.validatingForm.get( thisQuestion['name'] ).clearValidators();
          this.validatingForm.get( thisQuestion['name'] ).updateValueAndValidity();

        }

      }
    }

    this.validatingForm = this.formBuilder.group( this.formValues );

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.getCurrentFormGroup().controls;
  }

  validateAllFormFields( formGroup: FormGroup = this.getCurrentFormGroup() ) {

    Object.keys( formGroup.controls ).forEach( field => {

      const control = formGroup.get( field );

      if( control instanceof FormControl ) {
        control.markAsTouched({ onlySelf: true });
      } else if( control instanceof FormGroup ) {
        this.validateAllFormFields( control );
      }

    });

  }

  validateForm() {

    if( this.getCurrentFormGroup().valid ) {
      this.updateObject();
      return true;
    }

    this.validateAllFormFields();
    return false;

  }

  getCurrentFormGroup() {
    return this.questions[ this.currentQuestionPage ]['formGroup'];
  }

  updateObject() {
    let getCurrentFormGroup = this.getCurrentFormGroup();
    return Object.assign( this.formValues , getCurrentFormGroup.value );
  }

  getHelpHeading() {

    if( typeof( this.extraData ) !== 'undefined' && typeof( this.extraData['heading'] ) !== 'undefined' ) {
      return this.helpHeading = this.extraData['heading'];
    }

    return this.helpHeading = this.questions[ this.currentQuestionPage ]['heading'];

  }

  hasNextAction() {
    if( typeof( this.questions[ this.currentQuestionPage ]['nextAction'] ) !== 'undefined' ) {
      return true;
    }
    return false;
  }

  hasPrevAction() {
    if( typeof( this.questions[ this.currentQuestionPage ]['prevAction'] ) !== 'undefined' ) {
      return true;
    }
    return false;
  }

  runNextAction() {

    if( ! this.validateForm() ) {
      return;
    }

    var atts;

    let action = this.questions[ this.currentQuestionPage ]['nextAction']['action'];

    if( typeof( this.questions[ this.currentQuestionPage ]['nextAction']['atts'] ) === 'undefined' ) {
      atts  = {};
    } else {
      atts = this.questions[ this.currentQuestionPage ]['nextAction']['atts'];
    }

    return this[ action ]( atts );

  }

  runPrevAction() {

    var atts;

    let action = this.questions[ this.currentQuestionPage ]['prevAction']['action'];

    this.removeValidation();

    if( typeof( this.questions[ this.currentQuestionPage ]['prevAction']['atts'] ) === 'undefined' ) {
      atts  = {};
    } else {
      atts = this.questions[ this.currentQuestionPage ]['prevAction']['atts'];
    }

    return this[ action ]( atts );

  }

  selectValueChange( value: any , questionName: string ) {

    if( typeof( this.formValues['heldValues'] ) === 'undefined' ) {
      this.formValues['heldValues'] = {};
    }

    if( value !== 'free-text' ) {
      this.formValues['heldValues'][ questionName ] = false;
      return;
    }

    this.formValues['heldValues'][ questionName ] = '';
    return;

  }

  showFreeText( questionName ) {

    if( typeof( this.formValues['heldValues'] ) === 'undefined' ) {
      return false;
    }

    if( typeof( this.formValues['heldValues'][ questionName ] ) === 'undefined' ) {
      return false;
    }

    if( this.formValues['heldValues'][ questionName ] === false ) {
      return false;
    }

    return true;

  }

  canGoForward() {

    if( this.currentQuestionPage !== this.questions.length - 1 ) {
      return true;
    }

    return false;

  }

  canGoBack() {

    if( this.currentQuestionPage !== 0 ) {
      return true;
    }

    return false;

  }

  nextPage() {

    if( ! this.validateForm() ) {
      return;
    }

    if( this.canGoForward() ) {
      this.currentQuestionPage++;
    }

    this.setProgress();

  }

  previousPage() {

    if( this.canGoBack() ) {
      this.removeValidation();
      this.currentQuestionPage--;
    }

    this.setProgress();

  }

  goToQuestionPage( atts ) {

    //console.log( atts );

    if( typeof( atts['name'] ) !== 'undefined' ) {

      for( var i = 0; i < this.questions.length; i++ ) {
        if( this.questions[ i ]['name'] === atts['name'] ) {
          this.currentQuestionPage = i;
        }
      }

    }

    if( typeof( atts['i'] ) !== 'undefined' ) {
      this.currentQuestionPage = atts['i'];
    }

    this.setProgress();

  }

  goToRoute( atts ) {

    if( typeof( atts['name'] ) === 'undefined' ) {
      return false;
    }

    this.router.navigate([ atts['name'] ]);

  }

  setProgress() {
    this.progress = ( this.currentQuestionPage / ( this.questions.length - 1 ) ) * 100;
    this.getHelpHeading();
  }

  formInline( question ) {

    if( question['type'] !== 'radio' ) {
      return null;
    }

    if( typeof( question['options'] ) === 'undefined' ) {
      return null;
    }

    let yes = false;
    let no = false;

    for( var i = 0; i < question['options'].length; i++ ) {
      let thisOption = question['options'][ i ];
      if( thisOption['label'] === 'Yes' ) { yes = true; }
      if( thisOption['label'] === 'No' ) { no = true; }
    }

    if( yes && no ) {
      return 'form-check-inline';
    }

    return null

  }

  drawCanvas() {

    this.canvas.toArray().forEach(
      (item) => {

        //console.log( item );

        let font = new FontFaceObserver( 'Qwigley' );

        font.load().then(
          (resp) => {

            let canvas = document.getElementById( item.nativeElement.id ) as HTMLCanvasElement;
            let ctx: CanvasRenderingContext2D = item.nativeElement.getContext( '2d' );

            ctx.clearRect( 0 , 0 , 500 , 500 );

            var image = new Image();

            image.src = this.formValues[ item.nativeElement.id ];

            image.onload = function() {
              ctx.drawImage( image , 0 , 0 );
            };

          }
        );

      }
    );

  }

  setCanvas( source ) {

    this.canvas.toArray().forEach(
      (item) => {

        let id = source.replace( '_canvas' , '_image_url' );

        if( item.nativeElement.id !== id ) {
          return;
        }

        let font = new FontFaceObserver( 'Qwigley' );

        font.load().then(
          (resp) => {

            let canvas = document.getElementById( id ) as HTMLCanvasElement;
            let ctx: CanvasRenderingContext2D = item.nativeElement.getContext( '2d' );

            ctx.clearRect( 0 , 0 , 500 , 500 );

            ctx.font = '52px Qwigley';
            ctx.fillText( this.questions[ this.currentQuestionPage ]['formGroup'].get( source ).value , 10 , 50 );

            this.formValues[ id ] = canvas.toDataURL();

          }
        );

      }
    );

  }


  /**
  *
  * Component specific functions
  *
  * The following are specific form functions that may be executed at the end of any particular form page
  *
  * @todo move these to the components that are responsible for them
  *
  **/

  checkEmail( atts ) {

    this.loading = true;

    return this.accountManagementService.emailExists( this.formValues ).subscribe(

      resp => {

        this.loading = false;

        if( ! resp['result'] ) {
          return this.currentQuestionPage++;
        }

        return this.currentQuestionPage = this.currentQuestionPage + 2;

      },

      error => {
        this.loading = false;
      }

    );

  }

  registerAndSubmit() {

    this.loading = true;

    return this.accountManagementService.register( this.formValues ).subscribe(

      resp => {
        return this.servicesService.createService( 'lw-will' , this.formValues , this.sendData ).subscribe(

          resp => {
            this.router.navigate( ['/user-home'] , { queryParams: { status: 'done' } } );
            return;
          },

          error => {
            this.loading = false;
          }

        );
      },

      error => {
        this.loading = false;
      }

    );

  }

  saveChanges( atts ) {

    this.loading = true;

    return this.servicesService.updateService( atts['type'] , atts['id'] , this.formValues , this.sendData ).subscribe(

      resp => {
        this.loading = false;
        this.modalClose.updateGlobalRef( 'close' );
      },

      error => {
        this.loading = false;
      }

    );

  }

  saveOnly( atts ) {

    this.loading = true;

    return this.servicesService.updateService( atts['type'] , atts['id'] , this.formValues , this.sendData ).subscribe(

      resp => {
        this.loading = false;
        this.modalClose.updateGlobalRef( 'saved' );
      },

      error => {
        this.loading = false;
      }

    );

  }

  updateServicePart( atts ) {

    this.loading = true;

    return this.servicesService.updateServicePart( atts['type'] , atts['id'] , this.formValues , this.sendData ).subscribe(

      resp => {
        this.loading = false;
        this.modalClose.updateGlobalRef({
          action: 'closePartModal',
          object: false,
        });
      },

      error => {
        this.loading = false;
      }

    );

  }

  createServicePart( atts ) {

    this.loading = true;

    return this.servicesService.createServicePart( atts['type'] , this.formValues , this.sendData ).subscribe(

      resp => {
        this.loading = false;
        this.modalClose.updateGlobalRef({
          action: 'closePartModal',
          object: resp,
        });
      },

      error => {
        this.loading = false;
      }

    );

  }

  evaluateHiddenField( atts ) {

    let targetField = atts['field'];
    let ignoreList = atts['ignoreList'];
    let outcome = false;

    let debug = {}

    this.formValues[ targetField ] = false;

    //loop the potential outcomes
    for( var i = 0; i < atts['outcomes'].length; i++ ) {
      let thisOutcome = atts['outcomes'][ i ];
      let thisSpecificChecks = thisOutcome['specificChecks'];
      let thisPotentialValue = thisOutcome['value'];
      let defaultCheck = thisOutcome['defaultCheck'];

      debug[ thisPotentialValue ] = {}

      //loop all our formValues
      for( var fieldName in this.formValues ) {
        let thisFieldValue = this.formValues[ fieldName ];

        //exclude the field we are updating
        if( fieldName === targetField ) {
          continue;
        }

        //exclude fields in the ignoreList
        if( ignoreList.indexOf( fieldName ) !== -1 ) {
          outcome = thisPotentialValue;
          debug[ thisPotentialValue ][ fieldName ] = 'Is on the ignore list';
          continue;
        }

        //check specific checks, continue if OK
        if( typeof( thisSpecificChecks[ fieldName ] ) !== 'undefined' && ( thisFieldValue == thisSpecificChecks[ fieldName ] || thisSpecificChecks[ fieldName ] === 'any' ) ) {
          outcome = thisPotentialValue;
          debug[ thisPotentialValue ][ fieldName ] = 'Passed a specific check.';
          continue;
        }

        //if the default check is any we'll pick this up here
        if( typeof( thisSpecificChecks[ fieldName ] ) === 'undefined' && defaultCheck === 'any' ) {
          outcome = thisPotentialValue;
          debug[ thisPotentialValue ][ fieldName ] = 'Passed the default check, which allows any value.';
          continue;
        }

        //check against the default outcome, only if this field doesn't have a specific check, or it may have already failed
        if( typeof( thisSpecificChecks[ fieldName ] ) === 'undefined' && thisFieldValue === defaultCheck ) {
          outcome = thisPotentialValue;
          debug[ thisPotentialValue ][ fieldName ] = 'Passed the default check, which only allows the value: ' + defaultCheck + '.';
          continue;
        }

        debug[ thisPotentialValue ][ fieldName ] = {
          outcome: 'Did not pass any of the allowed checks',
          ignore: ignoreList.indexOf( fieldName ),
          specificChecks: typeof( thisSpecificChecks[ fieldName ] ),
          defaultCheck: defaultCheck,
        }

        //if we're here, we can't have met all the criteria
        outcome = false;
        break;

      }

      debug[ thisPotentialValue ]['outcome'] = outcome;

      //console.log( debug );
      //console.log( outcome );

      //now we've checked this outcome against all our fields, we should check if we have an outcome
      //i.e. if there are two possible valid outcomes the first found will be selected
      if( outcome ) {
        break;
      }

    }

    //now we've checked all the outcomes we're going to check, if we haven't found an outcome then there's a mis-config
    if( ! outcome ) {
      alert( 'Unable to determine the outcome of this form.' );
      return;
    }

    //otherwise we set the field value and move on
    this.formValues[ targetField ] = outcome;
    this.goToQuestionPage({
      name: outcome,
    });

    return;

  }

  updateObjectValue( key: string , value: any ) {

    let thisFormGroup = this.questions[0]['formGroup'];
    let updateValue = {}

    updateValue[ key ] = value;

    thisFormGroup.patchValue( updateValue );

    //console.log( this.questions );

  }

  /**
  *
  * File upload handlers
  *
  **/

  showFiles() {

    let files = '';

    for( let i = 0; i < this.files.length; i++ ) {
      files += this.files[ i ].name;
       if( ! ( this.files.length - 1 === i )) {
         files += ',';
      }
    }

    return files;

   }

   startUpload( questionName: string = 'fileUploadInput' ): void {

     var formData = new FormData();
     formData.append( 'file' , this.files[0].nativeFile , this.files[0].name );
     formData.append( 'post' , this.object['service_parent'] );

     this.loading = true;
     this.currentUpload = questionName;

     this.serverService.upoadMedia( this.uploadInput , formData ).subscribe(
       resp => {

         //console.log( resp );
         //console.log( this.currentUpload );

         let url = resp['source_url'];
         this.formValues['heldValues'][ this.currentUpload ] = url;

         this.files = [];
         this.loading = false;
         this.currentUpload = '';

       },
       error => {
         this.loading = false;
       },
     );

   }

  cancelUpload( id: string ): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  onUploadOutput( output: UploadOutput | any ): void {

    if( output.type === 'addedToQueue' ) {
      this.files.push( output.file );
    }

    this.showFiles();

  }

}

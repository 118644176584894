import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, skipWhile } from 'rxjs/operators';

import { WpLoginService } from '../../angular-wordpress-rest-api/services/wp-login/wp-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  loggedIn: any;

  constructor(
    public router: Router,
    private wpLoginService: WpLoginService,
  ) { }

  canActivate() {

    let token = localStorage.getItem( 'userToken' );

    if( typeof( token ) !== 'string' ) {
      this.router.navigate(['/login']);
      return false;
    }

    return this.wpLoginService.currentLoginStatus.pipe(

      skipWhile( resp => resp === 'unknown' ),

      map( resp => {

        if( resp !== 'logged-in' ) {
          this.router.navigate(['login']);
          return false;
        }

        return true;

      }),

      catchError( () => {
        this.router.navigate(['login']);
        localStorage.clear();
        return of( false );
      }),

    );

  }

}

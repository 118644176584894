import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';

import { HttpResponse } from '@angular/common/http';

import { WpQueryService } from '../../../angular-wordpress-rest-api/services/wp-query/wp-query.service';
import { WpSaveService } from '../../../angular-wordpress-rest-api/services/wp-save/wp-save.service';
import { WpUserService } from '../../../angular-wordpress-rest-api/services/wp-user/wp-user.service';

import { DialogueModalService } from '../../../services/dialogue-modal/dialogue-modal.service';
import { GlobalCommsService } from '../../../services/global-comms/global-comms.service';
import { ActionService } from '../../../services/action-service/action-service.service';
import { EventsService } from '../../services/events/events.service';

import { WpUser } from '../../../angular-wordpress-rest-api/classes/wp-user/wp-user';
import { WpObject } from '../../../angular-wordpress-rest-api/classes/wp-object/wp-object';
import { WpQuery } from '../../../angular-wordpress-rest-api/classes/wp-query/wp-query';

@Component({
  selector: 'app-standard-table',
  templateUrl: './standard-table.component.html',
  styleUrls: ['./standard-table.component.scss']
})
export class StandardTableComponent implements OnInit, OnDestroy {

  @Input() tab: Object[];

  public requestStore: HttpResponse<WpObject>;
  public referrals: WpObject[];
  public loading: boolean;
  public disableActions: boolean;
  public currentQuery: WpQuery;
  public totalPages: any;
  public pageItems: any[];
  public totalCount: any;
  public totalValue: any;
  private uniqueTag: string;

  private dialogueListener: Subscription;
  private actionListener: Subscription;

  public suppliers: Object[];
  public branches: Object[];

  public currentUser: WpUser;
  public currentUserRoles: string[];

  constructor(
    public router: Router,
    public modalService: MDBModalService,
    public wpQueryService: WpQueryService,
    public wpSaveService: WpSaveService,
    private wpUserService: WpUserService,
    private dialogueModalService: DialogueModalService,
    public globalCommsService: GlobalCommsService,
    public actionService: ActionService,
    public eventsService: EventsService,
  ) {

    this.loading = true;
    this.disableActions = false;

    this.referrals = [];
    this.totalPages = 0;
    this.pageItems = [];
    this.totalCount = 0;
    this.totalValue = 0;

    this.suppliers = [
      { value: 0 , label: 'Show All Suppliers' },
    ];
    this.branches = [
      { value: 0 , label: 'Show All Branches' },
    ];

    this.currentUserRoles = [];

  }

  ngOnInit() {

    this.dialogueListener = this.dialogueModalService.currentDialogueResponse.subscribe(
      resp => {

        if( typeof( resp['key'] ) === 'undefined' || resp['key'] === 'clear' ) {
          return;
        }

        if( resp['data']['sendingComponent'] !== this.uniqueTag ) {
          return;
        }

        let methodName = resp['key']
        let object = resp['object'];

        return ( resp['value'] !== 0 ? this.runMethod( methodName , object ) : false );

      }
    );

    this.actionListener = this.globalCommsService.currentRef.subscribe(
      resp => {

        if( typeof( resp['action'] ) === 'undefined' || resp['action'] === 'none' ) {
          return;
        }

        if( typeof( resp['object'] ) === 'undefined' ) {
          this.maybeWarnMethod( { method: resp['action'] } , resp['object'] );
        }

        if( typeof( resp['object'] ) !== 'undefined' ) {
          this.maybeWarnMethod( { method: resp['action'] } , {} );
        }

        this.globalCommsService.updateGlobalRef({
          action: 'none',
        });

      }
    );

    this.globalCommsService.globalData.subscribe(
      resp => {

        if( typeof( resp['suppliers'] ) !== 'undefined' ) {
          for( let supplier of resp['suppliers'] ) {
            this.suppliers.push({
              value: supplier['id'],
              label: supplier['title']['rendered'].replace( '&#038;' , '&' ),
            });
          }
        }

        if( typeof( resp['branches'] ) !== 'undefined' ) {
          for( let branch of resp['branches'] ) {
            this.branches.push({
              value: branch['id'],
              label: branch['title']['rendered'].replace( '&#038;' , '&' ),
            });
          }
        }

      }
    );

    this.wpUserService.currentUser.subscribe(
      resp => {

        if( typeof( resp['roles'] ) === 'undefined' || resp['roles'].length === 0 ) {
          return;
        }

        this.currentUser = resp;
        this.currentUserRoles = resp['roles'];

      }
    );

    this.uniqueTag = this.tab['slug'] + '-' + Math.random().toString( 36 ).replace( /[^a-z]+/g , '' );
    this.loadReferrals( this.tab['query'] );
    this.init();

  }

  init() {

  }

  ngOnDestroy() {
    this.dialogueListener.unsubscribe();
    this.actionListener.unsubscribe();
  }

  loadReferrals( query: WpQuery , showLoading: boolean = true ): void {

    this.currentQuery = query;

    if( showLoading ) {
      this.loading = true;
    }

    this.wpQueryService.getPosts( query ).subscribe(
      resp => {

        this.requestStore = resp;
        this.totalPages = parseInt( resp.headers.get( 'x-wp-totalpages' ));
        this.totalCount = resp.headers.get( 'x-wp-total' );
        this.totalValue = 0;

        this.referrals = resp['body'];

        this.pageItems = [];

        for( var i = 0; i < this.totalPages; i++ ) {
          this.pageItems.push( i + 1 );
        }

        this.loading = false;
        this.disableActions = false;

      }
    );

  }

  refreshItems( showLoading: boolean = false , resetPages: boolean = false ) {
    this.currentQuery['pageNumber'] = ( resetPages ? 1 : this.currentQuery['pageNumber'] );
    this.loadReferrals( this.currentQuery , showLoading );
  }

  searchRecords(): void {
    this.currentQuery['pageNumber'] = 1;
    this.loadReferrals( this.currentQuery );
  }

  addFilters(): void {
    this.searchRecords();
  }

  clearSearch() {

    if( typeof( this.currentQuery['args']['s'] ) === 'undefined' ) {
      return;
    }

    this.currentQuery['pageNumber'] = 1;
    this.currentQuery['args']['s'] = '';

    this.loadReferrals( this.currentQuery );

  }

  onPageChange( page: number ) {
    this.currentQuery['pageNumber'] = page;
    this.loadReferrals( this.currentQuery );
  }

  maybeWarnMethod( action: Object , object: WpObject ): any {

    let methodName = action['method'];
    let warningTitle = ( typeof( action['warningTitle'] ) !== 'undefined' ? action['warningTitle'] : false );
    let warning = ( typeof( action['warning'] ) !== 'undefined' ? action['warning'] : false );
    let fields = ( typeof( action['fields'] ) !== 'undefined' ? action['fields'] : [] );

    if( ! warning || ! warningTitle ) {
      return this.runMethod( methodName , object );
    }

    let modalOptions = {
      data: {
        key: methodName,
        heading: warningTitle,
        text: warning,
        object: object,
        fields: fields,
        data: {
          sendingComponent: this.uniqueTag,
        },
        options: [
          {
            type: 'primary',
            text: 'Confirm',
            value: 1,
          },
          {
            type: 'danger',
            text: 'Cancel',
            value: 0,
          },
        ]
      }
    }

    return this.dialogueModalService.openDialogueModal( modalOptions );

  }

  runMethod( methodName: string , object: WpObject ): any {

    if( typeof( this[ methodName] ) !== 'function' ) {
      //console.log( 'Invalid method name: ' + methodName );
      return;
    }

    return this[ methodName ]( object );

  }

  removeFromReferrals( object: WpObject ): void {

    for( let key in this.referrals ) {

      let thisReferral = this.referrals[ key ];

      if( object.id === thisReferral.id ) {
        this.referrals.splice( parseInt( key ) , 1 );
        this.disableActions = false;
      }

    }

    this.globalCommsService.updateGlobalRef({
      action: 'refreshItems',
    });

  }

  showEvents( parent: WpObject ) {
    this.eventsService.openModal( parent );
  }

}

<app-header></app-header>

<div *ngIf="loading" class="wig-will-writer-iframe-container loading-container">
  <img src="assets/loading.gif" />
</div>

<div class="mt-5 pt-3 mb-2">

  <div *ngIf="! loading" class="wig-will-writer-iframe-container">
    <seb-ng-magic-iframe #iframe class="wig-will-writer-iframe" [source]="case['lw_ww_url']">
      <div class="wig-will-writer-iframe-container loading-container">
        <img src="assets/loading.gif" />
      </div>
    </seb-ng-magic-iframe>
  </div>

  <div class="wig-powered-by-absolute">
    <app-powered-by-lm></app-powered-by-lm>
  </div>

</div>

<app-footer></app-footer>

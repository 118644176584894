<div *ngIf="! showSummaryOnly()" class="row">
  <div class="col-md-12">

    <div class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
      <h2 class="card-title h2">Your Funeral Arrangements</h2>
      <p>Planning a funeral when you've just lost a loved one can be a difficult task, you can make this easier on your family by providing instructions on
        how you'd likey you're funeral to be carried out and what you'd like to happen to your body.</p>
    </div>

    <app-service-completion [service]="object"></app-service-completion>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <app-master-form [object]="object" [key]="key" [extraData]="extraData"></app-master-form >
      </div>
      <div class="col-md-3"></div>
    </div>

  </div>
</div>

<div *ngIf="showSummaryOnly()">
  <p><strong>When I die I would like to be:</strong> {{ object['body_options'] }}</p>
  <p><strong>Please also take note of the following information:</strong> {{ object['other_funeral_details'] }}</p>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row">
      <div class="col-sm-12">

        {{ item['item_name'] }}

      </div>
    </div>

  </div>
</app-service-child-list>

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Debts</h2>
  <p>Your next of kin will need to make contact with any companies you have debts with.
    This is so that debts can be paid with any available money from your estate.</p>
  <p>You can make this easier for them by listing the details of all these companies below</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-4">
          Creditor Name
        </div>
        <div class="col-sm-4">
          Type
        </div>
        <div class="col-sm-4">
          Account Number
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-4">
        {{ item['creditor_name'] }}
      </div>
      <div class="col-sm-4">
        {{ item['debt_type'] }}
      </div>
      <div class="col-sm-4">
        {{ item['account_number'] }}
      </div>
    </div>

  </div>
</app-service-child-list>

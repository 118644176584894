import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MDBModalRef, CollapseComponent } from 'ng-uikit-pro-standard';

import { WpObject } from '../../angular-wordpress-rest-api/classes/wp-object/wp-object';
import { PipelineTab } from '../../angular-mdb-pipeline/classes/pipeline-tab/pipeline-tab';

import { GlobalCommsService } from '../../services/global-comms/global-comms.service';
import { ActionService } from '../../services/action-service/action-service.service';

@Component({
  selector: 'app-single-referral',
  templateUrl: './single-referral.component.html',
  styleUrls: ['./single-referral.component.scss']
})
export class SingleReferralComponent implements OnInit {

  @Input() referral: WpObject;
  @ViewChild( 'addNewService' ) addNewServiceCollapse: CollapseComponent;

  public referralTab: PipelineTab;
  public eventTab: PipelineTab;
  public otherReferralsTab: PipelineTab;

  public newServiceType: string;
  public serviceSelectOptions: Object[];
  public disableActions: boolean;

  constructor(
    public modalRef: MDBModalRef,
    private globalCommsService: GlobalCommsService,
    private actionService: ActionService,
  ) {

    this.disableActions = false;

    this.serviceSelectOptions = [
      { value: 'lw-will' , label: 'New Will' },
      { value: 'request-a-review' , label: 'Will Review' },
      { value: 'request-an-update' , label: 'Will Update' },
      { value: 'store-a-will' , label: 'Will Storage' },
      { value: 'lpa-referral' , label: 'LPA' },
    ]

  }

  ngOnInit(): void {

    this.referralTab = {
      title: 'Services',
      slug: 'supplier-services',
      total: 0,
      count: 0,
      actions: [],
      allowedRoles: [
        'administrator',
        'supplier',
      ],
      view: 'referral',
      query: {
        postType: 'services',
        pageNumber: 1,
        args: {
          status: 'private',
          child_type: 'service',
          referral_parent: this.referral.id,
          //_fields: 'id,author,author_name,date,supplier_parent_title,price,event_count,product_type,title',
        },
      },
    }

    if( this.canChangeServices()) {
      this.referralTab.actions = [
        {
          label: 'Move to New Referral',
          type: 'default',
          method: 'moveToNewReferral',
          warningTitle: 'Move this service to a new referral?',
          warning: 'Are you sure you want to move this service to a new referral? You will no longer be able to enter billing information for this service on this referral.',
          allowedRoles: [
            'supplier'
          ],
          allowedStatus: [
            'supplier-services'
          ]
        },
        {
          label: 'Remove from Referral',
          type: 'danger',
          method: 'removeService',
          warningTitle: 'Remove this service?',
          warning: 'Are you sure you want to remove this service from the current referral?',
          allowedRoles: [
            'supplier'
          ],
          allowedStatus: [
            'supplier-services'
          ]
        },
      ]
    }

    this.eventTab = {
      title: 'Notes and Events',
      slug: 'notes-events',
      total: 0,
      count: 0,
      view: 'quotes',
      filters: [],
      actions: [],
      allowedRoles: [
        'administrator',
        'supplier',
      ],
      query: {
        postType: 'events',
        pageNumber: 1,
        args: {
          status: 'private',
          child_type: 'event',
          service_part_parent: this.referral.id,
        },
      },
    }

    this.otherReferralsTab = {
      title: 'Other Referrals for this Customer',
      slug: 'otherReferrals',
      total: 0,
      count: 0,
      view: 'quotes',
      filters: [],
      actions: [],
      allowedRoles: [
        'administrator',
        'supplier',
      ],
      query: {
        postType: 'referrals',
        pageNumber: 1,
        args: {
          status: 'private',
          child_type: 'referral',
          service_part_parent: this.referral['supplier_parent'],
          exclude: this.referral.id,
          author: this.referral['author'],
        },
      },
    }

  }

  addServiceToReferral( type: string ) {

    this.disableActions = true;

    this.actionService.addServiceToReferral( this.referral , type ).subscribe(
      resp => {

        this.disableActions = false;
        this.addNewServiceCollapse.toggle();

        this.globalCommsService.updateGlobalRef({
          action: 'refreshItems',
        });

        this.globalCommsService.updateGlobalRef({
          action: 'none',
        });

      },
      error => {
        this.disableActions = false;
      },
    );

  }

  canChangeServices(): boolean {
    return [ 'pending' , 'live' ].indexOf( this.referral['referral_status'] ) !== -1;
  }

}

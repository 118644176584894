<div class="modal-content" style="max-height: 100vh; overflow-y: scroll; overflow-x: hidden;">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title text-capitalize w-100" id="myModalLabel">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <ng-template app-content-loader></ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="default" class="waves-light" aria-label="Close" (click)="modalRef.hide()" mdbWavesEffect>Close</button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { WpConfigService } from '../wp-config/wp-config.service';
import { WpCacheService } from '../wp-cache/wp-cache.service';
import { WpServerService } from '../wp-server/wp-server.service';

import { AppConfig } from '../../classes/app-config/app-config';
import { WpQuery } from '../../classes/wp-query/wp-query';
import { WpObject } from '../../classes/wp-object/wp-object';

@Injectable({
  providedIn: 'root'
})

export class WpQueryService {

  private config: AppConfig;

  constructor(
    private wpConfigService: WpConfigService,
    private wpCacheService: WpCacheService,
    private wpServerService: WpServerService,
  ) {

    this.wpConfigService.currentAppConfig.subscribe(
      resp => {

        if( resp === {} ) {
          return;
        }

        this.config = resp;

      }
    );

  }

  getPosts( query: WpQuery , nameSpace?: string ): Observable<HttpResponse<any>> {

    let url = this.generateQueryUrl( query );
    let getCachedValue = ( query.skipCache ? false : this.getCachedValue( query , url ));

    if( getCachedValue ) {
      return getCachedValue;
    }

    return this.wpServerService.executeWpQuery( url , nameSpace ).pipe(
      tap( resp => {
          this.cacheRequest( query , url , resp );
      })
    );

  }

  generateQueryUrl( query: WpQuery ): string {

    let url = ( typeof( query.id ) === 'undefined' ? query.postType : query.postType + '/' + query.id );
    url = url + '?' + this.generateQueryString( query );

    url = ( url[ url.length -1 ] === '?' ? url.substring( 0 , url.length - 1 ) : url );

    return url;

  }

  generateQueryString( query: WpQuery ): string {

    let queryString = '';

    if( typeof( query.pageNumber ) !== 'undefined' && query.pageNumber !== 1 ) {
      queryString += 'page=' + query.pageNumber + '&';
    }

    if( typeof( query.args ) !== 'undefined' ) {
      queryString += this.processArgs( query.args );
    }

    return queryString.substring( 0 , queryString.length - 1 );

  }

  processArgs( query: Object ): string {

    let queryString = '';

    for( var key in query ) {
      queryString += key + '=' + query[ key ] + '&';
    }

    return queryString;

  }

    cacheRequest( query: WpQuery , url: string , results: HttpResponse<any> ): void {
      return this.wpCacheService.cacheRequest( query , url , results );
    }

    getCachedValue( query: WpQuery , url: string ): any {
      return this.wpCacheService.getCachedValue( query , url );
    }

}

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Nominated Person(s)</h2>
  <p>You can nominate specific people that you would like to take care of things when you're gone. </p>
  <p>Use the list below to add people that you'd like to deal with these things for you.</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-6">
        <strong>{{ item['nominated_persons_name'] }}</strong>
      </div>
      <div class="col-sm-6">
        <small>{{ item['nominated_persons_email_address'] }}</small><br />
        <small>{{ item['nominated_persons_contact_number'] }}</small>
      </div>
    </div>

  </div>
</app-service-child-list>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AccountManagementService } from '../services/account-management/account-management.service';
import { GlobalCommsService } from '../services/global-comms/global-comms.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  sent: boolean;
  sentMessage: string;

  loading: Boolean;

  constructor(
    public accountManagementService: AccountManagementService,
    public router: Router,
    private globalCommsService: GlobalCommsService,
  ) {
    this.sent = false;
  }

  ngOnInit() {
    this.globalCommsService.updateGlobalRef( 'stop-global-loading' );
  }

  resetPassword() {

    this.loading = true;

    this.accountManagementService.resetPassword({
      email: this.email,
    }, [ 'email'] ).subscribe(

      resp => {

        this.loading = false;

        if( resp['status'] !== 200 ) {
          alert( resp['message'] );
          return;
        }

        this.sent = true;
        this.sentMessage = resp['message'];

      },

      error => {
        this.loading = false;
      }

    );

  }

}

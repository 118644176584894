<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Request to Store a Will</h4>
  </div>
  <div class="modal-body">

    <div class="p-5">

      <div class="row">
        <div class="col-md-12">

          <p>Below are the files that have been sent with this request</p>
          <p>You can request a temporary link to any files listed below. Download links will only be valid for 15mins</p>

          <div *ngIf="attachments && attachments.length !== 0 && ! loading" class="list-group list-group-flush">
            <hr />

            <div *ngFor="let attachment of attachments" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

              <div class="flex-fill">

                <div class="row">
                  <div class="col-sm-12">
                    {{ attachment.title['rendered'] }}
                  </div>
                </div>

              </div>

              <div class="flex-fill">

                <div class="row">
                  <div class="col-sm-12">
                    {{ attachment['mime_type'] }}
                  </div>
                </div>

              </div>

              <div style="min-width: 188px;">

                <button (click)="requestDownloadLink( attachment )" class="indigo lighten-1 btn-block white-text mb-1" mdbBtn type="button" size="sm" mdbWavesEffect [disabled]="itemIsLoading( attachment )">
                  <span *ngIf="! canDownload( attachment )">Request Download Link</span>
                  <span *ngIf="canDownload( attachment )">Refresh Download Link</span>
                </button>

                <a [href]="attachment['encrypted_file_url']" target="_blank" download *ngIf="canDownload( attachment )"  class="btn-default btn-block" mdbBtn type="button" size="sm" mdbWavesEffect [disabled]="itemIsLoading( attachment )">
                  <span>Download File</span>
                </a>

              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

</div>

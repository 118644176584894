import { Component, OnInit, Input } from '@angular/core';

import { ApiFieldsService } from '../../../services/api-fields/api-fields.service';
import { ServicesService } from '../../../services/services/services.service';

@Component({
  selector: 'app-donor-information',
  templateUrl: './donor-information.component.html',
  styleUrls: ['./donor-information.component.scss']
})
export class DonorInformationComponent implements OnInit {

  @Input() object: Object;
  @Input() summaryOnly: boolean = false;
  public key: string;
  public extraData: Object;

  public description: string;
  public subDescription: string;

  public selectOptions: Object[];

  constructor(
    public apiFieldsService: ApiFieldsService,
    public servicesService: ServicesService,
  ) {
    this.selectOptions = [
      { label: 'Yes' , value: 'yes' },
      { label: 'No' , value: 'no' },
    ]
  }

  ngOnInit() {
    this.key = 'donor';
    this.extraData = {}
  }

  updateDonorChoice( event ) {

    if( typeof( this.object['donate_your_organs'] ) === 'undefined' ) {
      return;
    }

    this.servicesService.updateService( this.key , this.object['id'] , {
      donate_your_organs: this.object['donate_your_organs'],
    },[
      'donate_your_organs',
    ]).subscribe();

  }

  showDonorOption(): boolean {
    return ( this.object['donate_your_organs'] === 'yes' ? true : false );
  }

  getYesNoLabels( key: string ): string {

    let value = this.object[ key ];

    if( typeof( value ) === 'undefined' || ! value ) {
      return 'No';
    }

    return 'Yes';

  }

  showSummaryOnly() {

    if( typeof( this.object ) === 'undefined' ) {
      return true;
    }

    return this.summaryOnly;

  }

}

<app-header></app-header>

<div class="mt-5 mb-5">
  <div class="p-sm-5 p-3">

    <div class="row">
      <div class="col-md-6">
        <h1 class="mb-4">Checkout</h1>
      </div>
      <div class="col-md-6">
        <div class="float-right">
        </div>
      </div>
    </div>

    <div class="row">

      <!--Rotating card-->
      <div class="col-lg-4 col-xl-3">

        <div class="d-lg-none">
          <mdb-accordion [multiple]="false" aria-multiselectable="true">
            <mdb-accordion-item  [collapsed]="true">
              <mdb-accordion-item-head>View Profile</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <app-user-profile></app-user-profile>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>

        <div class="d-none d-lg-block">
          <app-user-profile></app-user-profile>
        </div>

      </div>
      <!--/.Rotating card-->

      <div class="col-lg-8 col-xl-9">

        <!-- Form -->
        <form style="color: #757575;" (ngSubmit)="pay()" #i="ngForm">

          <div class="row">
            <div class="col-md-6">

              <div class="row">
                <div class="col-md-12">
                  <!--Card-->
                  <div class="card card-cascade narrower">
                    <!--Card content-->
                    <div class="card-body">
                      <h3 class="text-left mb-4"><strong>Billing Details</strong></h3>

                        <div class="form-row">
                          <div class="col">
                            <!-- First name -->
                            <div class="md-form">
                              <input [(ngModel)]="billingDetails['first_name']" name="first_name" type="text" id="materialRegisterFormFirstName" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                              <label for="materialRegisterFormFirstName">First name *</label>
                            </div>
                          </div>
                          <div class="col">
                            <!-- Last name -->
                            <div class="md-form">
                              <input [(ngModel)]="billingDetails['last_name']" name="last_name" type="email" id="materialRegisterFormLastName" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                              <label for="materialRegisterFormLastName">Last name *</label>
                            </div>
                          </div>
                        </div>

                        <div class="md-form mt-0">
                          <input [(ngModel)]="billingDetails['address_1']" name="address_1" type="text" id="materialRegisterAddress1" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                          <label for="materialRegisterAddress1">Address Line 1 *</label>
                        </div>

                        <div class="md-form mt-0">
                          <input [(ngModel)]="billingDetails['address_2']" name="address_2" type="text" id="materialRegisterAddress2" class="form-control" #input="ngModel" mdbInput mdbValidate>
                          <label for="materialRegisterAddress2">Address Line 2</label>
                        </div>

                        <div class="md-form mt-0">
                          <input [(ngModel)]="billingDetails['city']" name="city" type="text" id="materialRegisterFormCity" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                          <label for="materialRegisterFormCity">Town / City *</label>
                        </div>

                        <div class="md-form mt-0">
                          <mdb-select [(ngModel)]="billingDetails['country']" name="country" id="country" [options]="countries" name="country" placeholder="Select Country"></mdb-select>
                        </div>

                        <div class="md-form mt-0">
                          <input [(ngModel)]="billingDetails['postcode']" name="postcode" type="text" id="materialRegisterFormPostCode" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                          <label for="materialRegisterFormPostCode">Post Code *</label>
                        </div>

                        <div class="md-form mt-0">
                          <input [(ngModel)]="billingDetails['email']" name="email" type="email" id="materialRegisterFormEmail" class="form-control" required #input="ngModel" mdbInput mdbValidate>
                          <label for="materialRegisterFormEmail">Email Address *</label>
                        </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <!--Card-->
                  <div class="card card-cascade narrower">
                    <!--Card content-->
                    <div class="card-body">
                      <h3 class="text-left mb-4"><strong>Your Order</strong></h3>

                      <div class="row">
                        <div class="col-sm-12">

                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Simple Will - For you and your partner <strong> x1</strong></td>
                                <td>£19.99</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">

                          <div *ngIf="env.stripeTest">
                            You are currently checking out in test mode. Use the following card details to checkout:
                            <ul>
                              <li>Card: 4000 0082 6000 0000</li>
                              <li>Expiry: 01/22</li>
                              <li>CCV2: 200</li>
                              <li>Postcode: AB1 2CD</li>
                            </ul>
                          </div>

                          <div class="md-form">
                            <div mdbInput mdbCreditCard required #card="mdbCreditCard" id="card-number" style="border-bottom: 1px solid #ced4da;" type="text"></div>
                          </div>

                          <div class="form-row">
                            <mdb-checkbox [(ngModel)]="terms" name="terms" required mdbValidate>Please click here to confirm you agree with our Terms & Conditions and Privacy Policy *</mdb-checkbox>
                            <mdb-checkbox [(ngModel)]="mailing" name="mailing" required mdbValidate>Please click here if we can add you to our mailing list *</mdb-checkbox>
                          </div>

                        </div>
                      </div>

                      <button style="width: 100%; display: block;" type="submit" class="btn btn-default mt-4" block="true" [disabled]="! i.valid || loading">
                        <span *ngIf="! loading">Place Order</span>
                        <span *ngIf="loading">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </span>
                      </button>

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </form>

      </div>

    </div>

  </div>
</div>

<app-footer></app-footer>

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { MdbWysiwygModule } from 'mdb-wysiwyg';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { SignaturePadModule } from 'angular2-signaturepad';

import { NgxStripeModule } from 'ngx-stripe';

import { NgMagicIframeModule } from '@sebgroup/ng-magic-iframe';

//AMDBP//
import { StandardTableComponent } from './angular-mdb-pipeline/components/standard-table/standard-table.component';
import { EventsTableComponent } from './angular-mdb-pipeline/components/events-table/events-table.component';
import { PipelineComponent } from './angular-mdb-pipeline/components/pipeline/pipeline.component';
import { EventModalComponent } from './angular-mdb-pipeline/components/event-modal/event-modal.component';
import { PriceFormatterPipe } from './angular-mdb-pipeline/pipes/price-formatter/price-formatter.pipe';
//AMDBP//

import { ServerService } from './server.service';
import { AccountManagementService } from './services/account-management/account-management.service';
import { ApiFieldsService } from './services/api-fields/api-fields.service';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { GlobalCommsService } from './services/global-comms/global-comms.service';

import { ObjectFilterPipe } from './pipes/object-filter.pipe';
import { ObjectArrayFilterPipe } from './pipes/object-array-filter/object-array-filter.pipe';
import { SimpleFilterPipe } from './simple-filter.pipe';

import { ContentLoaderDirective } from './directives/content-loader.directive';

import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { HomeComponent } from './home/home.component';
import { QuotesComponent } from './quotes/quotes.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LoginComponent } from './login/login.component';
import { CartComponent } from './payments/cart/cart.component';
import { CheckoutComponent } from './payments/checkout/checkout.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { OrderComponent } from './payments/order/order.component';
import { FormComponent } from './components/form/form.component';
import { MasterFormComponent } from './parts/master-form/master-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { SimpleWillComponent } from './parts/vault/wills/simple-will/simple-will.component';
import { ServiceChildListComponent } from './parts/service-child-list/service-child-list.component';
import { TestComponent } from './scratch/test/test.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetNewPasswordComponent } from './reset-password/set-new-password/set-new-password.component';
import { SimpleWillActionsComponent } from './parts/vault/wills/simple-will-actions/simple-will-actions.component';
import { PlainTextComponent } from './parts/plain-text/plain-text.component';
import { DonorInformationComponent } from './parts/vault/donor-information/donor-information.component';
import { RantComponent } from './parts/vault/rant/rant.component';
import { BucketComponent } from './parts/vault/bucket/bucket.component';
import { BucketAddEditComponent } from './parts/vault/bucket/parts/add-edit/add-edit.component';
import { WillsDefaultListComponent } from './parts/vault/wills/parts/wills-default-list/wills-default-list.component';
import { VaultAddEditComponent } from './parts/vault/rant/parts/add-edit/add-edit.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NavFilterPipe } from './pipes/nav-filter/nav-filter.pipe';
import { MyLifeComponent } from './parts/vault/my-life/my-life.component';
import { MyLifeAddEditComponent } from './parts/vault/my-life/parts/add-edit/add-edit.component';
import { ObjectSortPipe } from './pipes/object-sort.pipe';
import { LoyaltyCardsComponent } from './parts/vault/loyalty-cards/loyalty-cards.component';
import { LoyaltyAddEditComponent } from './parts/vault/loyalty-cards/parts/add-edit/add-edit.component';
import { PhotoAlbumComponent } from './parts/vault/photo-album/photo-album.component';
import { LmWillComponent } from './parts/vault/lw-will/lw-will.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InitialWillFormComponent } from './parts/initial-will-form/initial-will-form.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { LwWillWriterComponent } from './parts/vault/lw-will/lw-will-writer/lw-will-writer.component';
import { ComingSoonComponent } from './parts/vault/coming-soon/coming-soon.component';
import { PleaseWaitComponent } from './parts/vault/please-wait/please-wait.component';
import { PoweredByLmComponent } from './parts/vault/lw-will/powered-by-lm/powered-by-lm.component';
import { EulogyComponent } from './parts/vault/eulogy/eulogy.component';
import { MusicComponent } from './parts/vault/music/music.component';
import { MusicAddEditComponent } from './parts/vault/music/parts/add-edit/add-edit.component';
import { ReferralTableComponent } from './parts/pipeline-tables/referral-table/referral-table.component';
import { DialogueModalComponent } from './components/dialogue-modal/dialogue-modal.component';
import { LpaComponent } from './parts/vault/lpa/lpa.component';
import { LpaFormComponent } from './parts/vault/lpa/lpa-form/lpa-form.component';
import { SingleReferralComponent } from './parts/single-referral/single-referral.component';
import { ServiceTableComponent } from './parts/pipeline-tables/service-table/service-table.component';
import { ServiceCompletionComponent } from './parts/service-completion/service-completion.component';
import { LwWillReviewComponent } from './parts/vault/lw-will-review/lw-will-review.component';
import { LwWillUpdateComponent } from './parts/vault/lw-will-update/lw-will-update.component';
import { LwWillStoreComponent } from './parts/vault/lw-will-store/lw-will-store.component';
import { LpaFormDataComponent } from './parts/vault/lpa/lpa-form-data/lpa-form-data.component';
import { LwWillStoreDataComponent } from './parts/vault/lw-will-store/lw-will-store-data/lw-will-store-data.component';
import { BankAccountsComponent } from './parts/vault/bank-accounts/bank-accounts.component';
import { LifeInsuranceComponent } from './parts/vault/life-insurance/life-insurance.component';
import { SavingInvestmentComponent } from './parts/vault/saving-investment/saving-investment.component';
import { UtilityComponent } from './parts/vault/utility/utility.component';
import { DebtComponent } from './parts/vault/debt/debt.component';
import { PensionComponent } from './parts/vault/pension/pension.component';
import { MiscItemComponent } from './parts/vault/misc-item/misc-item.component';
import { BankAccountAddEditComponent } from './parts/vault/bank-accounts/add-edit/add-edit.component';
import { LifeInsuranceAddEditComponent } from './parts/vault/life-insurance/parts/add-edit/add-edit.component';
import { MessagesComponent } from './parts/vault/messages/messages.component';
import { SecretsComponent } from './parts/vault/secrets/secrets.component';
import { LoveHateComponent } from './parts/vault/love-hate/love-hate.component';

import { DebtAddEditComponent } from './parts/vault/debt/parts/add-edit/add-edit.component';
import { MiscItemAddEditComponent } from './parts/vault/misc-item/parts/add-edit/add-edit.component';
import { PensionAddEditComponent } from './parts/vault/pension/parts/add-edit/add-edit.component';
import { SavingInvestmentAddEditComponent } from './parts/vault/saving-investment/parts/add-edit/add-edit.component';
import { UtilityAddEditComponent } from './parts/vault/utility/parts/add-edit/add-edit.component';
import { FuneralArrangementsComponent } from './parts/vault/funeral-arrangements/funeral-arrangements.component';
import { MessagesAddEditComponent } from './parts/vault/messages/parts/add-edit/add-edit.component';
import { SecretsAddEditComponent } from './parts/vault/secrets/parts/add-edit/add-edit.component';
import { LoveHateAddEditComponent } from './parts/vault/love-hate/parts/add-edit/add-edit.component';
import { ExecutorAddEditComponent } from './parts/vault/lw-will-store/executor-add-edit/executor-add-edit.component';
import { StandardReferralDetailsComponent } from './parts/standard-referral-details/standard-referral-details.component';
import { StandardReferralDetailsFormComponent } from './parts/standard-referral-details/standard-referral-details-form/standard-referral-details-form.component';
import { ServiceSummaryComponent } from './parts/service-summary/service-summary.component';
import { NominatedPersonsComponent } from './parts/vault/nominated-persons/nominated-persons.component';
import { PersonAddEditComponent } from './parts/vault/nominated-persons/person-add-edit/person-add-edit.component';
import { LpaStoreComponent } from './parts/vault/lpa-store/lpa-store.component';
import { AddEditAttorneyComponent } from './parts/vault/lpa-store/add-edit-attorney/add-edit-attorney.component';
import { DeathInstructionsComponent } from './parts/vault/death-instructions/death-instructions.component';
import { DeathInstructionsAddEditComponent } from './parts/vault/death-instructions/parts/add-edit/add-edit.component';
import { SendNotificationsComponent } from './parts/send-notifications/send-notifications.component';
import { WillFormDataComponent } from './parts/vault/lw-will/will-form-data/will-form-data.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminDashboardComponent,
    HomeComponent,
    QuotesComponent,
    SimpleFilterPipe,
    UserProfileComponent,
    LoginComponent,
    CartComponent,
    CheckoutComponent,
    UserHomeComponent,
    OrderComponent,
    ObjectFilterPipe,
    FormComponent,
    MasterFormComponent,
    ModalComponent,
    SimpleWillComponent,
    ContentLoaderDirective,
    ServiceChildListComponent,
    TestComponent,
    ResetPasswordComponent,
    SetNewPasswordComponent,
    SimpleWillActionsComponent,
    PlainTextComponent,
    DonorInformationComponent,
    RantComponent,
    //MdbWysiwygComponent,
    BucketComponent,
    BucketAddEditComponent,
    WillsDefaultListComponent,
    VaultAddEditComponent,
    SafeHtmlPipe,
    MyLifeComponent,
    MyLifeAddEditComponent,
    ObjectSortPipe,
    NavFilterPipe,
    ObjectArrayFilterPipe,
    LoyaltyCardsComponent,
    LoyaltyAddEditComponent,
    PhotoAlbumComponent,
    LmWillComponent,
    HeaderComponent,
    FooterComponent,
    InitialWillFormComponent,
    UserDashboardComponent,
    LwWillWriterComponent,
    ComingSoonComponent,
    PleaseWaitComponent,
    PoweredByLmComponent,
    EulogyComponent,
    MusicComponent,
    MusicAddEditComponent,
    StandardTableComponent,
    EventsTableComponent,
    PipelineComponent,
    EventModalComponent,
    PriceFormatterPipe,
    ReferralTableComponent,
    DialogueModalComponent,
    LpaComponent,
    LpaFormComponent,
    SingleReferralComponent,
    ServiceTableComponent,
    ServiceCompletionComponent,
    LwWillReviewComponent,
    LwWillUpdateComponent,
    LwWillStoreComponent,
    LpaFormDataComponent,
    LwWillStoreDataComponent,
    BankAccountsComponent,
    BankAccountAddEditComponent,
    LifeInsuranceComponent,
    SavingInvestmentComponent,
    UtilityComponent,
    LifeInsuranceAddEditComponent,
    DebtComponent,
    PensionComponent,
    MiscItemComponent,
    DebtAddEditComponent,
    MiscItemAddEditComponent,
    PensionAddEditComponent,
    SavingInvestmentAddEditComponent,
    UtilityAddEditComponent,
    FuneralArrangementsComponent,
    MessagesComponent,
    MessagesAddEditComponent,
    SecretsComponent,
    SecretsAddEditComponent,
    LoveHateComponent,
    LoveHateAddEditComponent,
    ExecutorAddEditComponent,
    StandardReferralDetailsComponent,
    StandardReferralDetailsFormComponent,
    ServiceSummaryComponent,
    NominatedPersonsComponent,
    PersonAddEditComponent,
    LpaStoreComponent,
    AddEditAttorneyComponent,
    DeathInstructionsComponent,
    DeathInstructionsAddEditComponent,
    SendNotificationsComponent,
    WillFormDataComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    MdbWysiwygModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SignaturePadModule,
    NgxStripeModule.forRoot( 'pk_test_jOGbCDGvgS3aI6MWWB53n1EZ' ),
    DragDropModule,
    NgMagicIframeModule,
    AppRoutingModule,
  ],
  entryComponents: [
    SimpleWillComponent,
    ModalComponent,
    MasterFormComponent,
    ServiceChildListComponent,
    PlainTextComponent,
    DonorInformationComponent,
    RantComponent,
    BucketComponent,
    BucketAddEditComponent,
    VaultAddEditComponent,
    WillsDefaultListComponent,
    MyLifeComponent,
    MyLifeAddEditComponent,
    LoyaltyCardsComponent,
    LoyaltyAddEditComponent,
    PhotoAlbumComponent,
    LmWillComponent,
    ComingSoonComponent,
    PleaseWaitComponent,
    DialogueModalComponent,
    LpaFormComponent,
    SingleReferralComponent,
  ],
  providers: [
    HttpClient,
    MDBModalRef,
    MDBSpinningPreloader,
    ServerService,
    AccountManagementService,
    ApiFieldsService,
    AuthGuardService,
    GlobalCommsService,
    EventModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }

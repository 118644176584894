import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { environment } from '../../../environments/environment';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";

import { ServerService } from '../../server.service';
import { ServicesService } from '../../services/services/services.service';

import { ModalComponent } from '../../components/modal/modal.component';
import { PlainTextComponent } from '../../parts/plain-text/plain-text.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  env = environment;

  elements: Elements;
  card: StripeElement;

  elementsOptions: ElementsOptions = {}
  stripeForm: FormGroup;

  billingDetails: Object = {
    first_name: '',
    last_name: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    email: '',
    phone: '',
  }

  terms: boolean;
  mailing: boolean;

  cart: Object;
  user: Object;

  countries: Array<Object>;

  loading: Boolean;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public serverService: ServerService,
    private stripeService: StripeService,
    public servicesService: ServicesService,
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
  ) {

  }

  ngOnInit() {

    this.initStripe();
    this.initCountries();

    this.cart = JSON.parse( localStorage.getItem( 'cart' ) );

    if( typeof( this.cart ) === 'undefined' || this.cart === null ) {
      this.router.navigate(['/user-home']);
    }

    this.user = JSON.parse( localStorage.getItem( 'currentUser' ) );

    if( this.user !== null ) {

      this.billingDetails['first_name'] = this.user['first_name'];
      this.billingDetails['last_name'] = this.user['last_name'];
      this.billingDetails['email'] = this.user['email'];

    }

  }

  showModal( info: Object ) {

    this.modalRef = this.modalService.show( ModalComponent , {
      data: {
        heading: info['heading'],
        component: PlainTextComponent,
        extraData: {
          text: info['text'],
        },
      }
    });

  }

  initStripe() {

    this.stripeForm = this.fb.group({
      card: [ '' , [ Validators.required ] ]
    });

    this.stripeService.elements( this.elementsOptions ).subscribe( elements => {

        this.elements = elements;

        // Only mount the element the first time
        if( ! this.card ) {

          this.card = this.elements.create( 'card' , {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });

          this.card.mount( '#card-number' );

        }

      });

    }

    initCountries() {

      this.serverService.getcountries().subscribe(
        resp => {

          this.countries = [];
          var thisCountry = {};

          for (var i = 0; i < resp.length; i++) {

            thisCountry = {
              id: resp[i]['numericCode'],
              label: resp[i]['name'],
              selected: false,
            }

            if (resp[i]['nativeName'] === 'United Kingdom') {

              thisCountry = {
                id: 'United Kingdom',
                label: 'United Kingdom',
                selected: true,
              }

              this.countries.unshift(thisCountry);

            } else {

              this.countries.push(thisCountry);

            }

          }

        }
      );

    }


    pay() {

      this.loading = true;

      const name = this.stripeForm.get( 'card' ).value;

      this.stripeService.createToken( this.card , { name } ).subscribe(
        resp => {

          if( resp.token ) {

            // Use the token to create a charge or a customer
            // https://stripe.com/docs/charges
            //console.log( resp.token );

            this.servicesService.willPayment({
              customerId: this.user['id'],
              billing: this.billingDetails,
              token: resp.token.id,
              will: this.cart['serviceId'],
              set_paid: true,
              payment_method: 'stripe',
              payment_method_title: 'Stripe',
              line_items: [
                {
                  product_id: 396,
                  quantity: 1,
                },
              ],
            }).subscribe(

              resp => {

                this.loading = false;

                if( typeof( resp['status'] ) === 'string' || resp['status'] === 200 ) {
                  this.router.navigate(['/order']);
                  return;
                }

                this.showModal({ heading: 'Error!', text: resp['message'] });

              },

              error => {
                this.showModal({ heading: 'Error!', text: error['message'] });
                //console.log( error );
                this.loading = false;
              }

            );

          } else if( resp.error ) {
            // Error creating the token
            this.showModal({ heading: 'Error!', text: resp.error.message });
            this.loading = false;
          }

        },

        error => {
          this.loading = false;
          this.showModal({ heading: 'Error!', text: 'Unable to verify your card details.' });
        }

      );

    }

}

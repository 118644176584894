import { Component, AfterViewInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

import { ContentLoaderDirective } from '../../directives/content-loader.directive';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent  implements AfterViewInit {

  @ViewChild( ContentLoaderDirective ) modalContent: ContentLoaderDirective;

  heading;
  component;
  object;
  key;
  extraData;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public modalRef: MDBModalRef
  ) { }

  ngAfterViewInit() {
    this.loadComponent();
  }

  loadComponent() {

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory( this.component );

    let viewContainerRef = this.modalContent.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent( componentFactory );
    componentRef['instance']['object'] = this.object;
    componentRef['instance']['key'] = this.key;
    componentRef['instance']['extraData'] = this.extraData;

  }

}

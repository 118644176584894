<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Secrets</h2>
  <p>Keeping anything close to your chest? No time to spill the beans like when you're no longer around to face the music!</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-4">
          Title
        </div>
        <div class="col-sm-8">
          Your Secret
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-4">
        {{ item['give_your_secret_a_name'] }}
      </div>
      <div class="col-sm-8">
        {{ item['tell_us_the_secret'] }}
      </div>
    </div>

  </div>
</app-service-child-list>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { WpConfigService } from '../angular-wordpress-rest-api/services/wp-config/wp-config.service';
import { WpMessagesService } from '../angular-wordpress-rest-api/services/wp-messages/wp-messages.service';
import { WpUserService } from '../angular-wordpress-rest-api/services/wp-user/wp-user.service';

import { WpMessage } from '../angular-wordpress-rest-api/classes/wp-message/wp-message';
import { WpUser } from '../angular-wordpress-rest-api/classes/wp-user/wp-user';

import { GlobalCommsService } from '../services/global-comms/global-comms.service';
import { AccountManagementService } from '../services/account-management/account-management.service';

class UserActivationVar {
  activationCode?: string;
  descriptions: {
    negative: string;
    positive: string;
  }
  name: string;
  ready: boolean;
  slug: string;
  value: boolean;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @ViewChild( 'addressFormFocusField' ) addressFormFocusField: ElementRef;

  loggedIn: any;

  backendUrl: string;
  currentUser: Object;
  profileEditQuestions: Array<Object>;

  loading: Boolean;
  passwordIsActive: Boolean;

  constructor(
    public accountManagementService: AccountManagementService,
    public router: Router,
    private globalCommsService: GlobalCommsService,
    private wpConfigService: WpConfigService,
    private wpMessagesService: WpMessagesService,
    private wpUserService: WpUserService,
  ) {
    this.currentUser = {}
    this.passwordIsActive = false;
  }

  ngOnInit() {

    this.globalCommsService.currentUserData.subscribe(
      resp => {
        this.setUser( resp );
      }
    );

    this.wpConfigService.currentAppConfig.pipe(
      filter( resp => typeof( resp['siteUrl'] ) !== 'undefined' )
    ).subscribe(
      resp => {
        this.backendUrl = resp['siteUrl'].replace( '/wp-json' , '' );
      }
    );

    this.profileEditQuestions = [
      {
        type: 'text',
        name: 'first_name',
        text: 'Your First Name',
        required: true,
      },
      {
        type: 'text',
        name: 'last_name',
        text: 'Your Last Name',
        required: true,
      },
      {
        type: 'email',
        name: 'email',
        text: 'Your Email',
        required: true,
      },
      {
        type: 'text',
        name: 'phone-number',
        text: 'Your Phone Number',
        required: false,
      },
    ]

  }

  emailIsVerified(): boolean {

    if( typeof( this.currentUser ) === 'undefined' ) {
      return false;
    }

    let emailIsVerifiedVar = this.getActivationVar( 'email-confirmed' );

    return emailIsVerifiedVar.value;

  }

  getActivationVar( slug: string ): UserActivationVar {
    let userActivationVar = this.currentUser['wig_activation_status']['criteria'].filter( acVar => acVar.slug === slug );
    return userActivationVar[0];
  }

  getActivationVars(): UserActivationVar[] {

    if( typeof( this.currentUser ) === 'undefined' ) {
      return [];
    }

    if( typeof( this.currentUser['wig_activation_status'] ) === 'undefined' ) {
      return [];
    }

    return this.currentUser['wig_activation_status']['criteria'];

  }

  getInactiveVars(): UserActivationVar[] {

    if( typeof( this.currentUser ) === 'undefined' ) {
      return [];
    }

    return this.currentUser['wig_activation_status']['criteria'].filter( acVar => ! acVar.status );

  }

  logout() {
    this.accountManagementService.logout();
    this.router.navigate(['/login']);
    this.globalCommsService.updateLoginStatus({
      status: 'not-logged-in',
    });
  }

  setUser( user: WpUser ) {

    if( typeof( user['address'] ) === 'undefined' || user['address'].length === 0 ) {
      user['address'] = {};
      return this.currentUser = user;
    }

    try {
      user['address'] = JSON.parse( user['address'] );
    }

    catch( e ) { }

    this.currentUser = user;

  }

  runActivationTrigger( userActivationVar: UserActivationVar ): void {

    let triggerMethods = [
      { slug: 'email-confirmed' , method: 'triggerEmailConfirmation' },
      { slug: 'phone-confirmed' , method: 'triggerPhoneConfirmation' },
      { slug: 'address-added' , method: 'triggerAddAddress' },
      { slug: 'subscription-active' , method: 'triggerActivateSubscription' },
      { slug: 'id-confirmed' , method: 'triggerIdConfirmation' },
    ]

    let triggerMethod = triggerMethods.filter( method => method.slug === userActivationVar.slug );

    if( triggerMethod.length === 0 || typeof( this[ triggerMethod[0].method ] ) !== 'function' ) {
      this.sendMessage({
        message: 'Invalid activation method requested',
        type: 'error',
      });
    }

    let triggerMethodName = triggerMethod[0].method;
    let callable = this[ triggerMethodName ].bind( this );

    return callable( userActivationVar );

  }

  sendMessage( message: WpMessage ): void {
    this.wpMessagesService.broadcastMessage( message );
  }

  showCodeVerification( userActivationVar: UserActivationVar ): boolean {

    if( [ 'email-confirmed' , 'phone-confirmed' ].indexOf( userActivationVar.slug ) === -1 ) {
      return false;
    }

    if( ! userActivationVar.ready ) {
      return false;
    }

    return true;

  }

  triggerActivateSubscription( userActivationVar: UserActivationVar ) {
    let userToken = localStorage.getItem( 'userToken' );
    window.location.href = this.backendUrl + '?token=' + userToken
  }

  triggerAddAddress( userActivationVar: UserActivationVar ) {
    this.addressFormFocusField.nativeElement.focus();
  }

  triggerEmailConfirmation( userActivationVar: UserActivationVar ) {

    this.loading = true;

    this.accountManagementService.triggerEmailConfirmation().subscribe(
      resp => {
        userActivationVar.ready = true;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );

  }

  triggerPhoneConfirmation( userActivationVar: UserActivationVar ) {

    if( ! this.currentUser['phone-number'] ) {
      return alert( 'You need to add a phone number and click save before you can verify your phone number.' );
    }

    this.loading = true;

    this.accountManagementService.triggerPhoneConfirmation().subscribe(
      resp => {
        userActivationVar.ready = true;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );

  }

  updateProfile() {

    this.loading = true;

    let sendData = [
      'address',
      'email',
      'first_name',
      'last_name',
      'phone-number',
    ];

    if( typeof( this.currentUser['password'] ) !== 'undefined' && this.currentUser['password'] !== '' ) {
      sendData.push( 'password' );
    }

    this.accountManagementService.update( this.currentUser , sendData ).subscribe(

      resp => {
        this.loading = false;
        this.globalCommsService.updateUserData( resp );
        this.wpUserService.updateCurrentUser( resp );
      },

      error => {
        this.loading = false;
      }

    );

  }

  onFocus( event ) {
    this.passwordIsActive = true;
  }

  validateCode( userActivationVar: UserActivationVar ): void {

    this.loading = true;

    this.accountManagementService.validateCode( userActivationVar.activationCode , userActivationVar.slug ).subscribe(
      resp => {
        this.globalCommsService.updateUserData( resp );
        this.wpUserService.updateCurrentUser( resp );
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );

  }

}

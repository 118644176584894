<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Funeral Music</h2>
  <p>The mourners at your funeral may always associate the music that you choose with you thereafter,
    so choosing the music is very important.
    There are usually three opportunities for music to be played at a funeral, once on entry,
    once on exit and once when the final farewell is said during the ceremony. </p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-3">
          Song Name
        </div>
        <div class="col-sm-3">
          Artist
        </div>
        <div class="col-sm-2">
          When to Play
        </div>
        <div class="col-sm-4">
          Notes
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-3">
        {{ item['song_title'] }}
      </div>
      <div class="col-sm-3">
        {{ item['artist'] }}
      </div>
      <div class="col-sm-2">
        {{ item['when_played'] }}
      </div>
      <div class="col-sm-4">
        {{ item['music_item_other_information'] }}
      </div>
    </div>

  </div>
</app-service-child-list>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { HomeComponent } from './home/home.component';
import { QuotesComponent } from './quotes/quotes.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetNewPasswordComponent } from './reset-password/set-new-password/set-new-password.component';
import { CartComponent } from './payments/cart/cart.component';
import { CheckoutComponent } from './payments/checkout/checkout.component';
import { OrderComponent } from './payments/order/order.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { LwWillWriterComponent } from './parts/vault/lw-will/lw-will-writer/lw-will-writer.component';

import { InitialWillFormComponent } from './parts/initial-will-form/initial-will-form.component';

import { ServiceSummaryComponent } from './parts/service-summary/service-summary.component';
import { SendNotificationsComponent } from './parts/send-notifications/send-notifications.component';

import {
  AuthGuardService as AuthGuard
} from './services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/user-dashboard', pathMatch: 'full' },
  //{ path: 'home', component: HomeComponent },
  //{ path: 'quotes', component: QuotesComponent },
  { path: 'referral-pipeline', component: AdminDashboardComponent },
  { path: 'user-home', redirectTo: '/user-dashboard' },
  { path: 'login', component: LoginComponent },
  { path: 'login/reset-password', component: ResetPasswordComponent },
  { path: 'login/set-new-password', component: SetNewPasswordComponent },
  //{ path: 'cart', component: CartComponent, canActivate:[AuthGuard] },
  //{ path: 'checkout', component: CheckoutComponent, canActivate:[AuthGuard] },
  //{ path: 'order', component: OrderComponent, canActivate:[AuthGuard] },
  //{ path: 'will-form', component: InitialWillFormComponent },
  //{ path: 'test' , component: TestComponent },
  { path: 'will-writer' , component: LwWillWriterComponent },
  { path: 'will-writer/:id' , component: LwWillWriterComponent },
  { path: 'user-dashboard', component: UserDashboardComponent, canActivate:[ AuthGuard ] },
  { path: 'user-dashboard/:section', component: UserDashboardComponent, canActivate:[ AuthGuard ] },
  { path: 'user-dashboard/:section/:part', component: UserDashboardComponent, canActivate:[ AuthGuard ] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

<div class="row">
  <div class="col-md-12">

    <div *ngIf="! showSummaryOnly()">

      <p class="text-dark" *ngIf="description">
        <strong>{{ description }}</strong><br />
        <a class="text-default" (click)="basicModal.show()">Read More >></a>
      </p>

      <div class="d-flex align-items-center justify-content-between">
        <p *ngIf="extraData['section']['max'] !== 0">Add up to {{ extraData['section']['max'] }} {{ extraData['section']['plural'] }}</p>
        <p *ngIf="extraData['section']['max'] === 0">Add {{ extraData['section']['plural'] }}</p>
        <button [disabled]="wait" (click)="showModal({ type: 'service-part' })" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Add {{ extraData['section']['singular'] }}</button>
      </div>

    </div>

    <div cdkDropList (cdkDropListDropped)="moveItem( $event )" class="list-group list-group-flush">

      <div cdkDrag *ngFor="let item of items" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between" style="cursor: move">

        <i *ngIf="! showSummaryOnly()" class="fas fa-grip-lines" style="position: relative;left: -10px;"></i>

        <ng-template *ngTemplateOutlet="dynamicContent; context: { $implicit: item }"></ng-template>

        <div *ngIf="! showSummaryOnly()" style="min-width: 188px;">
          <button (click)="showModal( item )" [disabled]="wait" class="btn-default" mdbBtn type="button" size="sm" mdbWavesEffect>Edit</button>
          <button (click)="delete( item )" [disabled]="wait" class="btn-danger" mdbBtn type="button" size="sm" mdbWavesEffect>Remove</button>
        </div>
      </div>

      <div *ngIf="loading" class="list-group-item list-group-item-action">
        <div class="flex-fill">

          <div class="row align-items-center">
            <div class="col-sm-4">
              <div class="wig-skeleton"></div>
            </div>
            <div class="col-sm-4">
              <div class="wig-skeleton"></div>
            </div>
            <div class="col-sm-4">
              <div class="wig-skeleton"></div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal f ade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-full-height modal-right" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ extraData['section']['title'] }}</h4>
      </div>
      <div class="modal-body" [innerHTML]="subDescription"></div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="default" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="! showSummaryOnly()" class="jumbotron text-center mdb-color lighten-2 white-text mx-2 mb-5">
  <h2 class="card-title h2">Savings and Investments</h2>
  <p>Your next of kin will need to make contact with any banks where you hold savings accounts.
    This is both so that any funds can be distributed as part of probabte.</p>
  <p>You can make this easier for them by listing the details of all your accounts below</p>
</div>

<app-service-completion *ngIf="! showSummaryOnly()" [service]="object"></app-service-completion>

<div *ngIf="showSummaryOnly()" class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">

    <div class="flex-fill">
      <div class="row align-items-center">
        <div class="col-sm-4">
          Company Name
        </div>
        <div class="col-sm-4">
          Type
        </div>
        <div class="col-sm-4">
          Account Number
        </div>
      </div>
    </div>

  </div>
</div>

<app-service-child-list [object]="object" [key]="key" [extraData]="extraData">
  <div *app-content-loader="let item" class="flex-fill">

    <div class="row align-items-center">
      <div class="col-sm-4">
        {{ item['company_name'] }}
      </div>
      <div class="col-sm-4">
        {{ item['type'] }}
      </div>
      <div class="col-sm-4">
        {{ item['account_number'] }}
      </div>
    </div>

  </div>
</app-service-child-list>
